import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logo.png";

export default class StaticPageHeader extends PureComponent {
  render() {
    return (
      <header>
          <nav className="navbar navbar-expand-lg topnav py-0">
            <Link className="navbar-brand logo"  style={{ width: "18%"}}  to="/dashboard">
              <img src={logo} alt="" />{" "}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon toggler-icon">
                <svg
                  viewBox="0 0 32 32"
                  width="32px" /* xml:space="preserve" */
                >
                  <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2 s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
                </svg>
              </span>
            </button>
          </nav>
      </header>
    );
  }
}
