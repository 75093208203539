/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { PureComponent } from "react";
import { Badge } from "reactstrap";
import { connect } from "react-redux";

import ReviewTransactions from "./ReviewTransactions";
import TableWithSearch from "../../../shared/components/TableWithSearch";
import TransactionAction from "./TransactionAction";
import { categoryOptions } from "../../../helpers/constants";
import CategorySelect from "../../../shared/components/form/CategorySelect";
import { getLocalDate, formatCurrency } from "../../../helpers";
import { Type } from "react-bootstrap-table2-editor";
import { compareValues } from "../../../helpers";
import _ from "lodash";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";
import queryString from "query-string";
import { getCategoryValueFromLabel } from "../../../helpers/constants";

import AddTransaction from "./AddTransaction";
import PastTransactionImport from "./PastTransactionImport";

import DeleteTransaction from "./DeleteTransaction";
import DuplicateTransaction from "./DuplicateTransaction";

import {
  getTransactions,
  updateTransaction
} from "../../../actions/transaction/";

class TransactionListing extends PureComponent {
  columnProps = [
    {
      dataField: "category",
      text: "Category",
      sort: true,
      editable: true,
      formatter: (cell, row) => {
        let matchedOption = categoryOptions
          .flatMap(cat => cat.options)
          .find(option => option.value === cell.toLowerCase());
        return matchedOption ? matchedOption.label : cell;
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => <CategorySelect {...editorProps} value={value} />
    },
    {
      dataField: "payee",
      text: "Payee",
      sort: true,
      style: {
        overflow: "hidden"
      }
    },
    {
      dataField: "name",
      text: "Description",
      sort: true,
      editable: true,
      style: {
        overflow: "hidden"
      }
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: (cell, row) => {
        if (row.amount) {
          return formatCurrency.format(row.amount);
        }
      }
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      editable: true,
      editor: {
        type: Type.DATE
      },
      formatter: (cell, row) => {
        if (row.date) {
          return getLocalDate(row.date);
        }
      }
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      editable: true,
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: "INCOME",
            label: "INCOME"
          },
          {
            value: "EXPENSE",
            label: "EXPENSE"
          }
        ]
      },
      formatter: (cell, row) => {
        return (
          <Badge color={row.type === "INCOME" ? "success" : "danger"}>
            {row.type}
          </Badge>
        );
      }
    },
    {
      text: "Status",
      dataField: "isPending",
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: true,
            label: "UNPAID"
          },
          {
            value: false,
            label: "PAID"
          }
        ]
      },
      formatter: (cell, row) => {
        return (
          <Badge color={row.isPending ? "danger" : "success"}>
            {row.isPending ? "Unpaid" : "Paid"}
          </Badge>
        );
      }
    },
    {
      text: "Actions",
      dataField: "",
      editable: false,
      formatter: (cell, row) => {
        return (
          <TransactionAction onSelect={type => this.handleAction(type, row)} />
        );
      }
    }
  ];
  constructor(props) {
    super(props);
    const searchParams = queryString.parse(this.props.location.search).search;
    let searchText;
    let queryParams = new URLSearchParams(
      "offset=0&limit=10&sortBy=date&orderBy=desc"
    );
    if (!_.isEmpty(searchParams)) {
      searchText = getCategoryValueFromLabel(searchParams);
      queryParams.set("search", searchText);
    }

    this.state = {
      loading: true,
      error: null,
      transactions: [],
      hasDuplicate: false,
      term: "",
      page: 1,
      sizePerPage: 10,
      updatingTransaction: false,
      loadingTransactions: false,
      currentTransactions: [],
      duplicateTransactions: [],
      selectedRowData: [],
      pendingTransactions: [],
      transactionsCount: 0,
      actionType: null,
      selectedRow: null,
      searchText: searchText,
      queryParams: queryParams.toString()
    };
  }

  componentDidMount = () => {
    this.setState({ loadingTransactions: true }, () => {
      this.queryTransactions();
    });
  };

  handleResetQueryParams = () => {
    let queryParams = new URLSearchParams(this.state.queryParams);
    queryParams.delete("search");
    this.setState(
      { searchText: null, queryParams: queryParams.toString() },
      () => {
        this.queryTransactions();
      }
    );
  };
  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText, data, cellEdit }
  ) => {
    const { assetSlug } = this.props;
    if (cellEdit) {
      const { rowId, dataField, newValue } = cellEdit;
      if (rowId && newValue) {
        // if there was a edit operation, we need to call update transactions
        let rowData = data.find(row => row._id === rowId);
        if (
          !compareValues(rowData[dataField], newValue, dataField === "date")
        ) {
          rowData[dataField] = newValue;
          this.setState(
            {
              updatingTransaction: true
            },
            () => {
              if (rowData.asset)
                this.props.updateTransaction({
                  slug: assetSlug,
                  transaction: rowData,
                  query: this.state.queryParams
                });
            }
          );
        }
      }
    } else {
      let queryParams = new URLSearchParams(this.state.queryParams);
      let currentSearchText = this.state.searchText;
      if (searchText) currentSearchText = searchText;
      queryParams.set("offset", page - 1);
      if (sortField) queryParams.set("sortBy", sortField);
      if (sortOrder) queryParams.set("orderBy", sortOrder);
      if (currentSearchText) queryParams.set("search", currentSearchText);

      this.setState(
        {
          page: page,
          sizePerPage: sizePerPage,
          queryParams: queryParams.toString()
        },
        () => {
          this.queryTransactions();
        }
      );
    }
  };

  queryTransactions = () => {
    let { queryParams } = this.state;
    this.props.getTransactions(this.props.assetSlug, queryParams);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      transactions: { transactions }
    } = nextProps;

    if (!prevState.loadingTransactions && isFetching(transactions)) {
      return {
        loadingTransactions: true
      };
    }
    let pendingTransactionsChanged = false;
    let duplicateTransactionsChanged = false;
    let currentTransactionsChanged = false;
    if (!isFetching(transactions)) {
      const {
        currentTransactions,
        pendingTransactions,
        duplicateTransactions,
        totalCount
      } = transactions.data;

      if (currentTransactions)
        currentTransactionsChanged =
          (prevState.currentTransactions &&
            prevState.currentTransactions.length !==
              currentTransactions.length) ||
          prevState.transactionsCount !== totalCount;

      if (pendingTransactions) {
        pendingTransactionsChanged =
          prevState.pendingTransactions &&
          prevState.pendingTransactions.length !== pendingTransactions.length;
      }

      if (duplicateTransactions)
        duplicateTransactionsChanged =
          prevState.duplicateTransactions &&
          prevState.duplicateTransactions.length !==
            duplicateTransactions.length;
    }

    if (
      (prevState.loadingTransactions ||
        currentTransactionsChanged ||
        pendingTransactionsChanged ||
        duplicateTransactionsChanged) &&
      !isFetching(transactions)
    ) {
      if (
        isSuccess(transactions) &&
        (transactions.type === "GET" ||
          transactions.type === "UPDATE" ||
          transactions.type === "DELETE" ||
          transactions.type === "SAVE")
      ) {
        const {
          currentTransactions,
          pendingTransactions,
          duplicateTransactions,
          bankAccounts,
          totalCount
        } = transactions.data;
        return {
          loadingTransactions: false,
          currentTransactions,
          pendingTransactions,
          duplicateTransactions,
          bankAccounts,
          transactionsCount: totalCount
        };
      }
    }

    return null;
  }

  filterFunction = row => {
    let res = false;
    const { term } = this.state;
    for (let i = 0; i < this.columns.length; i += 1) {
      const val = row[this.columns[i]];
      if (!Array.isArray(val)) {
        if (
          String(val)
            .toLowerCase()
            .includes(term.toLowerCase())
        ) {
          res = true;
          break;
        }
      } else {
        for (let j = 0; i < val.length; j += 1) {
          if (
            String(val[j])
              .toLowerCase()
              .includes(term.toLowerCase())
          ) {
            res = true;
            break;
          }
        }
      }
    }
    return res;
  };

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toString().toLowerCase()).startsWith(
          filter.value.toLowerCase()
        )
      : true;
  };

  onSearchChange = term => {
    this.setState({ term });
  };

  checkTranIdInQuery = props => {
    if (!props) return;
    const unpaidTranIdFromQuery = props.searchParams.unpaidId;
    if (unpaidTranIdFromQuery) {
      this.setState({ unpaidTranIdFromQuery: unpaidTranIdFromQuery || null });
    }
  };

  componentDidMount() {
    this.checkTranIdInQuery(this.props);
  }

  selectedrow = data => {
    this.props.selectedRow(data);
  };

  handleAction = (type, row) => {
    this.setState({ actionType: type, selectedRow: row }, () => {});
  };

  closeModal = () => {
    this.setState({ selectedRow: null, actionType: null });
  };

  render() {
    const { assetSlug, asset, queryParams, location } = this.props;
    const searchParams = queryString.parse(this.props.location.search).search;

    const {
      actionType,
      selectedRow,
      currentTransactions,
      pendingTransactions,
      duplicateTransactions,
      transactionsCount
    } = this.state;

    if (!currentTransactions) return <div />;

    return (
      <div className="px-4">
        <ReviewTransactions
          type="pending"
          transactions={pendingTransactions}
          asset={asset}
          assetSlug={assetSlug}
          unpaidTranIdFromQuery={this.state.unpaidTranIdFromQuery}
        />

        <ReviewTransactions
          type="duplicate"
          transactions={duplicateTransactions}
          asset={asset}
          assetSlug={assetSlug}
          unpaidTranIdFromQuery={this.state.unpaidTranIdFromQuery}
        />
        <TableWithSearch
          keyField="_id"
          data={currentTransactions}
          totalSize={transactionsCount}
          page={this.state.page}
          sizePerPage={this.state.sizePerPage}
          columns={this.columnProps}
          onTableChange={this.handleTableChange}
          resetQueryParams={this.handleResetQueryParams}
          enableCellEdit={true}
          location={location}
          searchParams={searchParams}
        >
          <PastTransactionImport
            assetSlug={assetSlug}
            asset={asset}
            modelContext={"transactions"}
            assetAccounts={this.props.accountDetail}
          />
          <AddTransaction assetSlug={assetSlug} asset={asset} />
        </TableWithSearch>
        {selectedRow && (
          <DuplicateTransaction
            transaction={selectedRow}
            assetSlug={this.props.assetSlug}
            asset={this.props.asset}
            showModal={actionType === "duplicate"}
            closeModal={this.closeModal}
            queryParams={queryParams}
          />
        )}
        {selectedRow && (
          <DeleteTransaction
            transaction={selectedRow}
            assetSlug={this.props.assetSlug}
            showModal={actionType === "delete"}
            closeModal={this.closeModal}
            queryParams={queryParams}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  transactions: state.transactions
});

const mapDispatchToProps = dispatch => ({
  getTransactions: (slug, query) =>
    getTransactions({ slug: slug, query: query }, dispatch),
  updateTransaction: data => updateTransaction(data, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionListing);
