import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, NavItem, NavLink } from "reactstrap";
import clsx from "clsx";

const ChatSidebar = props => {
  const { contacts, activeContact } = props;
  if (!contacts) return <div />;

  return (
    <div
      className={clsx(
        "app-inner-content-layout--sidebar pos-r order-2 order-lg-3 bg-white app-inner-content-layout--sidebar__sm",
        { "layout-sidebar-open": props.isInnerSidebarOpen }
      )}
    >
      <div className="text-uppercase font-size-sm text-primary font-weight-bold my-3 px-3">
        Contact list
      </div>
      <PerfectScrollbar className="scroll-area-lg">
        <Nav pills className=" nav-neutral-first flex-column">
          {contacts.map(contact => {
            return (
              <NavItem key={contact.id}>
                <NavLink
                  href="#/"
                  onClick={e => props.onClick(contact, "", e)}
                  active={activeContact && activeContact.id === contact.id}
                >
                  <div className="align-box-row">
                    <div className="avatar-icon-wrapper avatar-icon-sm">
                      <div className="avatar-icon rounded-circle">
                        <img alt="..." src={contact.picture} />
                      </div>
                    </div>
                    <div className="pl-2">
                      <span className="d-block text-black font-size-sm font-weight-bold">
                        {contact.name}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </PerfectScrollbar>
    </div>
  );
};

export default ChatSidebar;
