import {
  GET_ASSET_CONTACTS,
  ADD_ASSET_CONTACT,
  UPDATE_ASSET_CONTACT,
  DELETE_ASSET_CONTACT,
  GET_ALL_CONTACTS,
  ADD_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT
} from "../actionTypes";

export const getAssetContacts = (data, dispatch) => {
  dispatch({
    type: GET_ASSET_CONTACTS,
    payload: data
  });
};

export const addAssetContact = (data, dispatch) => {
  dispatch({
    type: ADD_ASSET_CONTACT,
    payload: data
  });
};

export const updateAssetContact = (data, dispatch) => {
  dispatch({
    type: UPDATE_ASSET_CONTACT,
    payload: data
  });
};

export const deleteAssetContact = (data, dispatch) => {
  dispatch({
    type: DELETE_ASSET_CONTACT,
    payload: data
  });
};

export const getAllContacts = dispatch => {
  dispatch({
    type: GET_ALL_CONTACTS
  });
};

export const addContact = (data, dispatch) => {
  dispatch({
    type: ADD_CONTACT,
    payload: data
  });
};
export const deleteContact = (data, dispatch) => {
  dispatch({
    type: DELETE_CONTACT,
    payload: data
  });
};
export const updateContact = (data, dispatch) => {
  dispatch({
    type: UPDATE_CONTACT,
    payload: data
  });
};
