import React, { Component } from "react";
import {
  getAccounts,
  addAccount,
  removeAccount
} from "../../actions/accounts/accountActions";
import { connect } from "react-redux";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import SectionHeader from "../../shared/components/SectionHeader";
import PlaidLinkButton from "./PlaidLinkButton";
import BankTransactionImport from "./BankTransactionImport";
import BankAccountTable from "./BankAccountTable";

class BankImport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBankImport: false,
      showModal: false,
      error: null,
      accountToFetch: null,
      loading: true,
      accounts: [],
      userLinkToken: null,
      accountLinkTokens: {},
      addingAccount: false,
      updatingAccount: false,
      deletingAccount: false
    };
  }

  componentDidMount() {
    this.props.getAccounts();
  }

  onAddAccount = data => {
    this.setState({ addingAccount: true }, () => {
      this.props.addAccount(data);
    });
  };

  onUpdateAccount = data => {
    this.setState({ updatingAccount: true }, () => {
      this.props.getAccounts();
    });
  };

  onDeleteAccount = accountId => {
    this.setState({ deletingAccount: true }, () => {
      const data = {
        _id: accountId
      };
      this.props.deleteAccount(data);
    });
  };

  toggleBankImportModal = accountId => {
    this.setState(prevState => ({
      showBankImport: !prevState.showBankImport,
      accountToFetch: accountId
    }));
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { plaid } = nextProps;

    if (prevState.updatingAccount && !prevState.loading) {
      return { loading: true, updatingAccount: false };
    }
    if (!prevState.loading && isFetching(plaid)) {
      return {
        loading: true
      };
    }

    if (prevState.loading && !isFetching(plaid)) {
      if (isSuccess(plaid)) {
        if (
          plaid.type === "REMOVE" &&
          prevState.deletingAccount &&
          !prevState.addingAccount
        ) {
          const { _id } = plaid.data;
          let filteredAccounts = prevState.accounts.filter(
            account => account._id !== _id
          );

          return {
            deletingAccount: false,
            loading: false,
            accounts: filteredAccounts
          };
        }

        if (
          plaid.type === "ADD" &&
          prevState.addingAccount &&
          !prevState.deletingAccount
        ) {
          let accounts = prevState.accounts;
          accounts.push(plaid.data.data);
          return {
            addingAccount: false,
            loading: false,
            accounts: accounts
          };
        }

        if (
          plaid.type === "GET" &&
          (!prevState.deletingAccount || !prevState.addingAccount)
        ) {
          const { accountLinkTokens, accounts, userLinkToken } = plaid.data;
          return {
            loading: false,
            accountLinkTokens,
            accounts,
            userLinkToken
          };
        }
      }
    }

    return null;
  }
  render() {
    const { loading, accounts, accountLinkTokens, userLinkToken } = this.state;
    if (loading || !userLinkToken) return <div />;
    // We have to fetch the asset_list
    const { asset } = this.props;

    return (
      <div className="p-5">
        <SectionHeader titleHeading="Bank Accounts">
          <PlaidLinkButton
            linkToken={userLinkToken}
            onSuccess={this.onAddAccount}
          />
        </SectionHeader>
        <BankTransactionImport
          showModal={this.state.showBankImport}
          toggleBankImportModal={this.toggleBankImportModal}
          accountToFetch={this.state.accountToFetch}
          assets={asset.data}
        />
        <BankAccountTable
          accounts={accounts}
          assets={asset.data}
          accountLinkTokens={accountLinkTokens}
          onDeleteAccount={this.onDeleteAccount}
          onUpdateAccount={this.onUpdateAccount}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  plaid: state.plaid,
  asset: state.assetList
});
const mapDispatchToProps = dispatch => ({
  addAccount: plaidData => {
    addAccount(plaidData, dispatch);
  },
  getAccounts: () => {
    getAccounts(dispatch);
  },
  deleteAccount: data => {
    removeAccount(data, dispatch);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankImport);
