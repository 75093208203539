import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Field /* Form */ } from "formik";
import * as Yup from "yup";
import { unmaskCurrency } from "../../../helpers/";
import {
  getInvestmentInfo,
  updateInvestmentInfo
} from "../../../actions/asset/assetAction";
import CurrencyField from "../../../shared/components/form/CurrencyField";
// import FormField from '../../../shared/components/form/FormField';
// import customSelect from '../../../shared/components/form/customSelect';

const validationSchema = Yup.object({
  purchaseValue: Yup.string().max(16, "Purchase value too large"),
  marketValue: Yup.string().max(16, "Market value too large"),
  marketRent: Yup.string().max(16, "Market rent too large"),
  downPayment: Yup.string().max(16, "Down payment too large"),

  askingPrice: Yup.string().max(16, "Asking Price too large"),
  afterRepairValue: Yup.string().max(16, "After Repair value too large"),
  holdingCosts: Yup.string().max(16, "Holding Costs too large"),
  rehabEstimate: Yup.string().max(16, "Rehab Estimate too large"),

  llcName: Yup.string().max(100, "LLC Name too long"),
  amountInvested: Yup.string().max(16, "Amount Invested too large"),
  amountEarnedEachMonth: Yup.string().max(
    16,
    "Amount Earned each month too large"
  ),

  balance: Yup.string().max(16, "Balance too large"),
  yield: Yup.string().max(16, "Yield too large"),
  investmentToBalance: Yup.string().max(16, "Investment to balance too large"),
  investmentToValue: Yup.string().max(16, "Investment to value too large"),
  loanToValue: Yup.string().max(16, "Loan to value too large"),
  lienPosition: Yup.number()
    .typeError("Enter valid Lien position")
    .integer("Enter valid Lien position")
    .min(1, "Min 1 unit is required")
    .required("Enter Lien position"),
  unpaidBalance: Yup.string().max(16, "Unpaid Balance too large"),
  isPerforming: Yup.string().max(1, "Performing too large")
});

class FlipInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      investmentInfo: {
        // purchaseValue: '',
        // marketValue: '',
        // marketRent: '',
        // downPayment: '',

        askingPrice: "",
        afterRepairValue: "",
        holdingCosts: "",
        rehabEstimate: ""
        // llcName:'',
        // amountInvested:'',
        // amountEarnedEachMonth:'',
        // balance:'',
        // yield:'',
        // investmentToBalance:'',
        // investmentToValue:'',
        // loanToValue:'',
        // lienPosition:1,
        // unpaidBalance:'',
        // isPerforming:'1',
      }
    };
  }

  updateInvestmentInfo = values => {
    values.askingPrice = unmaskCurrency(values.askingPrice);
    values.afterRepairValue = unmaskCurrency(values.afterRepairValue);
    values.holdingCosts = unmaskCurrency(values.holdingCosts);
    values.rehabEstimate = unmaskCurrency(values.rehabEstimate);

    this.setState({ loading: true, error: null }, () => {
      values.slug = this.props.assetSlug;
      this.props.updateInvestmentInfo(values);
    });
  };

  componentWillMount() {
    const { assetSlug } = this.props;
    this.props.getInvestmentInfo(assetSlug);
  }

  componentWillReceiveProps(nextProps) {
    const { loading } = this.state;
    const { assetInvestmentInfo } = nextProps;
    if (loading && this.props !== nextProps) {
      if (assetInvestmentInfo.loadingInvestmentInfoSuccess === true) {
        const investmentInfo = assetInvestmentInfo.investmentInfo;
        this.setState({ investmentInfo, loading: false });
      } else if (assetInvestmentInfo.loadingInvestmentInfoSuccess === false) {
        this.setState({ error: assetInvestmentInfo.error, loading: false });
      }

      if (assetInvestmentInfo.updateInvestmentInfoSuccess === true) {
        const investmentInfo = assetInvestmentInfo.investmentInfo;

        this.setState({ investmentInfo, loading: false });
      } else if (assetInvestmentInfo.updateInvestmentInfoSuccess === false) {
        this.setState({ error: assetInvestmentInfo.error, loading: false });
      }
    }
  }
  render() {
    const initialValues = this.state.investmentInfo;
    const { loading } = this.state;
    const { isShare /* assetBasicInfo */ } = this.props;
    // const basicInfo = assetBasicInfo.basicInfo;
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.updateInvestmentInfo}
      >
        {props => (
          <div className="px-3 py-4">
            <form onSubmit={props.handleSubmit}>
              {/* Start of flip/deal fields */}
              {!isShare && (
                <div className="row">
                  <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6  inputblock has-symbol">
                    <label htmlFor="exampleInputEmail1">Asking Price:</label>
                    <Field name="askingPrice" component={CurrencyField} />
                    <span className="usd_icon">$</span>
                  </div>

                  <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6  inputblock  has-symbol">
                    <label htmlFor="exampleInputEmail1">Rehab Estimate:</label>
                    <Field
                      name="rehabEstimate"
                      component={CurrencyField}
                      type="text"
                    />
                    <span className="usd_icon">$</span>
                  </div>
                  <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6  inputblock">
                    <label htmlFor="exampleInputEmail1">
                      After Repair Value:
                    </label>
                    <Field
                      name="afterRepairValue"
                      component={CurrencyField}
                      type="text"
                    />
                    <span className="usd_icon">$</span>
                  </div>
                  <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6  inputblock">
                    <label htmlFor="exampleInputEmail1">Holding Costs:</label>
                    <Field
                      name="holdingCosts"
                      component={CurrencyField}
                      type="text"
                    />
                    <span className="usd_icon">$</span>
                  </div>
                </div>
              )}
              {!isShare && (
                <div className="py-5 text-center">
                  <button
                    type="submit"
                    className="btn btn-primary text-uppercase px-5"
                    disabled={loading}
                  >
                    Update
                  </button>
                </div>
              )}
            </form>
          </div>
        )}
      </Formik>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  getInvestmentInfo: slug => {
    getInvestmentInfo(slug, dispatch);
  },
  updateInvestmentInfo: data => {
    updateInvestmentInfo(data, dispatch);
  }
});
const mapStateToProps = state => ({
  assetInvestmentInfo: state.assetInvestmentInfo,
  assetBasicInfo: state.assetBasicInfo
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlipInfo);
