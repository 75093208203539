/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import { NavLink as NavLinkStrap } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "../../shared/components/ConfirmModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import {
  faEnvelope,
  faPlus,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import AddAttachment from "../AssetDetails/Documents/AddAttachment";
import DocumentsTable from "./DocumentsTable";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import SectionHeader from "../../shared/components/SectionHeader";

import {
  deleteDocument,
  getAllDocuments,
  uploadDocuments
} from "../../actions/asset/assetAction";

class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableChanged: false,
      deleting: false,
      uploading: false,
      showAddAttachmentModal: false,
      showAddViaEmailModal: false,
      selectedFile: null,
      showDeleteFileModal: false,
      showViewFileModal: false,
      documents: [],
      totalCount: 0,
      showModal: null
    };
  }

  toggleAddModal = type => {
    this.setState({ showModal: type });
  };

  toggleAddAttachmentModal = () => {
    this.setState(prevState => ({
      showAddAttachmentModal: !prevState.showAddAttachmentModal
    }));
  };

  toggleAddViaEmailModal = () => {
    this.setState(prevState => ({
      showAddViaEmailModal: !prevState.showAddViaEmailModal
    }));
  };

  copyEmail = () => {
    toast.info("Email copied to clipboard", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
    this.toggleAddViaEmailModal();
  };

  toggleDeleteFileModal = documentId => {
    this.setState(prevState => ({
      selectedFile: documentId,
      showDeleteFileModal: !prevState.showDeleteFileModal
    }));
  };

  handleDeleteDocument = () => {
    this.setState({ deleting: true }, () => {
      this.props.deleteDocument(this.state.selectedFile);
    });
  };

  toggleViewFileModal = documentId => {
    this.setState(prevState => ({
      selectedFile: documentId,
      showViewFileModal: !prevState.showViewFileModal
    }));
  };
  handleSubmit = values => {
    this.props.searchDocument(values.searchValue);
  };

  handleUploadDocuments = files => {
    if (files.length > 0) {
      this.setState({ uploading: true, error: null }, () => {
        this.props.uploadDocuments({
          fileName: "documents",
          files,
          params: {
            fileFolder: "documents"
          }
        });
      });
    }
  };

  handleTableChange = (type, attrs) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = attrs;
    this.setState({ tableChanged: true }, () => {
      let queryParams = [];
      queryParams.push(`skip=${page - 1} * sizePerPage`);
      queryParams.push(`limit=${sizePerPage}`);
      if (sortField) queryParams.push(`sortBy=${sortField}`);
      if (sortOrder) queryParams.push(`orderBy=${sortOrder}`);
      if (searchText) queryParams.push(`search=${searchText}`);
      this.props.getAllDocuments(queryParams.join("&"));
    });
  };

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.props.getAllDocuments("skip=0&limit=5");
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { documents } = nextProps;
    if (prevState.tableChanged || !documents) {
      return {
        loading: true,
        tableChanged: false
      };
    }

    if (prevState.uploading || !documents) {
      return {
        loading: true,
        uploading: false
      };
    }
    if (prevState.deleting || !documents) {
      return {
        loading: true,
        deleting: false
      };
    }

    if (prevState.loading) {
      if (!isFetching(documents) && documents.type === "DELETE") {
        // if delete type, we need to update the current documents and total
        if (isSuccess(documents)) {
          const deletedDocument = documents.data;
          return {
            loading: false,
            documents: prevState.documents.filter(
              a => a._id !== deletedDocument._id
            ),
            totalCount: prevState.totalCount - 1,
            selectedFile: null,
            showDeleteFileModal: false
          };
        }
      }

      if (!isFetching(documents) && documents.type === "UPLOAD") {
        if (isSuccess(documents)) {
          let newAttachments = documents.data;
          let currentAttachments = prevState.documents;
          let currentAttachmentCount = prevState.totalCount;
          return {
            loading: false,
            showAddAttachmentModal: false,
            documents: currentAttachments.concat(newAttachments),
            totalCount: currentAttachmentCount + newAttachments.length
          };
        } else
          return {
            loading: false,
            showAddAttachmentModal: false,
            error: documents.error
          };
      }
      if (!isFetching(documents) && documents.type === "GET") {
        if (isSuccess(documents)) {
          const { attachments, totalCount } = documents.data;

          return {
            loading: false,
            documents: attachments,
            totalCount: totalCount
          };
        } else
          return {
            loading: false,
            error: documents.error
          };
      }
    }
  }

  render() {
    const { documents, totalCount } = this.state;
    return (
      <div className="p-5">
        <SectionHeader titleHeading="Documents">
          <UncontrolledDropdown className="m-2">
            <DropdownToggle color="primary">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={faPlus} className="font-size-sm" />
              </span>
              <span className="btn-wrapper--label">Add Document</span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu">
              <Nav className="flex-column">
                <NavItem>
                  <NavLinkStrap
                    href="#/"
                    onClick={() => this.toggleAddModal("upload")}
                  >
                    <div className="nav-link-icon">
                      <FontAwesomeIcon icon={faUpload} />
                    </div>
                    <span>Upload</span>
                  </NavLinkStrap>
                </NavItem>
                <li className="dropdown-divider" />
                <NavItem>
                  <NavLinkStrap
                    href="#/"
                    onClick={() => this.toggleAddModal("email")}
                  >
                    <div className="nav-link-icon">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    <span>Email</span>
                  </NavLinkStrap>
                </NavItem>
              </Nav>
            </DropdownMenu>
          </UncontrolledDropdown>
        </SectionHeader>
        <ConfirmModal
          type="info"
          modalOpen={this.state.showModal === "email"}
          onSubmit={this.toggleAddModal}
          btnText={"Ok"}
          hideCancel={true}
        >
          <h4 className="font-weight-bold mt-4">
            Just email your documents to:
            <CopyToClipboard
              text={`document@upload.bettercapital.us`}
              onCopy={this.copyEmail}
            >
              <Button>{`document@upload.bettercapital.us`}</Button>
            </CopyToClipboard>
          </h4>
        </ConfirmModal>
        <DocumentsTable
          documents={documents}
          totalCount={totalCount}
          handleTableChange={this.handleTableChange}
          toggleDeleteFileModal={this.toggleDeleteFileModal}
          toggleViewFileModal={this.toggleViewFileModal}
        />

        <ConfirmModal
          title={"Are you sure to delete this document?"}
          subtitle={"You cannot undo this operation."}
          type="danger"
          modalOpen={this.state.showDeleteFileModal}
          onCancel={this.toggleDeleteFileModal}
          onSubmit={this.handleDeleteDocument}
          btnText={"Delete"}
          btnTextOnLoading={"Deleting"}
        />
        <AddAttachment
          toggleModal={this.toggleAddModal}
          modalOpen={this.state.showModal === "upload"}
          onSubmit={this.handleUploadDocuments}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    documents: state.documents
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllDocuments: query => getAllDocuments(dispatch, query),
    uploadDocuments: data => uploadDocuments(dispatch, data),
    deleteDocument: documentId => deleteDocument(dispatch, documentId)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Document);
