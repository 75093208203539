import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { capAllWords, getAssetTypeLabel } from "../../../helpers";
import queryString from "query-string";

const SectionHeader = props => {
  const { children, titleHeading, innerSection } = props;

  let {
    location: { pathname, search },
    pathItems
  } = props;

  if (!pathItems) {
    pathItems = pathname
      .split("/")
      .filter(Boolean)
      .map(path => {
        if (path === "dashboard" && search) {
          const searchParams = queryString.parse(search);
          return {
            path: "/dashboard" + search,
            label: getAssetTypeLabel(searchParams.type),
            active: true
          };
        } else if (path !== "dashboard") {
          return {
            path: "/" + path,
            label: capAllWords(path),
            active: "/" + path === pathname
          };
        }
      })
      .filter(Boolean);

    pathItems.unshift({
      path: "/",
      label: "Dashboard",
      active: pathItems.length === 0
    });
  }

  let style;
  if (innerSection) style = { marginTop: "3rem" };
  return (
    <div className={"app-page-title app-page-title-alt-1"} style={style}>
      <div>
        <div className="app-page-title--first">
          <div className="app-page-title--heading">
            {innerSection ? (
              <h4>{titleHeading}</h4>
            ) : (
              <Breadcrumb
                tag="nav"
                listTag="h4"
                className="px-1"
                listClassName="bg-neutral-primary"
              >
                {pathItems.map(item => {
                  return (
                    <BreadcrumbItem
                      tag={Link}
                      to={item.path}
                      active={item.active}
                    >
                      {item.label}
                    </BreadcrumbItem>
                  );
                })}
              </Breadcrumb>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">{children}</div>
    </div>
  );
};

export default withRouter(SectionHeader);
