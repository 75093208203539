import React from 'react';
import { connect } from 'react-redux';

import { Card, CardBody, Col } from 'reactstrap';
import Footer from '../Footer';
import StaticPageHeader from '../StaticPageHeader';
import { getCMSContent } from '../../actions/cms/cmsActions';

class Policy extends React.PureComponent {

  constructor() {
    super();
    this.state = {
        content: '',
        loading: true
    }
}


componentDidMount() {
    this.props.getCMSContent();
}
componentWillReceiveProps(nextProps) {
    if (nextProps.cms.cmsContentSuccess) {
        let policy = nextProps.cms.cmsContent.policy;
        this.setState({ loading: false, content: policy }, () => {
        })
    }
}
  render() {
    return (
      <React.Fragment>
        <StaticPageHeader/>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 8, offset: 2 }} style={{marginTop:'50px'}}>
          <Card>
            <CardBody>
              {/* <div className="card__title">
                <h5 className="bold-text">{"Help"}</h5>
              </div> */}
              <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
              </CardBody>
          </Card>
        </Col>
        <Footer />
      </React.Fragment>
    );
  }
};
const mapStateToProps = state => ({
  cms: state.cms,
});

const mapDispatchToProps = dispatch => ({
  getCMSContent: () => getCMSContent(dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Policy);