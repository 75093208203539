/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import { getAssetFinancials } from "../../../actions/financial";
import TextWithFrequency from "./TextWithFrequency";
import AddFinancial from "./AddFinancial";
import EditableText from "../../../shared/components/form/EditableText";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

class LeaseAndDeposit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      unitNames: []
    };
  }

  handleLeaseNameChange = (unitIndex, unitName) => {
    let unitNames = this.state.unitNames;
    unitNames[unitIndex] = unitName;
    this.setState({
      unitNames
    });
  };

  getFinancialData = (category, unitName) => {
    const {
      financial: { financials }
    } = this.props;
    let financialData;
    if (financials.length > 0) {
      financialData = financials.find(financial => {
        if (unitName && financial.meta) {
          return (
            financial.type === "INCOME" &&
            financial.category === category &&
            financial.meta.leaseUnitName === unitName
          );
        } else {
          return financial.type === "INCOME" && financial.category === category;
        }
      });
      if (financialData) {
        return financialData;
      }
    }

    const currentDate = new Date();
    const defaultDueDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      5
    );
    let dueDates = [defaultDueDate];
    if (category === "RENT") {
      dueDates.push(null);
    }
    return {
      type: "INCOME",
      category: category,
      frequency: category === "DEPOSIT" ? "One-Time" : "Monthly",
      amount: "",
      dueDates: dueDates,
      notify: true,
      placeholder: true
    };
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      financial: { getFinancialSuccess, financials },
      assetBasicInfo
    } = nextProps;

    if (!isFetching(assetBasicInfo)) {
      if (isSuccess(assetBasicInfo)) {
        if (getFinancialSuccess) {
          let unitNames = [];
          let existingUnitNames = new Set(
            financials
              .filter(f => f.category === "RENT")
              .map(f => f.meta && f.meta.leaseUnitName)
          );

          let unitNameIt = existingUnitNames.values();

          for (let idx = 0; idx < assetBasicInfo.data.basicInfo.units; idx++) {
            const existingUnitName = unitNameIt.next();
            unitNames[idx] =
              existingUnitName.value === undefined
                ? idx + 1
                : existingUnitName.value;
          }

          return { unitNames: unitNames };
        }
      }
    }
    return null;
  }

  render() {
    const {
      isShare,
      financial: { meta }
    } = this.props;
    const assetSlug = this.props.match.params.asset;

    return this.state.unitNames.map((unitName, index) => {
      const leaseData = this.getFinancialData("RENT", unitName);
      const depositData = this.getFinancialData("DEPOSIT", unitName);
      leaseData.meta = { leaseUnitName: unitName };
      depositData.meta = { leaseUnitName: unitName };

      return (
        <div className="expenses-block" key={unitName + index}>
          <div className="titleblock mb-4">
            <h4 style={{ display: "inline-block" }}>Unit: </h4>
            <EditableText
              value={
                leaseData.meta.leaseUnitName &&
                leaseData.meta.leaseUnitName.toString()
              }
              handleChange={value => {
                this.handleLeaseNameChange(index, value);
                leaseData.meta.leaseUnitName = value;
                depositData.meta.leaseUnitName = value;
              }}
            />
          </div>
          <div className="row">
            <TextWithFrequency
              key={"RENT" + index}
              data={leaseData}
              placeholder={leaseData.placeholder}
              assetSlug={assetSlug}
              info={meta}
              isShare={isShare}
              index={index}
            />

            <TextWithFrequency
              key={"DEPOSIT" + index}
              data={depositData}
              placeholder={depositData.placeholder}
              assetSlug={assetSlug}
              info={meta}
              isShare={isShare}
              index={index}
            />
          </div>
        </div>
      );
    });
  }
}

class FinancialListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: "",
      showAddFinancial: false
    };
  }

  componentWillMount() {
    const { getAssetFinancials } = this.props;
    const assetSlug = this.props.match.params.asset;
    getAssetFinancials(assetSlug);
  }

  getOtherIncomeFinancials = () => {
    const {
      financial: { financials }
    } = this.props;
    return financials.filter(
      f => f.type === "INCOME" && !["DEPOSIT", "RENT"].includes(f.category)
    );
  };

  toggleAddFinancial = () => {
    this.setState(nextState => ({
      showAddFinancial: !nextState.showAddFinancial
    }));
  };

  render() {
    const {
      financial: { meta },
      /* assetSlug, */ isShare
    } = this.props;

    const assetSlug = this.props.match.params.asset;
    const otherIncomeFinancials = this.getOtherIncomeFinancials();
    return (
      <div className="px-3 py-4">
        <div className="border-0 text-right">
          <a
            href="#"
            className="mb-3 ml-auto"
            onClick={this.toggleAddFinancial}
          >
            <Button color="primary" className="m-2">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span className="btn-wrapper--label">Add Income</span>
            </Button>
          </a>
        </div>

        <LeaseAndDeposit
          unitNames={this.state.unitNames}
          financial={this.props.financial}
          {...this.props}
        />

        <div className="row">
          {otherIncomeFinancials.map((financial, index) => {
            return (
              <TextWithFrequency
                key={financial.category + index}
                data={financial}
                assetSlug={assetSlug}
                info={meta}
                isShare={isShare}
                index={index}
                placeholder={financial.placeholder}
              />
            );
          })}
        </div>

        <AddFinancial
          modalOpen={this.state.showAddFinancial}
          type={"INCOME"}
          toggleModal={this.toggleAddFinancial}
          assetSlug={assetSlug}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  financial: state.financial,
  assetBasicInfo: state.assetBasicInfo
});

const mapDispatchToProps = dispatch => {
  return {
    getAssetFinancials: slug => getAssetFinancials(slug, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancialListing);
