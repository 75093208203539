/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Col,
  Row,
  Button,
  ButtonGroup,
  InputGroup,
  InputGroupButtonDropdown,
  InputGroupAddon,
  InputGroupText,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  UncontrolledTooltip
} from "reactstrap";

import FinancialDatesField from "./FinancialDatesField";
import FinancialMetaData from "./FinancialMetaData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../../shared/components/ConfirmModal";

import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { unmaskCurrency, getLabelForCategory } from "../../../helpers";
import {
  calculatePayment,
  calculateMortgage
} from "../../../helpers/assetCalculator";

import {
  addFinancialToAsset,
  updateAssetFinancial,
  deleteAssetFinancial
} from "../../../actions/financial";

const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: true
});

const percentMask = createNumberMask({
  suffix: "%",
  prefix: "",
  allowDecimal: true
});

class TextWithFrequency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDueDate: false,
      dropdownOpen: false,
      installmentCount: props.data ? props.data.dueDates.length : 1,
      financial: props.data || {},
      showLeaseInput: false,
      showDelete: false
      // leaseUnitName: (props.data &&  props.data.meta) ? props.data.meta.leaseUnitName : null,
    };
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  myRef = React.createRef();

  handleClickOutside = e => {
    if (this.myRef.current && !this.myRef.current.contains(e.target)) {
      const state = { showLeaseInput: false };
      if (this.state.financial.meta.leaseUnitName === "") {
        //if user clears out the leaseUnit field, we set the default value supplied from parent
        state.financial = {
          ...this.state.financial,
          meta: this.props.financial.meta
        };
      }
      this.setState(state, () => {
        this.updateFinancial(this.state.financial);
      });
    }
  };

  toggleDropDown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleDelete = () => {
    this.setState({ showDelete: !this.state.showDelete });
  };

  updateInstallmentCount = count => {
    this.setState({
      installmentCount: count
    });
  };

  showLeaseInputField = () => {
    this.setState({ showLeaseInput: true });
  };

  handleLeaseNameChange = target => {
    // const value = target.value?target.value:this.props.defaultLeaseName;
    this.setState({
      financial: {
        ...this.state.financial,
        meta: { leaseUnitName: target.value }
      }
    });
    // this.setState({leaseUnitName: target.value});
  };

  changeFrequency = event => {
    let { financial } = this.state;
    financial.frequency = event.target.innerText;
    this.updateFinancial(financial);
  };

  handleChange = target => {
    let { financial } = this.state;
    financial.amount = target.value;
    this.updateFinancial(financial);
  };

  toggleReminder = () => {
    let { financial } = this.state;
    financial.notify = !financial.notify;
    this.updateFinancial(financial);
  };

  deleteFinancial = () => {
    let { financial } = this.state;
    const { deleteAssetFinancial, assetSlug } = this.props;
    this.setState({ showDelete: false }, () => {
      deleteAssetFinancial({
        slug: assetSlug,
        id: financial._id
      });
    });
  };

  changeValueType = isPercent => {
    let { financial } = this.state;
    financial.isPercent = isPercent;
    this.updateFinancial(financial);
  };

  updateFinancialMetadata = metadata => {};

  updateFinancial = financial => {
    this.setState({ financial }, () => {
      const { financial } = this.state;
      const frequency = financial.frequency;
      const hasValidDate =
        frequency === "Half-Yearly" || financial.category === "RENT"
          ? financial.dueDates.length === 2
          : financial.dueDates.length === 1;
      // const hasValidLeaseDate =
      //   financial.category === "LEASE"
      //     // ? financial.dueDates.length === 1 && financial.leaseEndDate
      //     ? financial.dueDates.length === 2
      //     : true;

      if (financial.amount !== "" && hasValidDate) {
        financial.amount = unmaskCurrency(financial.amount);
        this.saveFinancial(financial);
      }
    });
  };

  saveFinancial = values => {
    const {
      updateAssetFinancial,
      assetSlug,
      placeholder,
      addFinancialToAsset
    } = this.props;
    values.slug = assetSlug;

    // If the frequency changes right before we save, just calculate the payment
    // again
    if (values.category === "INTEREST" || values.category === "DISTRIBUTION") {
      values.amount = calculatePayment(
        values.meta,
        values.category,
        values.frequency
      );
    } else if (values.category === "MORTGAGE") {
      values.amount = calculateMortgage(values.meta);
    }
    values.document = [];
    // return
    if (placeholder)
      // It's a new insert
      addFinancialToAsset(values);
    // It's a update
    else updateAssetFinancial(values);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data } = nextProps;
    return {
      financial: data
    };
  }

  render() {
    const {
      financial,
      dropdownOpen,
      installmentCount /* leaseUnitName */
    } = this.state;
    const { index, placeholder } = this.props;
    let frequencies = ["Monthly", "Yearly"];
    if (financial.category === "DEPOSIT") {
      frequencies = ["One-Time"];
    } else if (financial.category === "REAL_ESTATE_TAXES") {
      if (installmentCount === 2) {
        frequencies = ["Half-Yearly"];
      } else {
        frequencies = ["Yearly"];
      }
      financial.frequency = frequencies[0];
    }

    if (
      ["INTEREST"].includes(financial.category) ||
      ["DISTRIBUTION"].includes(financial.category)
    ) {
      frequencies.push("Quarterly");
    }

    const self = this;
    let titleBlock;
    if (financial.category === "REAL_ESTATE_TAXES") {
      titleBlock = (
        <div>
          <h4 style={{ display: "inline-block" }}>
            {getLabelForCategory(financial.category)}
          </h4>
          <h5 style={{ display: "inline-block", padding: "0 10px" }}>
            # of Installments
          </h5>
          <ButtonGroup>
            <Button
              onClick={() => this.updateInstallmentCount(1)}
              color={installmentCount === 1 ? "primary" : "secondary"}
              active={true}
            >
              1
            </Button>
            <Button
              onClick={() => this.updateInstallmentCount(2)}
              color={installmentCount === 2 ? "primary" : "secondary"}
              active={true}
            >
              2
            </Button>
          </ButtonGroup>
        </div>
      );
    } else {
      titleBlock = (
        <div>
          <h4 style={{ display: "inline-block" }}>
            {getLabelForCategory(financial.category)}
          </h4>
        </div>
      );
    }

    const financialCard = (
      <Card className="card-box mb-5">
        <div className="card-header pr-2">
          <div className="card-header--title py-2 font-size-lg font-weight-bold">
            {titleBlock}
          </div>
          <div className="card-header--actions">
            <Button
              size="md"
              onClick={this.toggleReminder}
              color={
                financial.notify && !financial.placeholder
                  ? "neutral-first"
                  : ""
              }
              active={financial.notify && !financial.placeholder}
              id="reminderTooltip"
            >
              <FontAwesomeIcon size="1x" icon={faBell} />
            </Button>
            <UncontrolledTooltip target="reminderTooltip">
              Set Reminder
            </UncontrolledTooltip>
            {!financial.placeholder && (
              <>
                <Button
                  size="md"
                  onClick={this.toggleDelete}
                  color={"neutral-danger"}
                  id="deleteTooltip"
                >
                  <FontAwesomeIcon size="1x" icon={faTrash} />
                </Button>

                <UncontrolledTooltip target="deleteTooltip">
                  Delete Financial
                </UncontrolledTooltip>
              </>
            )}
          </div>
        </div>

        <Row className="p-4 mt-4">
          <Col className=" inputfilds-block">
            <InputGroup size={"lg"}>
              {financial.category === "MORTGAGE" ||
              financial.category === "INTEREST" ||
              financial.category === "DISTRIBUTION" ? (
                <FinancialMetaData
                  financial={financial}
                  assetSlug={this.props.assetSlug}
                  onBlur={({ target }) => this.handleChange(target)}
                />
              ) : financial.isPercent ? (
                <MaskedInput
                  mask={percentMask}
                  value={financial.amount}
                  onBlur={({ target }) => this.handleChange(target)}
                  className="form-control form-control-lg"
                  placeholder={"Enter Percent"}
                />
              ) : (
                <MaskedInput
                  mask={currencyMask}
                  value={financial.amount}
                  onBlur={({ target }) => this.handleChange(target)}
                  className="form-control form-control-lg"
                  placeholder={"Enter Amount"}
                />
              )}
              {frequencies.length > 1 ? (
                <InputGroupButtonDropdown
                  addonType="append"
                  isOpen={dropdownOpen}
                  toggle={this.toggleDropDown}
                >
                  <DropdownToggle className="btntoggal" color="none" caret>
                    {financial.frequency}
                  </DropdownToggle>
                  <DropdownMenu className="dropdown_input">
                    {frequencies.map((frequency, i) => {
                      return (
                        <DropdownItem
                          className="custom-dropdown-item"
                          onClick={self.changeFrequency}
                          key={i}
                        >
                          {frequency}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </InputGroupButtonDropdown>
              ) : (
                <InputGroupAddon addonType="append">
                  <InputGroupText>{frequencies[0]}</InputGroupText>
                </InputGroupAddon>
              )}
            </InputGroup>
            {financial.showPercent && (
              <ButtonGroup>
                <Button
                  color="link"
                  active={!financial.isPercent}
                  className="btn-link-primary p-2"
                  onClick={() => this.changeValueType(false)}
                >
                  <span>Dollar</span>
                </Button>

                <Button
                  color="link"
                  active={financial.isPercent}
                  className="btn-link-primary p-2"
                  onClick={() => this.changeValueType(true)}
                >
                  <span>Percent</span>
                </Button>
              </ButtonGroup>
            )}
          </Col>
          <FinancialDatesField
            financial={financial}
            installmentCount={installmentCount}
            updateFinancial={this.updateFinancial}
            handleReminderChange={this.toggleReminder}
            placeholderData={placeholder}
          />
          <ConfirmModal
            title={"Are you sure to delete this financial?"}
            subtitle={"You cannot undo this operation."}
            type="danger"
            modalOpen={this.state.showDelete}
            onCancel={this.toggleDelete}
            onSubmit={this.deleteFinancial}
            error={this.state.error}
            loading={this.state.loading}
            btnText={"Delete"}
            btnTextOnLoading={"Deleting"}
          />
          <div className="clearfix" />
        </Row>
      </Card>
    );
    if (this.props.fullWidth) {
      return financialCard;
    } else {
      return (
        <Col xl="6" key={`finance-card-${index}`}>
          {financialCard}
        </Col>
      );
    }
  }
}

const mapDispatchToProps = dispatch => ({
  updateAssetFinancial: data => updateAssetFinancial(data, dispatch),
  addFinancialToAsset: data => addFinancialToAsset(data, dispatch),
  deleteAssetFinancial: data => deleteAssetFinancial(data, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(TextWithFrequency);
