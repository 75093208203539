import React, {PureComponent} from "react";
import PropTypes from "prop-types";

export default class EditableText extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
      value: props.value
    };
  }

  showInput = () => {
    this.setState({showInput: true});
  };

  onChange = e => {
    this.setState({
      value: e.target.value
    });
  };

  handleChange = e => {
    this.setState({
      showInput: false
    });
    // this.props.value = this.state.value;
    this.props.handleChange(this.state.value);
  };

  render() {
    if (this.state.showInput) {
      return (
        <input
          type="text"
          className="form-control ml-3"
          style={{display: "inline-block", width: "30%"}}
          onChange={this.onChange}
          value={this.state.value}
          onBlur={this.handleChange}
        />
      );
    } else {
      return (
        <h5
          style={{
            display: "inline-block",
            padding: "0 10px",
            cursor: "pointer"
          }}
          onClick={this.showInput}
        >
          - {this.state.value}
          <i className="fas fa-pencil-alt ml-3" />
        </h5>
      );
    }
  }
}
