import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { SidebarProps } from "../../../../shared/prop-types/ReducerProps";
import { Link } from "react-router-dom";
import LogoAlt from "../../../../img/logo-alt.png";

import clsx from "clsx";
import SidebarContent from "../sidebar_content";
import SidebarUser from "../sidebar_user";

class Sidebar extends Component {
  render() {
    return (
      <div>
        <div className="app-sidebar app-sidebar--dark ">
          <Fragment>
            <div className="app-sidebar--header">
              <div className="header-nav-logo">
                <Link
                  to="/dashboard"
                  className="nav-logo"
                  title="BetterCapital"
                >
                  <img alt="BetterCapital.US" src={LogoAlt} width={180} />
                </Link>
              </div>
              <button
                className={clsx(
                  "navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn",
                  { "is-active": this.props.sidebar.show }
                )}
                onClick={this.props.changeMobileSidebarVisibility}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
          </Fragment>

          <SidebarUser />
          <div className="app-sidebar--content">
            <SidebarContent {...this.props} />
          </div>
        </div>

        <div
          onClick={this.props.changeMobileSidebarVisibility}
          className={clsx("app-sidebar-overlay", {
            "is-active": this.props.sidebar.show
          })}
        />
      </div>
    );
  }
}

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  assets: PropTypes.array
};

export default Sidebar;
