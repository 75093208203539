/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAssetFinancials } from "../../../actions/financial";
import TextWithFrequency from "./TextWithFrequency";
import AddFinancial from "./AddFinancial";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const requiredCategories = [
  "REAL_ESTATE_TAXES",
  "INSURANCE",
  "MORTGAGE",
  "HOA",
  "PROPERTY MANAGEMENT"
];

class FinancialListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: "",
      showAddFinancial: false
    };
  }

  componentWillMount() {
    const { getAssetFinancials /*  assetSlug */ } = this.props;
    const assetSlug = this.props.match.params.asset;

    getAssetFinancials(assetSlug);
  }

  toggleAddFinancial = () => {
    this.setState(nextState => ({
      showAddFinancial: !nextState.showAddFinancial
    }));
  };

  getDefaultFrequency = category => {
    let frequency = "Yearly";
    if (["MORTGAGE", "HOA"].includes(category)) {
      frequency = "Monthly";
    } else if (category === "REAL_ESTATE_TAXES") {
      frequency = "Half-Yearly";
    }
    return frequency;
  };

  getNonRequiredFinancials = () => {
    const {
      financial: { financials }
    } = this.props;
    if (financials.length > 0) {
      return financials.filter(financial => {
        return (
          financial.type === "EXPENSE" &&
          !requiredCategories.includes(financial.category)
        );
      });
    }
    return null;
  };

  getMissingExpenseCategories = () => {
    const {
      financial: { financials }
    } = this.props;

    const existingCategories = financials.map(a => a.category);
    return requiredCategories.filter(
      category => !existingCategories.includes(category)
    );
  };

  getFinancialData = category => {
    const {
      financial: { financials }
    } = this.props;
    if (financials.length > 0) {
      const financialData = financials.find(financial => {
        return financial.type === "EXPENSE" && financial.category === category;
      });
      if (financialData) {
        // If the financialdata has percent or if it is a PM Fee
        financialData.showPercent =
          financialData.isPercent ||
          financialData.category === "PROPERTY MANAGEMENT";
        return financialData;
      }
    }
    const currentDate = new Date();
    const defaultDueDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      5
    );
    return {
      type: "EXPENSE",
      category: category,
      frequency: this.getDefaultFrequency(category),
      amount: "",
      dueDates: [defaultDueDate],
      notify: true,
      placeholder: true,
      showPercent: category === "PROPERTY MANAGEMENT"
    };
  };

  getExpenseFinancials = () => {
    const {
      financial: { financials }
    } = this.props;
    return financials.filter(f => f.type === "EXPENSE");
  };

  render() {
    const {
      financial: { meta },
      isShare
    } = this.props;
    const assetSlug = this.props.match.params.asset;
    let nonRequiredFinancials = this.getNonRequiredFinancials();
    return (
      <div className="px-3 py-4">
        <div className="border-0 text-right">
          <a
            href="#"
            className="mb-3 ml-auto"
            onClick={this.toggleAddFinancial}
          >
            <Button color="primary" className="m-2">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span className="btn-wrapper--label">Add Expense</span>
            </Button>
          </a>
        </div>
        <div className="row">
          {requiredCategories &&
            requiredCategories.map((category, index) => {
              const data = this.getFinancialData(category);
              return (
                <TextWithFrequency
                  key={category + index}
                  data={data}
                  placeholder={data.placeholder}
                  assetSlug={assetSlug}
                  info={meta}
                  isShare={isShare}
                  index={index}
                />
              );
            })}
          {nonRequiredFinancials &&
            nonRequiredFinancials.map((financial, index) => {
              return (
                <TextWithFrequency
                  key={financial.category + index}
                  data={financial}
                  placeholder={false}
                  assetSlug={assetSlug}
                  info={meta}
                  isShare={isShare}
                  index={index}
                />
              );
            })}
        </div>
        <AddFinancial
          modalOpen={this.state.showAddFinancial}
          type={"EXPENSE"}
          toggleModal={this.toggleAddFinancial}
          assetSlug={assetSlug}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  financial: state.financial
});

const mapDispatchToProps = dispatch => {
  return {
    getAssetFinancials: slug => getAssetFinancials(slug, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancialListing);
