import React, { PureComponent } from "react";
import { Button, Badge } from "reactstrap";
import { deleteDocument } from "../../../actions/asset/assetAction";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import TableWithSearch from "../../../shared/components/TableWithSearch";

class DocumentsTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5
    };
  }
  selectRow = (row, isSelect, rowIndex) => {};
  columnProps = [
    {
      dataField: "originalName",
      text: "Name",
      sort: true
    },
    {
      dataField: "source",
      text: "Uploaded via",
      sort: true
    },
    {
      dataField: "uploadedBy",
      text: "Uploaded By",
      sort: true,
      formatter: (cell, row) => {}
    },
    {
      dataField: "assetId",
      text: "Asset",
      sort: true,
      formatter: (cell, row) => {
        if (cell) {
          return <Badge color={"primary"}>{cell.asset_name}</Badge>;
        }
      }
    },

    {
      text: "Tags",
      dataField: "tags",
      formatter: (cell, row) => {
        return cell.map(tag => {
          return <Badge color="badge badge-first m-2">{tag}</Badge>;
        });
      }
    },
    {
      text: "Actions",
      dataField: "",
      isDummyField: true,

      formatter: (cell, row) => {
        return (
          <>
            <Button
              className={"btn-sm btn-neutral-primary mr-2"}
              onClick={() => this.props.toggleViewFileModal(row._id)}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
            <Button
              className={"btn-sm btn-neutral-danger"}
              onClick={() => this.props.toggleDeleteFileModal(row._id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </>
        );
      }
    }
  ];

  handleDeleteDocument = id => {
    deleteDocument(id);
  };

  handleTableChange = (type, attrs) => {
    const { page, sizePerPage } = attrs;
    this.setState({ page, sizePerPage }, () => {
      this.props.handleTableChange(type, attrs);
    });
  };

  render() {
    const { documents, totalCount } = this.props;
    return (
      <TableWithSearch
        keyField="_id"
        data={documents}
        totalSize={totalCount}
        page={this.state.page}
        sizePerPage={this.state.sizePerPage}
        columns={this.columnProps}
        onTableChange={this.handleTableChange}
        searchPlaceholder="Search Documents..."
        noDataIndicator="No Documents found!"
      />
    );
  }
}

export default DocumentsTable;
