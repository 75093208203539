import React from 'react';
import { connect } from 'react-redux';

import { Card, CardBody, Col, /* Table */ } from 'reactstrap';
import Footer from '../Footer';
import StaticPageHeader from '../StaticPageHeader';

import { getCMSContent } from '../../actions/cms/cmsActions';

class Help extends React.PureComponent {
  constructor() {
    super();
    this.state = {
        content: '',
        loading: true
    }
}

componentDidMount() {
  this.props.getCMSContent();
}
componentWillReceiveProps(nextProps) {
  if (nextProps.cms.cmsContentSuccess) {
      let policy = nextProps.cms.cmsContent.faq;

      this.setState({ loading: false, content: policy })
  }
}

  render() {
    return (
      <React.Fragment>
        <StaticPageHeader/>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 8, offset: 2 }} style={{marginTop:'50px'}}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h3 className="bold-text">{"Faqs"}</h3>
              </div>
              <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
             </CardBody>
          </Card>
        </Col>
        {/* </Row> */}
        <Footer />
      </React.Fragment>
    );
  }
};
const mapStateToProps = state => ({
  auth: state.login,
  cms: state.cms,
});

const mapDispatchToProps = dispatch => ({
  getCMSContent: () => getCMSContent(dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Help);
