import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import { Button, Row, Col } from "reactstrap";
import moment from "moment";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

moment.locale("en", {
  ordinal: num => {
    const b = num % 10;
    const output =
      ~~((num % 100) / 10) === 1
        ? "th"
        : b === 1
        ? "st"
        : b === 2
        ? "nd"
        : b === 3
        ? "rd"
        : "th";
    return num + "<sup>" + output + "</sup>";
  }
});

class CustomDateText extends PureComponent {
  render() {
    let className = "d-50";
    if (this.props.isSet) {
      className += " active";
    }
    if (this.props.value === "") {
      return (
        <Button
          color="danger"
          onClick={this.props.onClick}
          className={className}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon
              icon={faPlus}
              className="d-flex align-self-center display-4"
              style={{ marginLeft: "-5px" }}
            />
          </span>
        </Button>
      );
    } else if (this.props.displayType === "date") {
      const dateInt = parseInt(moment(this.props.value).format("D"));
      const marginleft = dateInt < 10 ? "-3px" : "-7px";
      return (
        <Button onClick={this.props.onClick} className={className}>
          <h5
            className="d-flex align-self-center mt-3 "
            style={{
              marginLeft: marginleft,
              fontWeight: "600"
            }}
          >
            {parse(moment(this.props.value).format("Do"))}
          </h5>
        </Button>
      );
    } else if (this.props.displayType === "year") {
      return (
        <Button onClick={this.props.onClick} className={className}>
          <h5
            className="d-flex align-self-center"
            style={{ marginLeft: "-15px" }}
          >
            {moment(this.props.value).format("MMM YYYY")}
          </h5>
        </Button>
      );
    } else {
      return (
        <Button onClick={this.props.onClick} className={className}>
          <h5
            className="d-flex align-self-center"
            style={{ marginLeft: "-10px" }}
          >
            {moment(this.props.value).format("MMM DD")}
          </h5>
        </Button>
      );
    }
  }
}

class FinancialDatePicker extends PureComponent {
  handleChange = date => {
    this.props.onChange(date);
  };

  render() {
    return (
      <Col xs="auto" className="px-1 pl-2">
        <label htmlFor="staticEmail" className="col-form-label">
          {this.props.label}
        </label>
        <DatePicker
          dateFormat="MM/dd/yyyy"
          placeholderText={this.props.placeholder}
          selected={this.props.defaultValue}
          onChange={this.handleChange}
          minDate={this.props.minDate}
          showMonthDropdown
          showYearDropdown
          customInput={
            <CustomDateText
              isSet={this.props.isSet}
              displayType={this.props.displayType}
            />
          }
          showPopperArrow={false}
          popperModifiers={{
            offset: {
              enabled: true,
              offset: "-10px, 5px"
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "viewport"
            }
          }}
        />
      </Col>
    );
  }
}

class FinancialDatesField extends React.Component {
  updateDueDates = (date, idx) => {
    const financial = this.props.financial;
    financial.dueDates[idx] = date;
    this.props.updateFinancial(financial);
  };

  render() {
    const { financial, installmentCount, placeholderData } = this.props;
    let dueDates = [];
    for (let i = 0; i < installmentCount; i++) {
      let displayType = "month";

      const selectedDateValue =
        typeof financial.dueDates[i] !== "undefined" &&
        financial.dueDates[i] !== null
          ? new Date(financial.dueDates[i])
          : null;
      let minDate = null;
      if (minDate === null && i === 1) {
        minDate =
          typeof financial.dueDates[i - 1] !== "undefined" &&
          financial.dueDates[i - 1] !== null
            ? new Date(financial.dueDates[i - 1])
            : null;
      }
      let dueDateLabel;
      if (
        financial.category === "DEPOSIT" ||
        financial.category === "CAPITAL EXPENDITURE" ||
        financial.category === "PAYOUT"
      ) {
        dueDateLabel = "Paid on";
        displayType = "year";
      } else {
        // dueDateLabel =
        //   installmentCount === 2 ? "Due Date - " + (i + 1) : "Due Date";
        dueDateLabel = "Due on";
        if (
          installmentCount === 2 &&
          financial.category === "REAL_ESTATE_TAXES"
        ) {
          if (i === 0) dueDateLabel = "1st Due";
          else if (i === 1) dueDateLabel = "2nd Due";
        } else if (financial.category === "RENT") {
          if (i === 0) {
            displayType = "date";
            dueDateLabel = "Due on";
          } else if (i === 1) {
            displayType = "year";
            dueDateLabel = "Lease End";
          }
        } else if (financial.frequency === "Monthly") {
          displayType = "date";
        }
      }

      dueDates.push(
        <FinancialDatePicker
          key={this.props.key + dueDateLabel}
          label={dueDateLabel}
          placeholder={dueDateLabel}
          defaultValue={selectedDateValue}
          minDate={minDate}
          isSet={!placeholderData}
          onChange={e => this.updateDueDates(e, i)}
          displayType={displayType}
          className="mt-1"
        />
      );
    }
    return <Row className="mt-n5 mr-1">{dueDates}</Row>;
  }
}

export default FinancialDatesField;
