import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "./components/Layout/index";

import Activateuser from "./components/Activation/activation";
import ResetPassword from "./components/ResetPassword/";
import Policy from "./components/Policy";
import Terms from "./components/Terms";
import AddTestimonial from "./components/Testimonial/AddTestimonial";

import Dashboard from "./components/Dashboard";
import Settings from "./components/Settings/";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import NotFound from "./components/NotFound/NotFound";
// import Share from "./components/Share";
import Deal from "./components/Deals";

import AssetDetails from "./components/AssetDetails";
import Chat from "./components/Chat";
import Transactions from "./components/Transactions";
import BankAccounts from "./components/BankAccounts";

import Help from "./components/Help";
import Reports from "./components/Reports";
import Reminders from "./components/Reminders";
import Documents from "./components/Documents";
import Contacts from "./components/Contacts";

const Pages = props => {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/settings" component={Settings} />
      <Route path="/assets/:asset" component={AssetDetails} />
      <Route exact path="/chats/" component={Chat} />
      <Route exact path="/chats/:userId" component={Chat} />
      {/* <Route exact path="/chats/:userId/:asset" component={Chat} /> */}
      <Route path="/transactions" component={Transactions} />
      <Route path="/banks" component={BankAccounts} />
      <Route path="/documents" component={Documents} />
      <Route path="/reports/" component={Reports} />
      <Route path="/reminders" component={Reminders} />
      <Route path="/contacts" component={Contacts} />

      <Route path="*" exact={true} component={NotFound} />
    </Switch>
  );
};

const wrappedRoutes = () => (
  <Layout isShare={false}>
    <div className="app-content">
      <Route path="/" component={Pages} />
    </div>
  </Layout>
);

const shareRoutes = props => (
  <Layout isShare={true}>
    <div className="app-content">
      <Route path="/deals" component={Deal} {...props} />
    </div>
  </Layout>
);

const Router = () => (
  <Switch>
    <Route exact path="/signup" component={Signup} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/resetpassword" component={ResetPassword} />
    <Route exact path="/activateuser" component={Activateuser} />
    <Route exact path="/add-testimonial" component={AddTestimonial} />

    <Route exact path="/faqs" component={Help} />
    <Route exact path="https://bettercapital.us/privacy" component={Policy} />
    <Route exact path="https://bettercapital.us/terms" component={Terms} />
    <Route path="/deals" component={shareRoutes} />
    <Route path="/" component={wrappedRoutes} />
    <Route path="*" exact={true} component={NotFound} />
  </Switch>
);

export default Router;
