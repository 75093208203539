import React, { Component } from "react";
import { connect } from "react-redux";
import MyDropZone from "../../../shared/components/DropZone";
import showToast from "../../../helpers/toastNotification";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { addAssetMedias } from "../../../actions/asset/assetAction";

class AddMedia extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
      loading: false,
      error: null
    };
  }

  onChange = newFiles => {
    this.setState({ files: newFiles });
  };

  handleSubmit = e => {
    const { files } = this.state;
    if (files.length > 0) {
      const { assetSlug } = this.props;
      this.setState({ loading: true, error: null }, () => {
        this.props.addAssetMedias({
          slug: assetSlug,
          tags: "asset, media",
          fileName: "attachments",
          files: files,
          folder: "photos"
        });
      });
    }
  };

  handleReset = () => {
    this.setState({ loading: false, error: null, files: [] }, () => {
      this.props.toggleModal();
    });
  };

  componentWillReceiveProps(nextProps) {
    const { assetMedia } = nextProps;
    if (this.state.loading && assetMedia) {
      if (assetMedia.isAddMediasSuccess) showToast("s", "Asset Media added!");
      else showToast("e", assetMedia.error);
      this.setState({ loading: false });
    }
  }

  render() {
    const { modalOpen } = this.props;
    const { loading } = this.state;

    return (
      <Modal
        isOpen={modalOpen}
        className={"modal-dialog modal-dialog-centered"}
      >
        <ModalHeader toggle={this.handleReset}>Add Media Files</ModalHeader>
        <ModalBody>
          <MyDropZone value={this.state.files} onChange={this.onChange} />
        </ModalBody>
        <ModalFooter>
          <Button
            type="reset"
            className="btn btn-secondary px-5 d-inline-block mx-2"
            onClick={this.handleReset}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="btn savebtns btn-primary px-5  d-inline-block mx-2"
            type="submit"
            onClick={e => this.handleSubmit(e)}
            disabled={loading}
          >
            {loading ? "Uploading" : "Upload"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  assetMedia: state.assetMedia
});

const mapDispatchToProps = dispatch => ({
  addAssetMedias: files => addAssetMedias(files, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMedia);
