/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Icon from "@mdi/react";

import { connect } from "react-redux";
import { mdiContentDuplicate } from "@mdi/js";
import { mdiClockOutline } from "@mdi/js";
import { mdiCheck } from "@mdi/js";
import { getResourcesStats } from "../../actions/resource";
import { Card, Nav, NavItem, NavLink } from "reactstrap";
import queryString from "query-string";

const getTabname = path => {
  switch (path) {
    case "pending":
      return "PENDING";
    case "duplicate":
      return "DUPLICATE";
    default:
      return "CURRENT";
  }
};

class TransactionsSidebar extends PureComponent {
  componentDidMount() {
    this.props.getResourcesStats("");
  }

  render() {
    const type = queryString.parse(this.props.location.search).type;
    const tabname = getTabname(type);
    return (
      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2 font-size-l">
        <Card className="card-box mb-5">
          <div className="bg-composed-wrapper bg-primary">
            <div className="bg-composed-wrapper--content text-light p-4">
              <h4 className="mb-4">Transactions</h4>
              <p className="mb-0 opacity-7">Analyze and Drilldown</p>
            </div>
          </div>
          <Nav pills className="nav-primary flex-column p-3">
            <NavItem>
              <NavLink
                tag={Link}
                to={"/transactions"}
                active={tabname === "CURRENT"}
              >
                <div className="nav-link-icon">
                  <Icon
                    className="icon"
                    color={tabname === "CURRENT" ? "#fff" : "#7a7b97"}
                    path={mdiCheck}
                    size={1}
                  />
                </div>
                <span>Current</span>
              </NavLink>
              <NavLink
                tag={Link}
                to={"/transactions?type=pending"}
                active={tabname === "PENDING"}
              >
                <div className="nav-link-icon">
                  <Icon
                    className="icon"
                    color={tabname === "PENDING" ? "#fff" : "#7a7b97"}
                    path={mdiClockOutline}
                    size={1}
                  />
                </div>
                <span>Pending</span>
              </NavLink>
              <NavLink
                tag={Link}
                to={"/transactions?type=duplicate"}
                active={tabname === "DUPLICATE"}
              >
                <div className="nav-link-icon">
                  <Icon
                    className="icon"
                    color={tabname === "DUPLICATE" ? "#fff" : "#7a7b97"}
                    path={mdiContentDuplicate}
                    size={1}
                  />
                </div>
                <span>Duplicate</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resourcesProp: state.resources
});

const mapDispatchToProps = dispatch => ({
  getResourcesStats: query => getResourcesStats(query, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionsSidebar);
