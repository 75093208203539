import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import ChatUserCard from "./ChatUserCard";
import ChatSidebar from "./ChatSidebar";
import socketIoObject from "./socketinit";

class ChatCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      contactLoaded: false,
      queryParams: null,
      userId: null,

      currentMessages: null,
      activeContact: null,
      defaultMessage: "",
      openContacts: false
    };
  }

  componentDidMount() {
    const { user } = this.props.auth;
    const userId = this.props.match.params.userId;
    const params = this.props.location;
    this.setState({ userId: userId, queryParams: params });

    socketIoObject.emit("USER_CONNECTED", { auth: user });

    socketIoObject.on("USER_CONNECTED_SUCCESS", () => {
      socketIoObject.emit("GET_CONTACTS", { auth: user });
    });

    socketIoObject.on("ADD_NEW_CONTACT_SUCCESS", data => {
      this.setState({ contactLoaded: false }, () => {
        socketIoObject.emit("GET_CONTACTS", { auth: user });
      });
    });

    socketIoObject.on("ADD_NEW_CONTACT_FAILURE", data => {
      // if(data.errorCode === "invalid_email") {
      this.props.history.push("/chats");
      // }

      // if (data.errorCode !== "already_exists") {
      //   showToast("e", data.message);
      // }
    });

    socketIoObject.on("GET_CONTACTS_SUCCESS", data => {
      this.setState({ contacts: data.contacts, contactLoaded: true });
    });

    socketIoObject.on("MESSAGE_SENT", data => {
      const { contacts } = this.state;
      const contactIndex = contacts.findIndex(c => c.id === data.receiverId);
      if (contactIndex > -1) {
        let messages = contacts[contactIndex].messages.push(data);
        this.setState({ contacts, currentMessages: messages });
      }
    });

    socketIoObject.on("GOT_NEW_MESSAGE", data => {
      const { contacts } = this.state;
      const contactIndex = contacts.findIndex(c => c.id === data.senderId);
      if (contactIndex > -1) {
        let messages = contacts[contactIndex].messages.push(data);
        this.setState({ contacts, currentMessages: messages });
      }
    });
  }

  componentWillUnmount() {
    const { user } = this.props.auth;

    socketIoObject.emit("USER_DISCONNECT", { auth: user });

    socketIoObject.off("USER_CONNECTED_SUCCESS");

    socketIoObject.off("ADD_NEW_CONTACT_SUCCESS");
    socketIoObject.off("ADD_NEW_CONTACT_FAILURE");

    socketIoObject.off("GOT_NEW_MESSAGE");
    socketIoObject.off("MESSAGE_SENT");

    socketIoObject.off("MESSAGE_SENT");
    socketIoObject.off("GET_CONTACTS_SUCCESS");
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      match: { params },
      location
    } = nextProps;

    if (params && params.userId) {
      let dm = "";
      if (location.query && location.query.basicInfo) {
        dm = `Hi!, I would like to talk about ${location.query.basicInfo.address.toUpperCase()}`;
      }
      if (prevState.contactLoaded && !prevState.activeContact) {
        return {
          activeContact: prevState.contacts.find(c => c.id === params.userId),
          defaultMessage: dm
        };
      }
    }
    return null;
  }

  onOpenChat = (contact, defaultMessage, e) => {
    const { contacts } = this.state;
    if (e) e.preventDefault();
    const activeContact = contacts.find(c => c.id === contact.id);
    this.setState({
      currentMessages: activeContact.messages,
      activeContact
    });
  };

  render() {
    const { contacts, contactLoaded } = this.state;
    const { user } = this.props.auth;

    if (!contactLoaded) return <div />;
    const userId = this.props.match.params.userId;

    if (userId) {
      const { contacts } = this.state;
      const contactIndex = contacts.findIndex(c => c.id === userId);
      if (contactIndex === -1) {
        socketIoObject.emit("ADD_NEW_CONTACT", {
          auth: user,
          payload: { userId: userId }
        });
      }
    }

    return (
      <div className="app-inner-content-layout ">
        <ChatUserCard
          currentUser={user}
          activeContact={this.state.activeContact}
          defaultMessage={this.state.defaultMessage}
        />
        <ChatSidebar
          contacts={contacts}
          onClick={this.onOpenChat}
          activeContact={this.state.activeContact}
          isInnerSidebarOpen={this.props.isInnerSidebarOpen}
        />
        <div
          onClick={this.toggleSidebarMenu}
          className={clsx("sidebar-inner-layout-overlay", {
            active: this.state.toggleSidebarMenu
          })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.login,
  chat: state.chat
});

export default connect(
  mapStateToProps,
  null
)(ChatCard);
