import React, { PureComponent } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import FormField from "../../shared/components/form/FormField";
import GoogleLogin from "react-google-login";
import logo from "../../assets/images/logo.png";
import queryString from "query-string";
import { isEmpty } from "lodash";

class SignUpForm extends PureComponent {
  handleSubmit = (values, { props = this.props, setSubmitting }) => {
    this.props.onSubmit(values, this.resetForm);
    setSubmitting(false);
    return;
  };

  constructor(props) {
    super(props);
    this.captcha = null;
    this.state = {
      showPassword: false
    };

    this.showPassword = this.showPassword.bind(this);
  }

  resetForm;

  googleResponse = response => {
    this.props.onSocialLogin(response);
  };

  showPassword = e => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  render() {
    const initialValues = { name: "", email: "", password: "" };

    const validationSchema = Yup.object({
      name: Yup.string().required("Enter your Name"),
      email: Yup.string()
        .email("Enter a valid email")
        .required("Enter your email"),
      password: Yup.string("")
        .min(8, "Password must contain at least 8 characters")
        .max(20, "Max length should be less than 20")
        .required("Enter your password")
    });

    const queryParams = queryString.parse(this.props.location.search);
    if (!isEmpty(queryParams)) {
      initialValues.email = queryParams.email.replace(" ", "+");
    }
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {props => {
          const { handleSubmit, resetForm } = props;
          this.resetForm = resetForm;
          return (
            <div className="bg-white rounded my-4">
              <div className="text-center my-4">
                <img src={logo} width="250px" alt="BetterCapital.US" />
                <p className="font-size-lg text-center my-3 text-black-50">
                  Built for Landlords, by landlords
                </p>
              </div>
              <h1 className="display-4 text-center mb-3 font-weight-bold">
                Sign up - it's free!
              </h1>
              <form
                className="form"
                onSubmit={handleSubmit}
                data-evidence-id="Ox3XMkqBUkoXKZ2b"
              >
                <div className="form-group col-12 inputblock">
                  <Field
                    name="name"
                    component={FormField}
                    type="text"
                    placeholder="Name"
                  />
                </div>
                <div className="form-group col-12 inputblock">
                  <Field
                    name="email"
                    component={FormField}
                    type="email"
                    placeholder="Email"
                  />
                </div>
                <div className="form-group col-12 inputblock">
                  <Field
                    name="password"
                    component={FormField}
                    type={this.state.showPassword ? "text" : "password"}
                    placeholder="Password"
                  />
                </div>
                <div className="col-12 mb-3 text-center">
                  <button
                    className="col-10 btn btn-primary px-5"
                    disabled={this.props.loading}
                    type="submit"
                  >
                    {this.props.loading ? "Please wait..." : "Sign up"}
                  </button>
                </div>
              </form>
              <div className="text-center my-3">- or -</div>
              <div className="account-or mt-3 mb-3 text-center">
                <div className="mt-3 text-center">
                  <GoogleLogin
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Sign up with Google"
                    theme="dark"
                    onSuccess={this.googleResponse}
                    onFailure={this.onFailure}
                  />
                </div>
              </div>
              <div className="account-signup text-lg-center font-size-lg ">
                <p>
                  By clicking "Sign up", I accept the{" "}
                  <a
                    href="https://bettercapital.us/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://bettercapital.us/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy.
                  </a>
                </p>
              </div>

              <div className="account-signup  text-lg-center font-size-lg ">
                <p>
                  Already have an account? <Link to="/login">Sign in</Link>
                </p>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default SignUpForm;
