/* eslint-disable no-param-reassign */
import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import moment from "moment";
import { dateFilters } from "../../../helpers/constants";

import {
  Button,
  ButtonGroup,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem
} from "reactstrap";
import { NavLink as NavLinkStrap } from "reactstrap";

class DateAsButton extends PureComponent {
  render() {
    return (
      <Button
        className="btn btn-lg btn-second btn-block"
        onClick={this.props.onClick}
      >
        <span className="btn-wrapper--label">{this.props.value}</span>
      </Button>
    );
  }
}

class DateRangeFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterOpen: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChangeFilter = event => {
    const filter = event.target.innerText;
    const {
      onChange,
      filters,
      field,
      form: { setFieldValue }
    } = this.props;
    const currentFilter = filters.find(f => f.type === filter);
    this.setState({
      filterOpen: false,
      type: filter,
      name: filter,
      fromDate: currentFilter.fromDate.toDate(),
      toDate: currentFilter.toDate.toDate()
    });

    setFieldValue(field.name, {
      type: filter,
      name: filter,
      fromDate: currentFilter.fromDate.toDate(),
      toDate: currentFilter.toDate.toDate()
    });
    if (onChange) {
      onChange({
        type: filter,
        name: filter,
        fromDate: currentFilter.fromDate.toDate(),
        toDate: currentFilter.toDate.toDate()
      });
    }
  };

  toggleFilter = () => {
    this.setState({ filterOpen: !this.state.filterOpen });
  };

  handleChangeFromDate = fromDate => this.handleChange({ fromDate });

  handleChangetoDate = toDate => this.handleChange({ toDate });

  handleChange({ fromDate, toDate }) {
    const {
      onChange,
      field,
      form: { setFieldValue, getFieldMeta }
    } = this.props;

    let currentFilter = getFieldMeta(field.name).value;
    fromDate = moment(fromDate || currentFilter.fromDate);
    toDate = moment(toDate || currentFilter.toDate);
    if (fromDate.isAfter(toDate)) {
      toDate = fromDate;
    }

    setFieldValue(field.name, {
      type: "Custom",
      name: "Custom",
      fromDate: fromDate.toDate(),
      toDate: toDate.toDate()
    });

    if (onChange) {
      onChange({
        type: "Custom",
        name: "Custom",
        fromDate: fromDate.toDate(),
        toDate: toDate.toDate()
      });
    }
  }

  render() {
    const {
      field,
      form: { getFieldMeta },
      filters,
      className,
      isDropdown,
      size
    } = this.props;

    let currentFilter = getFieldMeta(field.name).value;
    let { name, fromDate, toDate } = currentFilter;
    if (moment.isMoment(fromDate)) fromDate = fromDate.toDate();
    if (moment.isMoment(toDate)) toDate = toDate.toDate();

    if (isDropdown)
      return (
        <InputGroupButtonDropdown
          addonType="append"
          isOpen={this.state.filterOpen}
          toggle={this.toggleFilter}
          className={className || "px-4"}
        >
          <DropdownToggle color="outline-primary" className="btn-pill" caret>
            {name}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-xl dropdown-menu">
            <Nav className="nav-pills flex-column p-3">
              {filters.map((filter, i) => {
                return (
                  <NavItem key={i}>
                    <NavLinkStrap
                      onClick={this.handleChangeFilter}
                      active={name === filter.type}
                    >
                      <span> {filter.type}</span>
                    </NavLinkStrap>
                  </NavItem>
                );
              })}
              <li className="dropdown-divider" />
              <NavItem className="nav-item--header">
                <span className="text-second px-3 mb-2 d-block font-weight-bold">
                  Custom
                </span>
              </NavItem>
              <div className="card-footer d-flex justify-content-between">
                <DatePicker
                  className="form-control"
                  selected={fromDate}
                  selectsStart
                  maxDate={toDate}
                  onChange={this.handleChangeFromDate}
                  dropDownMode="select"
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport"
                    }
                  }}
                />
                <div>&nbsp;&nbsp;</div>
                <DatePicker
                  className="form-control"
                  selected={toDate}
                  selectsEnd
                  minDate={fromDate}
                  onChange={this.handleChangetoDate}
                  dropDownMode="select"
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport"
                    }
                  }}
                />
              </div>
            </Nav>
          </DropdownMenu>
        </InputGroupButtonDropdown>
      );
    else
      return (
        <ButtonGroup size={size} className="btn-group">
          <InputGroupButtonDropdown
            addonType="append"
            isOpen={this.state.filterOpen}
            toggle={this.toggleFilter}
            className="px-4"
          >
            <DropdownToggle color="outline-primary" caret>
              {name}
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu dropdown-menu">
              {filters.map((filter, i) => {
                return (
                  <DropdownItem
                    className="custom-dropdown-item"
                    onClick={this.handleChangeFilter}
                    key={i}
                  >
                    {filter.type}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </InputGroupButtonDropdown>
          <DatePicker
            selected={fromDate}
            selectsStart
            fromDate={fromDate}
            toDate={toDate}
            onChange={this.handleChangeFromDate}
            dropDownMode="select"
            customInput={<DateAsButton />}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: "viewport"
              }
            }}
          />
          <div className="d-flex justify-content-center align-items-center px-2">
            to
          </div>
          <DatePicker
            selected={toDate}
            selectsEnd
            fromDate={fromDate}
            toDate={toDate}
            onChange={this.handleChangetoDate}
            dropDownMode="select"
            customInput={<DateAsButton />}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: "viewport"
              }
            }}
          />
        </ButtonGroup>
      );
  }
}

DateRangeFilter.propTypes = {
  filters: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  isDropdown: PropTypes.bool,
  size: PropTypes.string
};

DateRangeFilter.defaultProps = {
  filters: dateFilters,
  isDropdown: true,
  size: "lg"
};

export default DateRangeFilter;
