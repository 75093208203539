import moment from "moment";

const categoryNameOptions = [
  {
    label: "Admin & Other",
    value: "Admin Other"
  },
  {
    label: "Legal & Professional",
    value: "Legal & Professional"
  },
  {
    label: "Management Fees",
    value: "Management Fees"
  },
  {
    label: "Repairs & Maintenance",
    value: "Repairs & Maintenance"
  },
  {
    label: "Utilities",
    value: "Utilities"
  }
];

const categoryOptions = [
  {
    label: "Monthly Income",
    options: [
      { label: "Rent", value: "rent", type: "income" },
      { label: "Vacation Rental", value: "vacation_rental", type: "income" },
      { label: "Corporate Rental", value: "corporate_rental", type: "income" },
      { label: "Laundry", value: "laundry", type: "income" },
      { label: "Storage", value: "storage", type: "income" },
      { label: "Parking", value: "parking", type: "income" },
      { label: "Pet Fee", value: "pet_fee", type: "income" }
    ]
  },
  {
    label: "Other Income",
    options: [
      { label: "Deposit", value: "deposit", type: "income" },
      { label: "Pet Deposit", value: "pet_deposit", type: "income" },
      { label: "Fee (Application)", value: "application_fee", type: "income" },
      { label: "Fee (Other)", value: "other_fee", type: "income" },
      {
        label: "Investment Payoff",
        value: "investment_payoff",
        type: "income"
      },

      {
        label: "Principal Paydown",
        value: "principal_paydown",
        type: "income"
      }
    ]
  },
  {
    label: "Monthly Expenses",
    options: [
      {
        label: "Cleaning/Maintenance",
        value: "cleaning_maintenance",
        type: "expense"
      },
      {
        label: "Yard/Snow removal",
        value: "yard_snow_removal",
        type: "expense"
      },
      { label: "Garbage", value: "garbage", type: "expense" },
      { label: "Gas", value: "gas", type: "expense" },
      { label: "Electricity", value: "electricity", type: "expense" },
      { label: "Water/Sewer", value: "water_sewer", type: "expense" },
      { label: "HOA Dues", value: "hoa_fee", type: "expense" },
      { label: "Internet/Cable", value: "internet_cable", type: "expense" },
      { label: "Supplies", value: "supplies", type: "expense" },

      { label: "Landscaping", value: "landscaping", type: "expense" }
    ]
  },
  {
    label: "Operating Expenses",
    options: [
      { label: "Advertising", value: "advertising", type: "expense" },
      {
        label: "Technology Licenses",
        value: "technology_licenses",
        type: "expense"
      },
      { label: "Management Fees", value: "management_fee", type: "expense" },
      { label: "Auto Travel", value: "auto_travel", type: "expense" },
      { label: "Telephone", value: "telephone", type: "expense" },
      {
        label: "Professional Services",
        value: "professional_services",
        type: "expense"
      },
      { label: "Office Supplies", value: "offfice_supplies", type: "expense" },
      {
        label: "Wages and Salaries",
        value: "wages_and_salaries",
        type: "expense"
      },
      { label: "Insurance", value: "insurance", type: "expense" },
      { label: "Taxes", value: "taxes", type: "expense" },
      { label: "Licenses", value: "licenses", type: "expense" },
      { label: "Inspections", value: "inspections", type: "expense" }
    ]
  },
  {
    label: "Other Expenses",
    options: [
      {
        label: "Real Estate Taxes",
        value: "real_estate_taxes",
        type: "expense"
      },
      { label: "Other taxes", value: "other_taxes", type: "expense" },
      {
        label: "Mortgage Interest",
        value: "mortgage_interest",
        type: "expense"
      },
      {
        label: "Mortgage",
        value: "mortgage",
        type: "expense"
      },
      { label: "Other Interest", value: "other_interest", type: "expense" },
      { label: "Pest Control", value: "pest_control", type: "expense" },
      {
        label: "Equipment Purchased",
        value: "equipment_purchased",
        type: "expense"
      },
      { label: "Photography", value: "photography", type: "expense" },
      { label: "Alarm System", value: "alarm_system", type: "expense" },
      { label: "Misc/Other", value: "misc_other", type: "expense" },
      { label: "Membership fees", value: "membership_fees", type: "expense" },
      { label: "Gifts", value: "gifts", type: "expense" }
    ]
  },
  {
    label: "Repairs",
    options: [
      { label: "Painting", value: "painting", type: "expense" },
      { label: "Plumbing", value: "plumbing", type: "expense" },
      { label: "Electrical", value: "electrical", type: "expense" },
      { label: "Appliances", value: "appliances", type: "expense" },
      { label: "Roof", value: "roof", type: "expense" },
      { label: "Doors", value: "doors", type: "expense" },
      { label: "Windows", value: "windows", type: "expense" },
      { label: "Flooring", value: "flooring", type: "expense" }
    ]
  }
];

const dateFilters = [
  {
    type: "This Year",
    name: "This Year",
    _id: "this_year",
    fromDate: moment().startOf("year"),
    toDate: moment().endOf("year")
  },
  {
    type: "This Month",
    name: "This Month",
    _id: "this_month",
    fromDate: moment().startOf("month"),
    toDate: moment().endOf("month")
  },
  {
    type: "Last Month",
    name: "Last Month",
    _id: "last_month",
    fromDate: moment()
      .subtract(1, "month")
      .startOf("month"),
    toDate: moment()
      .subtract(1, "month")
      .endOf("month")
  },
  {
    type: "Last Year",
    name: "Last Year",
    _id: "last_year",
    fromDate: moment()
      .subtract(1, "year")
      .startOf("year"),
    toDate: moment()
      .subtract(1, "year")
      .endOf("year")
  },
  {
    type: "Last 6 Months",
    name: "Last 6 Months",
    _id: "last_6_months",
    fromDate: moment()
      .subtract(6, "month")
      .startOf("month"),
    toDate: moment().endOf("month")
  },
  {
    type: "Last 12 Months",
    name: "Last 12 Months",
    _id: "last_12_months",
    fromDate: moment()
      .subtract(12, "month")
      .startOf("month"),
    toDate: moment().endOf("month")
  }
];

const reportFilters = [
  {
    type: "Last Year",
    name: "Last Year",
    _id: "last_year",
    fromDate: moment()
      .subtract(1, "year")
      .startOf("year"),
    toDate: moment()
      .subtract(1, "year")
      .endOf("year")
  },
  {
    type: "This Year",
    name: "This Year",
    _id: "this_year",
    fromDate: moment().startOf("year"),
    toDate: moment().endOf("year")
  },
  {
    type: "Last 6 Months",
    name: "Last 6 Months",
    _id: "last_6_months",
    fromDate: moment()
      .subtract(6, "month")
      .startOf("month"),
    toDate: moment().endOf("month")
  },
  {
    type: "Last 12 Months",
    name: "Last 12 Months",
    _id: "last_12_months",
    fromDate: moment()
      .subtract(12, "month")
      .startOf("month"),
    toDate: moment().endOf("month")
  }
];

const getCategoryValueFromLabel = categoryLabel => {
  let matchedCategory = categoryOptions
    .flatMap(a => a.options)
    .find(a => a.label.toLowerCase() === categoryLabel.toLowerCase());
  return matchedCategory ? matchedCategory.value : categoryLabel.toLowerCase();
};

const getChecklistCategories = assetType => {
  switch (assetType) {
    case "primary":
      return [
        { label: "Insurance", value: "Insurance" },
        { label: "Mortgage", value: "Mortgage" },
        { label: "1099", value: "1099" },
        { label: "Title", value: "Title" }
      ];

    case "rental":
      return [
        { label: "Lease", value: "Lease" },
        { label: "Insurance", value: "Insurance" },
        { label: "Mortgage", value: "Mortgage" },
        { label: "1099", value: "1099" },
        { label: "Renters_Insurance", value: "Renters_Insurance" },
        { label: "Title", value: "Title" }
      ];
    case "note":
      return [
        { label: "Note", value: "Note" },
        { label: "Mortgage_Deed", value: "Mortgage_Deed" },
        { label: "Insurance", value: "Insurance" },
        { label: "1099", value: "1099" }
      ];
    case "syndication":
      return [
        { label: "LLC", value: "LLC" },
        { label: "Schedule-K", value: "Schedule-K" }
      ];
    default:
      return [
        { label: "Insurance", value: "Insurance" },
        { label: "Mortgage", value: "Mortgage" },
        { label: "1099", value: "1099" },
        { label: "Title", value: "Title" }
      ];
  }
};
// Owner, Investor, Insurance Agent, Tenant, Real Estate Agent,
// Financial Advisor, Contractor, Banker, Unknown, Property Manager
// enum: ["O", "I", "IA", "T", "R", "F", "C", "B", "U", "IN", "PM"],
const getUserRole = role => {
  switch (role) {
    case "O":
      return "Owner";
    case "I":
      return "Investor";
    case "IA":
      return "Insurance Agent";
    case "R":
      return "Real Estate Agent";
    case "T":
      return "Tenant";
    case "C":
      return "Contractor";
    case "PM":
      return "Property Manager";
    default:
      return "Other";
  }
};

export {
  categoryNameOptions,
  dateFilters,
  categoryOptions,
  reportFilters,
  getCategoryValueFromLabel,
  getChecklistCategories,
  getUserRole
};
