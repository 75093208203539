/* eslint-disable no-param-reassign */
import React, { PureComponent } from "react";
import EntitySelect from "../form/EntitySelect";
import DropdownField from "../form/DropdownField";

import DateRangeFilter from "../DateRangeFilter";
import SlidingPane from "react-sliding-pane";

import {
  saveFilter,
  getCurrentFilter,
  setCurrentFilter
} from "../../../actions/auth/authAction";

import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { Button, FormGroup, ModalHeader, ModalBody } from "reactstrap";
import { Filter, XCircle } from "react-feather";

class DashFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterOpen: false
    };
  }

  handleEntityChange = value => {
    let { currentFilter } = this.props;
    currentFilter.entity = value;
    this.handleChangeFilter(currentFilter);
  };

  handleDateChange = filter => {
    let { currentFilter } = this.props;
    currentFilter.fromDate = filter.fromDate;
    currentFilter.toDate = filter.toDate;
    currentFilter.name = filter.type;
    this.handleChangeFilter(currentFilter);
  };

  handleInvestmentStatusChange = value => {
    let { currentFilter } = this.props;
    currentFilter.status = value;
    this.handleChangeFilter(currentFilter);
  };

  handleChangeFilter = values => {
    this.setState({ filterOpen: false }, () => {
      this.props.setCurrentFilter(values);
      this.props.onChange(values);
    });
  };

  render() {
    const { currentFilter } = this.props;
    let initialValues = {
      filter: currentFilter,
      entity: currentFilter.entity || "All",
      status: currentFilter.status || "active"
    };

    return (
      <div className="d-flex align-items-center">
        <Button
          color="neutral-primary"
          className="btn-icon btn-animated-icon btn-transition-none btn-pill"
          onClick={() => this.setState({ filterOpen: true })}
        >
          <span className="btn-wrapper--icon">
            <Filter />
          </span>
          <span className="btn-wrapper--label">Filters</span>
        </Button>
        <SlidingPane
          isOpen={this.state.filterOpen}
          closeIcon={<XCircle />}
          from="right"
          width="350px"
          hideHeader={true}
          className="d-flex justify-content-center modal-content"
          onRequestClose={() => this.setState({ filterOpen: false })}
        >
          <ModalHeader toggle={() => this.setState({ filterOpen: false })}>
            Apply Filters
          </ModalHeader>
          <ModalBody>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={this.handleChangeFilter}
            >
              {props => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <FormGroup>
                      <label>Entity:</label>
                      <Field
                        name="entity"
                        component={EntitySelect}
                        className=""
                        readOnly={true}
                        allOption={true}
                        onChange={this.handleEntityChange}
                        renderAsPill={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Status:</label>
                      <Field
                        name="status"
                        component={DropdownField}
                        options={[
                          { value: "active", label: "Active" },
                          { value: "for_sale", label: "For Sale" },
                          { value: "in_default", label: "In Default" },
                          { value: "in_forclosure", label: "In Foreclosure" },
                          { value: "closed", label: "Closed" }
                        ]}
                        placeholder="Investment Status"
                        classNamePrefix={"entity-select"}
                        defaultValue={"active"}
                        onChange={this.handleInvestmentStatusChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Period:</label>
                      <Field
                        name="filter"
                        component={DateRangeFilter}
                        onChange={this.handleDateChange}
                      />
                    </FormGroup>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </SlidingPane>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userDetail: state.userDetail,
  dashboardFilter: state.dashboardFilter
});

const mapDispatchToProps = dispatch => {
  return {
    saveFilter: data => saveFilter(data, dispatch),
    getCurrentFilter: data => getCurrentFilter(data, dispatch),
    setCurrentFilter: data => setCurrentFilter(data, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashFilter);
