import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";

const ContactAction = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        size="sm"
        color="neutral-first"
        className=" px-2 py-0 no-caret"
      >
        <FontAwesomeIcon icon={faEllipsisH} className="font-size-lg" />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-lg overflow-hidden p-0">
        <Nav pills className="nav-neutral-first flex-column pt-2 pb-3">
          <NavItem>
            <NavLink
              href="#/"
              onClick={() => {
                toggle();
                props.onSelect("edit");
              }}
            >
              <div className="nav-link-icon">
                <FontAwesomeIcon icon={faEdit} />
              </div>
              <span>Edit</span>
            </NavLink>
          </NavItem>
          {props.isUser && (
            <NavItem>
              <NavLink
                href="#/"
                onClick={() => {
                  toggle();
                  props.onSelect("message");
                }}
              >
                <div className="nav-link-icon">
                  <FontAwesomeIcon icon={faComments} />
                </div>
                <span>Message</span>
              </NavLink>
            </NavItem>
          )}
          <li className="dropdown-divider" />
          <NavItem>
            <NavLink
              href="#/"
              onClick={() => {
                toggle();
                props.onSelect("delete");
              }}
              className="text-danger"
            >
              <div className="nav-link-icon">
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <span>Delete</span>
            </NavLink>
          </NavItem>
        </Nav>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ContactAction;
