import React, { Fragment } from "react";
import {
  Table,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import { connect } from "react-redux";
import { isEmpty, get, isEqual } from "lodash";
import PlaidUpdateLinkButton from "./PlaidUpdateLinkButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import BankTransactions from "./BankTransactions";
import ConfirmModal from "../../shared/components/ConfirmModal";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import { getTransactionsFromBank } from "../../actions/transaction";
import { getTxnFilterPattern } from "../../helpers";

class BankAccountTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountToFetch: null,
      accountToDelete: null,
      fetchingBankData: false,
      fetching: false,
      showDeleteModal: false,
      daysToFetch: 7,
      transactions: []
    };
  }

  fetchAccount = (accountId, daysToFetch) => {
    this.setState({ accountToFetch: accountId, fetchingBankData: true }, () => {
      this.props.getTransactionsFromBank({
        account_id: accountId,
        days_to_fetch: daysToFetch
      });
    });
  };

  deleteAccount = accountId => {
    this.setState({ accountToDelete: accountId, showDeleteModal: true });
  };

  onDeleteCancel = () => {
    this.setState({ accountToDelete: null, showDeleteModal: false });
  };

  onDeleteSubmit = () => {
    this.setState({ showDeleteModal: false }, () => {
      this.props.onDeleteAccount(this.state.accountToDelete);
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      transactions: { bankTransactions }
    } = nextProps;

    if (prevState.fetchingBankData) {
      return {
        fetchingBankData: false,
        fetching: true,
        transactions: []
      };
    }

    if (prevState.fetching && !isEmpty(bankTransactions)) {
      if (!isFetching(bankTransactions)) {
        if (isSuccess(bankTransactions)) {
          const {
            data: { transactions, importedTransactions, filterPatterns }
          } = bankTransactions;

          // lets find out all the imported transactions
          if (importedTransactions)
            transactions.reduce(
              (r, txn) => (
                // eslint-disable-next-line
                (txn["imported"] = importedTransactions.includes(txn.txnId)),
                r.push(txn),
                r
              ),
              []
            );

          if (filterPatterns)
            transactions.reduce(
              (r, txn) => (
                (txn["hasFilter"] =
                  filterPatterns.findIndex(
                    pattern => isEqual(pattern, getTxnFilterPattern(txn))
                    // eslint-disable-next-line
                  ) >= 0),
                r.push(txn),
                r
              ),
              []
            );
          return {
            transactions: bankTransactions.data.transactions,
            importedTransactions: bankTransactions.data.importedTransactions,
            filterPatterns: bankTransactions.data.filterPatterns,
            fetching: false
          };
        } else {
          return {
            transactions: [],
            importedTransactions: [],
            filterPatterns: [],
            error: bankTransactions.error,
            fetching: false
          };
        }
      }
    }
    return null;
  }
  render() {
    const { accounts, accountLinkTokens, onUpdateAccount } = this.props;
    return (
      <div className="bordered_table">
        <div className="table-responsive-md">
          <Table className="mb-0">
            <thead>
              <tr>
                <th style={{ width: "400px" }} scope="col">
                  Bank Name
                </th>
                <th scope="col">Linked on</th>
                <th scope="col">Last Fetch</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {accounts &&
                accounts.map((account, accountIdx) => {
                  let accountLinkToken = get(accountLinkTokens, account._id);
                  return (
                    <Fragment key={account._id}>
                      <tr>
                        <td>
                          <b>{account.institutionName}</b>
                        </td>
                        <td>
                          <span>{account.createdAt}</span>
                        </td>
                        <td>
                          <span>{account.lastFetched}</span>
                        </td>
                        <td className="text-right">
                          {accountLinkToken ? (
                            <PlaidUpdateLinkButton
                              linkToken={accountLinkToken}
                              key={accountIdx}
                              onUpdate={onUpdateAccount}
                            />
                          ) : (
                            <UncontrolledDropdown tag="span" className="m-2">
                              <DropdownToggle color="first" caret>
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                    className="font-size-sm"
                                  />
                                </span>
                                <span className="btn-wrapper--label">
                                  Fetch
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                <div role="menuitem">
                                  <a
                                    className="dropdown-item"
                                    href="#/"
                                    onClick={() =>
                                      this.fetchAccount(account._id, 7)
                                    }
                                  >
                                    Last 7 days
                                  </a>
                                </div>
                                <div role="menuitem">
                                  <a
                                    className="dropdown-item"
                                    href="#/"
                                    onClick={() =>
                                      this.fetchAccount(account._id, 14)
                                    }
                                  >
                                    Last 14 days
                                  </a>
                                </div>
                                <div role="menuitem">
                                  <a
                                    className="dropdown-item"
                                    href="#/"
                                    onClick={() =>
                                      this.fetchAccount(account._id, 30)
                                    }
                                  >
                                    Last 30 days
                                  </a>
                                </div>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                          <Button
                            color="neutral-danger"
                            onClick={() => this.deleteAccount(account._id)}
                            className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="font-size-sm"
                            />
                          </Button>
                        </td>
                      </tr>
                      {!this.state.fetching &&
                        this.state.accountToFetch === account._id && (
                          <tr>
                            <td colSpan="5">
                              <BankTransactions
                                parsedTransactions={this.state.transactions}
                                assets={this.props.assets}
                                accountId={this.state.accountToFetch}
                              />
                            </td>
                          </tr>
                        )}
                      <tr className="divider" />
                      <ConfirmModal
                        title={"Are you sure to delete this account?"}
                        subtitle={"You cannot undo this operation."}
                        type="danger"
                        modalOpen={this.state.showDeleteModal}
                        onCancel={this.onDeleteCancel}
                        onSubmit={this.onDeleteSubmit}
                        error={this.state.error}
                        loading={this.state.loading}
                        btnText={"Delete"}
                        btnTextOnLoading={"Deleting"}
                      />
                    </Fragment>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  transactions: state.transactions
});

const mapDispatchToProps = dispatch => {
  return {
    getTransactionsFromBank: data => getTransactionsFromBank(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankAccountTable);
