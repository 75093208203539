import React, { useCallback } from "react";
import { usePlaidLink } from "react-plaid-link";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const PlaidLinkButton = props => {
  const onSuccess = useCallback((token, metadata) => {
    const plaidData = {
      public_token: token,
      metadata: metadata
    };
    props.onSuccess(plaidData);
  }, []);

  const config = {
    token: props.linkToken,
    onSuccess
  };

  const { open, ready } = usePlaidLink(config);

  let btnLabel = props.updateLink ? "Update" : "Add Bank";
  let btnClass = props.updateLink ? "first" : "primary";

  return (
    <Button color={btnClass} onClick={() => open()} disabled={!ready}>
      <span className="btn-wrapper--icon">
        <FontAwesomeIcon icon={faLink} />
      </span>
      <span className="btn-wrapper--label">{btnLabel}</span>
    </Button>
  );
};

export default PlaidLinkButton;
