import React, { Component } from "react";
import classnames from "classnames";
import ExpenseFinancial from "../FinancialWorksheet/ExpenseFinancial";
import IncomeFinancial from "../FinancialWorksheet/IncomeFinancial";
import NoteInfo from "./NoteInfo";
import SyndicateInfo from "./SyndicateInfo";
import FundListing from "./FundListing";

import FlipInfo from "./FlipInfo";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import showToast from "../../../helpers/toastNotification";

class Financials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
      changesInTab: false,
      dataModified: false,
      assetSlug: props.match.params.asset
    };
  }
  componentDidUpdate = (prevProps, prevState) => {
    const search = this.props.location.search;
    let assetType;
    if (this.props.asset) {
      assetType = this.props.asset.assetType;
    }

    if (search) {
      const query = search.split("=")[1];
      if (
        query === "income" &&
        (assetType === "rental" || assetType === "note")
      )
        this.toggle("3");
      else if (
        query === "expense" &&
        (assetType === "rental" || assetType === "primary")
      )
        this.toggle("4");
      else if (query === "note" && assetType === "note") this.toggle("5");
      else if (query === "flip" && assetType === "flip") this.toggle("6");
      else if (query === "syndication" && assetType === "syndication")
        this.toggle("7");
      else if (query === "fund" && assetType === "fund") this.toggle("8");
      else {
        this.toggle("3");
      }
    } else {
      if (this.state.activeTab !== 5 && assetType === "note") {
        this.toggle("5");
      } else if (this.state.activeTab !== 3 && assetType === "rental") {
        this.toggle("3");
      } else if (this.state.activeTab !== 4 && assetType === "primary") {
        this.toggle("4");
      } else if (this.state.activeTab !== 6 && assetType === "flip") {
        this.toggle("6");
      } else if (this.state.activeTab !== 7 && assetType === "syndication") {
        this.toggle("7");
      } else if (this.state.activeTab !== 8 && assetType === "fund") {
        this.toggle("8");
      }
    }
  };

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  switchTab = tab => {
    if (this.state.dataModified) {
      showToast("e", "Asset Detail Modified, Please Save before proceeding");
    } else {
      this.props.history.push(`?tabs=${tab}`);
      this.changesInDataSaved();
    }
  };

  changesInWorkSheet = () => {
    this.setState(prevState => ({ changesInTab: !prevState.changesInTab }));
  };

  changesInData = () => {
    this.setState(prevState => ({ dataModified: true }));
  };
  changesInDataSaved = () => {
    this.setState(prevState => ({ dataModified: false }));
  };

  render() {
    const { activeTab } = this.state;
    const { asset } = this.props;
    let assetType = "";
    let user_role = "O";
    if (asset) {
      assetType = asset.assetType;
      user_role = asset.user_role;
    }

    return (
      <div className="px-4">
        <div className="worksheet_content">
          <div className="tabs-animated tabs-animated-line">
            <Nav tabs className={"worksheet_tabs"}>
              {assetType === "note" && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => this.switchTab("note")}
                  >
                    Note Details
                  </NavLink>
                </NavItem>
              )}
              {assetType === "syndication" && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "7" })}
                    onClick={() => this.switchTab("syndication")}
                  >
                    Syndicate Details
                  </NavLink>
                </NavItem>
              )}
              {assetType === "flip" && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "6" })}
                    onClick={() => this.switchTab("flip")}
                  >
                    Flip Details
                  </NavLink>
                </NavItem>
              )}
              {assetType === "rental" && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => this.switchTab("income")}
                  >
                    Income
                  </NavLink>
                </NavItem>
              )}
              {(assetType === "rental" || assetType === "primary") && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => this.switchTab("expense")}
                  >
                    Expense
                  </NavLink>
                </NavItem>
              )}
              {assetType === "fund" && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "8" })}
                    onClick={() => this.switchTab("fund")}
                  >
                    Fund Details
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={activeTab} className={"border-top"}>
              {/* Show Incomes only for Rental Type */}
              {user_role === "O" && assetType === "rental" && (
                <TabPane tabId="3">
                  <IncomeFinancial {...this.props} />
                </TabPane>
              )}
              {/* Show Expenses only for Rental and Primary Type */}
              {user_role === "O" &&
                (assetType === "rental" || assetType === "primary") && (
                  <TabPane tabId="4">
                    <ExpenseFinancial {...this.props} />
                  </TabPane>
                )}
              {/* Show Notes only for Note Type */}
              {assetType === "note" && (
                <TabPane tabId="5">
                  <NoteInfo
                    assetSlug={this.state.assetSlug}
                    refreshData={this.props.refreshData}
                  />
                </TabPane>
              )}
              {/* Show Notes only for Flip Type */}
              {assetType === "flip" && (
                <TabPane tabId="6">
                  <FlipInfo assetSlug={this.state.assetSlug} />
                </TabPane>
              )}
              {/* Show Notes only for syndication Type */}
              {assetType === "syndication" && (
                <TabPane tabId="7">
                  <SyndicateInfo assetSlug={this.state.assetSlug} />
                </TabPane>
              )}
              {/* Show Fund */}
              {assetType === "fund" && (
                <TabPane tabId="8">
                  <FundListing assetSlug={this.state.assetSlug} />
                </TabPane>
              )}
            </TabContent>
          </div>
        </div>
      </div>
    );
  }
}
export default Financials;
