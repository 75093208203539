import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Formik, Field } from "formik";
import Options from "react-ionicons/lib/Options";
import Calculator from "react-ionicons/lib/Calculator";

import ToggleSwitch from "../../../shared/components/form/ToggleSwitch";
import MaskedField from "../../../shared/components/form/MaskedField";
import DatePickerField from "../../../shared/components/form/DatePicker";
import MaskedInput from "react-text-mask";
import Alert from "../../../shared/components/Alert";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {
  calculatePayment,
  calculateMortgage
} from "../../../helpers/assetCalculator";
import { unmaskCurrency } from "../../../helpers";
import { PPMT, IPMT } from "formulajs";
import ReactCardFlip from "react-card-flip";

import {
  updateAssetFinancial,
  addFinancialToAsset
} from "../../../actions/financial";
import { connect } from "react-redux";

const loanInfoMetadata = [
  {
    label: "loan_amount",
    name: "Loan Amount",
    addonText: "$",
    addonType: "prepend",
    type: "currency"
  },
  {
    label: "interest_rate",
    name: "Interest Rate",
    addonText: "%",
    addonType: "prepend",
    type: "percent"
  },
  {
    label: "term",
    name: "Loan Term",
    addonText: "Years",
    addonType: "append",
    type: "Years"
  },
  {
    label: "origination_date",
    name: "Origination Date",
    addonText: "Years",
    addonType: "append",
    type: "date"
  }
];
const categoryToMetadataMap = {
  DISTRIBUTION: [
    {
      label: "investment_amount",
      name: "Investment Amount",
      addonText: "$",
      addonType: "prepend",
      type: "currency"
    },
    {
      label: "annual_coc",
      name: "Annual Rate (Projected)",
      addonText: "%",
      addonType: "prepend",
      type: "percent"
    },
    {
      label: "annualized_irr",
      name: "Annualized Return (Projected)",
      addonText: "%",
      addonType: "prepend",
      type: "percent"
    }
  ],
  INTEREST: [
    {
      label: "investment_amount",
      name: "Investment Amount",
      addonText: "$",
      addonType: "prepend",
      type: "currency"
    },
    {
      label: "interest",
      name: "Interest Rate",
      addonText: "%",
      addonType: "prepend",
      type: "percent"
    },
    {
      label: "points",
      name: "Points",
      addonText: "%",
      addonType: "prepend",
      type: "percent"
    }

    /*,
    {
      label: "amortized",
      name: "Amortized Interest",
      type: "toggle"
    }*/
  ],
  MORTGAGE: [
    {
      label: "principal",
      name: "Principal",
      addonText: "$",
      addonType: "prepend",
      type: "currency"
    },
    {
      label: "interest",
      name: "Interest",
      addonText: "$",
      addonType: "prepend",
      type: "currency"
    },
    {
      label: "escrow",
      name: "Escrow Amount (Taxes & Insurance)",
      addonText: "$",
      addonType: "prepend",
      type: "currency"
    },
    {
      label: "pmi",
      name: "Private Mortgage Insurance (PMI)",
      addonText: "$",
      addonType: "prepend",
      type: "currency"
    }
  ]
};

const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: true
});

class FinancialMetaData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updating: false,
      error: null,
      fetchUrl: false,
      ext: null,
      showModal: false,
      frequency: null,
      frequencyChanged: false,
      showLoanInfo: false
    };
  }

  toggleLoanInfo = formValues => {
    // if the current state is show loan info and then when toggle
    // lets recalcuate the principal and interest..
    if (formValues) {
      const { loan_amount, interest_rate, term } = formValues;
      if (loan_amount && interest_rate && term) {
        formValues.principal =
          -PPMT(
            unmaskCurrency(interest_rate) / 1200,
            1,
            12 * unmaskCurrency(term),
            unmaskCurrency(loan_amount)
          ) || 0;

        formValues.interest =
          -IPMT(
            unmaskCurrency(interest_rate) / 1200,
            1,
            12 * unmaskCurrency(term),
            unmaskCurrency(loan_amount)
          ) || 0;
      }
    }
    this.setState(prevState => ({ showLoanInfo: !prevState.showLoanInfo }));
  };

  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };
  onError = () => {
    this.setState({
      error: "An error occured while loading this image. Try again"
    });
  };

  updateMetadata = values => {
    const {
      updateAssetFinancial,
      addFinancialToAsset,
      financial,
      assetSlug
    } = this.props;

    let newData = financial;
    newData.meta = values;
    newData.slug = assetSlug;

    if (financial.category === "MORTGAGE") {
      newData.amount = calculateMortgage(values);
    } else {
      newData.amount = calculatePayment(
        values,
        financial.category,
        financial.frequency
      );
    }

    if (financial.placeholder) {
      this.setState({ updating: true }, () => {
        addFinancialToAsset(newData);
      });
    } else {
      this.setState({ updating: true }, () => {
        updateAssetFinancial(newData);
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { updating } = prevState;
    const { updatedFinancial } = nextProps;
    if (updating) {
      if (
        updatedFinancial.updateFinancialSuccess === true ||
        updatedFinancial.addingFinancialSuccess === true
      ) {
        return {
          updating: false,
          showModal: false,
          frequencyChanged: false
        };
      } else if (
        updatedFinancial.updateFinancialSuccess === false &&
        updatedFinancial.addingFinancialSuccess === false
      ) {
        return {
          updating: false,
          frequencyChanged: false,
          error: updatedFinancial.error
        };
      }
    }
    return null;
  }

  getInitialValues = (metadatas, financial) => {
    let initialValues = metadatas.reduce((result, metadata) => {
      if (metadata.type === "toggle") result[metadata.label] = false;
      else result[metadata.label] = 0;

      return result;
    }, {});
    if (financial.meta && Object.keys(financial.meta).length > 0) {
      initialValues = Object.assign(initialValues, financial.meta);
    }
    return initialValues;
  };

  renderMortgageMetadata = (financial, formValues) => {
    return (
      <ReactCardFlip isFlipped={this.state.showLoanInfo}>
        <div>
          {loanInfoMetadata.map((metadata, idx) => {
            return (
              <div
                className="form-group inputblock"
                key={`metadata-key-${idx}`}
              >
                <label htmlFor="exampleInputEmail1">{metadata.name}</label>
                {metadata.type === "toggle" && (
                  <Field name={metadata.label} component={ToggleSwitch} />
                )}
                {metadata.type !== "toggle" && metadata.type !== "date" && (
                  <Field
                    name={metadata.label}
                    component={MaskedField}
                    addonSize={"lg"}
                    maskType={metadata.type}
                    addonType={metadata.addonType}
                    addonText={metadata.addonText}
                  />
                )}
                {metadata.type === "date" && (
                  <Field
                    name={metadata.label}
                    component={DatePickerField}
                    addonSize={"lg"}
                  />
                )}
              </div>
            );
          })}
          <div className="d-flex justify-content-end">
            <Button
              color={"first"}
              onClick={() => this.toggleLoanInfo(formValues)}
            >
              <span className="btn-wrapper--icon ">
                <Calculator color={"var(--light)"} />
                <span className="btn-wrapper--label">Calculate</span>
              </span>
            </Button>
          </div>
        </div>
        <div>{this.renderOtherMetadata(financial)}</div>
      </ReactCardFlip>
    );
  };

  renderOtherMetadata = financial => {
    const metadatas = categoryToMetadataMap[financial.category];
    return metadatas.map((metadata, idx) => {
      return (
        <div className="form-group inputblock" key={`metadata-key-${idx}`}>
          <label htmlFor="exampleInputEmail1">{metadata.name}</label>
          {metadata.type === "toggle" ? (
            <Field name={metadata.label} component={ToggleSwitch} />
          ) : (
            <Field
              name={metadata.label}
              component={MaskedField}
              addonSize={"lg"}
              maskType={metadata.type}
              addonType={metadata.addonType}
              addonText={metadata.addonText}
            />
          )}
        </div>
      );
    });
  };

  render() {
    const { financial } = this.props;
    const metadatas = categoryToMetadataMap[financial.category];

    if (!metadatas && !financial) {
      return <div />;
    }
    const initialValues = this.getInitialValues(metadatas, financial);

    let title;
    if (financial.category === "INTEREST") title = "Note Details";
    else if (financial.category === "DISTRIBUTION")
      title = "Syndication Details";
    else if (financial.category === "MORTGAGE") title = "Mortgage Details";
    else title = "Additional Details";

    return (
      <Fragment>
        <MaskedInput
          mask={currencyMask}
          value={financial.amount}
          type={"text"}
          onBlur={financial.category !== "MORTGAGE" && this.props.onBlur}
          onClick={this.toggleModal}
          className="form-control form-control-lg"
          placeholder={"Enter Amount"}
        />
        <Button
          color={financial.meta ? "first" : "neutral-second"}
          onClick={this.toggleModal}
        >
          <span className="btn-wrapper--icon font-size-lg">
            <Options
              color={financial.meta ? "var(--light)" : "var(--second)"}
            />
          </span>
        </Button>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={this.updateMetadata}
        >
          {props => {
            const { handleSubmit, values } = props;
            const { error, updating } = this.state;

            return (
              <Modal
                zIndex={2000}
                centered
                size="md"
                isOpen={this.state.showModal}
                toggle={this.toggleModal}
              >
                <ModalHeader toggle={this.toggleModal}>{title}</ModalHeader>
                <form onSubmit={handleSubmit}>
                  <ModalBody>
                    {error && (
                      <Alert color="danger" className="alert--bordered" icon>
                        <p>Error! {error}</p>
                      </Alert>
                    )}
                    {financial.category === "MORTGAGE"
                      ? this.renderMortgageMetadata(financial, values)
                      : this.renderOtherMetadata(financial)}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="reset"
                      className="btn btn-secondary px-5 d-inline-block mx-2"
                      onClick={this.toggleModal}
                      disabled={updating}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn savebtns btn-primary px-5  d-inline-block mx-2"
                      type="submit"
                      color="primary"
                      disabled={updating}
                    >
                      {updating ? "Updating" : "Update"}
                    </Button>
                  </ModalFooter>
                </form>
              </Modal>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  updatedFinancial: state.financial
});

const mapDispatchToProps = dispatch => ({
  updateAssetFinancial: data => updateAssetFinancial(data, dispatch),
  addFinancialToAsset: data => addFinancialToAsset(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancialMetaData);
