// import PropTypes from 'prop-types';
import React, { PureComponent } from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

import clsx from "clsx";

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true
});

class CurrencyText extends PureComponent {
  render() {
    const {
      type,
      touched,
      field,
      error,
      addonType,
      addonText,
      addonSize,
      ...otherProps
    } = this.props;

    return (
      <InputGroup size={addonSize}>
        {addonType === "prepend" && (
          <InputGroupAddon addonType={addonType}>
            <InputGroupText>{addonText}</InputGroupText>
          </InputGroupAddon>
        )}
        <MaskedInput
          {...field}
          mask={currencyMask}
          type={type}
          {...otherProps}
          className={clsx("form-control", {
            "is-invalid": touched && error
          })}
        />
        {addonType === "append" && (
          <InputGroupAddon addonType={addonType}>
            <InputGroupText>{addonText}</InputGroupText>
          </InputGroupAddon>
        )}
        <div className="invalid-feedback">{error}</div>
      </InputGroup>
    );
  }
}
export default CurrencyText;
