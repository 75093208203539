import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Field /* Form */ } from "formik";
import * as Yup from "yup";
import { unmaskCurrency } from "../../../helpers/";
import {
  getInvestmentInfo,
  updateInvestmentInfo
} from "../../../actions/asset/assetAction";
import { getAssetFinancials } from "../../../actions/financial";
import { Row, Col, Card } from "reactstrap";

import MaskedField from "../../../shared/components/form/MaskedField";
import EntitySelect from "../../../shared/components/form/EntitySelect";

import customSelect from "../../../shared/components/form/customSelect";
import Textarea from "../../../shared/components/form/Textarea";
import TextWithFrequency from "../FinancialWorksheet/TextWithFrequency";

const validationSchema = Yup.object({
  investmentStartDate: Yup.string().required(
    "Investment Start date is required"
  ),
  investmentEndDate: Yup.string().required("Investment End date is required"),
  // investmentAmount: Yup.string().max(16, "Investment Amount too large"),
  // interestRate: Yup.number().moreThan(-1, "Invalid Percentage"),
  // balance: Yup.string().max(16, 'Balance too large'),
  // yield: Yup.number().moreThan(-1,'Invalid Percentage'),
  // investmentToBalance: Yup.number().moreThan(-1,'Invalid Percentage'),
  // investmentToValue: Yup.number().positive('Invalid Percentage'),
  // loanToValue: Yup.number().moreThan(-1,'Invalid Percentage'),
  lienPosition: Yup.number()
    .typeError("Enter valid Lien position")
    .integer("Enter valid Lien position")
    .min(1, "Min 1 unit is required")
    .required("Enter Lien position"),
  // unpaidBalance: Yup.string().max(16, 'Unpaid Balance too large'),
  isPerforming: Yup.string().max(1, "Performing too large")
  // durationStart: Yup.date().typeError("Enter Valid date"),
  // durationEnd: Yup.date().typeError("Enter Valid date"),
});

class NoteInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updating: false,
      error: null,
      investmentInfo: {
        loanToValue: "",
        lienPosition: 1,
        isPerforming: "1",
        deedType: "deed_of_trust",
        investmentStatus: "active",
        notes: "",
        entity: "",
        investmentStartDate: "",
        investmentEndDate: ""
      }
    };
  }

  updateInvestmentInfo = values => {
    values.marketValue = unmaskCurrency(values.marketValue);
    values.loanToValue = unmaskCurrency(values.loanToValue);
    this.setState({ updating: true, error: null }, () => {
      values.slug = this.props.assetSlug;
      this.props.updateInvestmentInfo(values);
    });
  };

  componentDidMount() {
    const { assetSlug } = this.props;
    this.props.getInvestmentInfo(assetSlug);
    this.props.getAssetFinancials(assetSlug);
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.updating && !this.state.updating) {
      this.props.refreshData();
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading, updating } = prevState;
    const { assetInvestmentInfo } = nextProps;
    if (loading) {
      if (assetInvestmentInfo.loadingInvestmentInfoSuccess === true) {
        return {
          loading: false,
          investmentInfo: assetInvestmentInfo.investmentInfo
        };
      } else if (assetInvestmentInfo.loadingInvestmentInfoSuccess === false) {
        return {
          loading: false,
          error: assetInvestmentInfo.error
        };
      }
    } else if (updating) {
      if (assetInvestmentInfo.updateInvestmentInfoSuccess === true) {
        return {
          updating: false,
          investmentInfo: assetInvestmentInfo.investmentInfo
        };
      } else if (assetInvestmentInfo.updateInvestmentInfoSuccess === false) {
        return {
          updating: false,
          investmentInfo: assetInvestmentInfo.error
        };
      }
    }

    return null;
  }

  render() {
    const initialValues = this.state.investmentInfo;
    const { loading, updating } = this.state;

    const {
      isShare,
      assetSlug /* assetBasicInfo */,
      assetBasicInfo: { basicInfo },
      financial: { financials }
    } = this.props;

    let incomeData;
    if (
      financials &&
      financials.findIndex(f => f.category === "INTEREST") >= 0
    ) {
      const index = financials.findIndex(f => f.category === "INTEREST");
      incomeData = financials[index];
    } else {
      const currentDate = new Date();
      const defaultDueDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        5
      );
      incomeData = {
        type: "INCOME",
        category: "INTEREST",
        frequency: "Monthly",
        amount: "",
        dueDates: [defaultDueDate],
        notify: true,
        placeholder: true
      };
    }
    if (incomeData.meta && basicInfo && basicInfo.marketValue) {
      initialValues.loanToValue =
        (unmaskCurrency(incomeData.meta.investment_amount) * 100) /
        basicInfo.marketValue;
    }

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.updateInvestmentInfo}
      >
        {props => {
          const { handleSubmit, handleChange } = props;
          let values = props.values;
          if (
            unmaskCurrency(values.marketValue) > 0 &&
            (incomeData && incomeData.meta)
          ) {
            values.loanToValue =
              (unmaskCurrency(incomeData.meta.investment_amount) * 100) /
              unmaskCurrency(values.marketValue);
          }
          return (
            <div className="px-3 py-4">
              {/* {props.errors} */}
              <form onSubmit={handleSubmit}>
                {/* Notes */}
                <Row>
                  <Col xl="6">
                    <Card className="p-3 mb-5">
                      <div className="bg-composed-wrapper bg-deep-blue rounded-sm">
                        <div className="rounded-sm bg-composed-wrapper--image opacity-4" />
                        <div className="bg-composed-wrapper--bg rounded-sm bg-serious-blue opacity-7" />
                        <div className="bg-composed-wrapper--content text-center text-white p-3">
                          <h1 className="font-size-xxl font-weight-bold pt-2 pb-1 mb-0">
                            Note Info
                          </h1>
                        </div>
                      </div>
                      <div className="card-body ">
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">
                            Lien Position:
                          </label>
                          <Field
                            name="lienPosition"
                            component={customSelect}
                            placeholder="Lien Position"
                            disabled={isShare}
                            value={values.lienPosition}
                            options={[
                              { value: 1, label: "1st" },
                              { value: 2, label: "2nd" }
                            ]}
                          />
                        </div>
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">
                            Performance (PL/NPL):
                          </label>
                          <Field
                            name="isPerforming"
                            component={customSelect}
                            value={values.isPerforming}
                            placeholder="Performance"
                            disabled={isShare}
                            options={[
                              { value: "0", label: "Not Performing" },
                              { value: "1", label: "Performing" }
                            ]}
                          />
                        </div>
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">Deed Type:</label>
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            name="deedType"
                            value={values.deedType || "trust"}
                            onChange={handleChange}
                          >
                            <option value="deed_of_trust">Deed of Trust</option>
                            <option value="mortgage">Mortgage</option>
                            <option value="contract_for_deed">
                              Contract for Deed
                            </option>
                            <option value="mf_syndication">
                              Multi Family Syndication
                            </option>
                            <option value="reo_fund">REO Fund</option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">Status:</label>
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            name="investmentStatus"
                            value={values.investmentStatus || "active"}
                            onChange={handleChange}
                          >
                            <option value="active">Active</option>
                            <option value="for_sale">For Sale</option>
                            <option value="in_default">In Default</option>
                            <option value="in_forclosure">
                              In Foreclosure
                            </option>{" "}
                            <option value="inactive">In-Active</option>
                          </select>
                        </div>
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">
                            Entity Name:
                          </label>
                          <Field name="entity" component={EntitySelect} />
                        </div>
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">Notes:</label>
                          <Field name="notes" component={Textarea} />
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col xl="6">
                    <Card className="p-3 mb-5">
                      <div className="bg-composed-wrapper bg-warm-flame rounded-sm">
                        <div className="rounded-sm bg-composed-wrapper--image opacity-4" />
                        <div className="bg-composed-wrapper--bg rounded-sm bg-sunrise-purple opacity-7" />
                        <div className="bg-composed-wrapper--content text-center text-white p-3">
                          <h1 className="font-size-xxl font-weight-bold pt-2 pb-1 mb-0">
                            Payment Info
                          </h1>
                        </div>
                      </div>
                      <div className="card-body ">
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">
                            Investment Start:
                          </label>
                          <Field
                            name="investmentStartDate"
                            component={MaskedField}
                            disabled={isShare}
                            type="text"
                            addonSize={"lg"}
                            addonType={"prepend"}
                            addonText={"$"}
                            maskType="date"
                          />
                        </div>
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">
                            Investment End:
                          </label>
                          <Field
                            name="investmentEndDate"
                            component={MaskedField}
                            minDate={values.investmentStartDate}
                            disabled={isShare}
                            type="text"
                            addonSize={"lg"}
                            addonType={"prepend"}
                            addonText={"$"}
                            maskType="date"
                          />
                        </div>
                        <TextWithFrequency
                          data={incomeData}
                          assetSlug={assetSlug}
                          info={{}}
                          isShare={false}
                          fullWidth={true}
                        />
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">
                            Market Value:
                          </label>
                          <Field
                            name="marketValue"
                            component={MaskedField}
                            type="text"
                            addonSize={"lg"}
                            addonType={"prepend"}
                            addonText={"$"}
                            maskType="currency"
                          />
                        </div>
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">
                            Loan to Value (LTV):
                          </label>
                          <Field
                            name="loanToValue"
                            component={MaskedField}
                            addonSize={"lg"}
                            addonType={"prepend"}
                            maskType={"percent"}
                            addonText={"%"}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <div className="row" />

                {!isShare && (
                  <div className="py-5 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary text-uppercase px-5"
                      disabled={loading || updating}
                    >
                      Update
                    </button>
                  </div>
                )}
              </form>
            </div>
          );
        }}
      </Formik>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  getInvestmentInfo: slug => {
    getInvestmentInfo(slug, dispatch);
  },
  updateInvestmentInfo: data => {
    updateInvestmentInfo(data, dispatch);
  },
  getAssetFinancials: slug => getAssetFinancials(slug, dispatch)
});
const mapStateToProps = state => ({
  assetInvestmentInfo: state.assetInvestmentInfo,
  assetBasicInfo: state.assetBasicInfo,
  financial: state.financial
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoteInfo);
