import React, { Component } from "react";
import { connect } from "react-redux";

import ConfirmModal from "../../../shared/components/ConfirmModal";

import { deleteAttachment } from "../../../actions/attachments/attachmentAction";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

class DeleteAttachment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      attachmentDeleted: false
    };
  }

  closeModal = () => {
    const { toggleModal } = this.props;
    this.setState(
      { loading: false, attachmentDeleted: false, error: null },
      () => {
        toggleModal({});
      }
    );
  };

  deleteAttachment = () => {
    const { assetSlug, fileId } = this.props;
    this.setState({ loading: true, error: null }, () => {
      this.props.deleteAttachment({ id: fileId, slug: assetSlug });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevState.attachmentDeleted && this.state.attachmentDeleted) {
      this.closeModal();
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { attachments } = nextProps;

    if (prevState.loading && attachments.type === "DELETE") {
      if (!isFetching(attachments)) {
        if (isSuccess(attachments)) {
          return {
            attachmentDeleted: true,
            loading: false
          };
        } else {
          return {
            attachmentDeleted: false,
            loading: false,
            error: attachments.error
          };
        }
      }
    }
    return null;
  }

  render() {
    const { fileId, modalOpen } = this.props;
    if (!fileId) return null;
    return (
      <ConfirmModal
        title={"Are you sure to delete this attachment?"}
        subtitle={"You cannot undo this operation."}
        type="danger"
        modalOpen={modalOpen}
        onCancel={this.closeModal}
        onSubmit={this.deleteAttachment}
        error={this.state.error}
        loading={this.state.loading}
        btnText={"Delete"}
        btnTextOnLoading={"Deleting"}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    attachments: state.attachments.attachments
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteAttachment: data => deleteAttachment(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAttachment);
