import React, { PureComponent, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { Formik, Field, FieldArray } from "formik";
import renderDatePickerField from "../../../shared/components/form/DatePicker";
import ToggleSwitch from "../../../shared/components/form/ToggleSwitch";
import CategorySelect from "../../../shared/components/form/CategorySelect";
import SelectField from "../../../shared/components/form/SelectField";
import FormField from "../../../shared/components/form/FormField";

import PerfectScrollbar from "react-perfect-scrollbar";
import { hideMissingImport } from "../../../actions/asset/assetAction";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";
import * as Yup from "yup";

import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  saveTransactions,
  getTransactionsFromBank,
  downloadPastSampleXLSX,
  downloadSampleCSV
} from "../../../actions/transaction";
import { propParser } from "../../../actions/propParser";
import FileInput from "../../../shared/components/FileInput";

import { unmaskCurrency } from "../../../helpers";

// const defaultMinDate = new Date("01/01/1900");

const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: true
});

const InlineCurrencyField = ({
  type,
  field,
  form: { touched, errors },
  ...props
}) => (
  <MaskedInput
    {...field}
    mask={currencyMask}
    type={type}
    {...props}
    className="form-control"
  />
);

class PastTransactionImport extends PureComponent {
  constructor(props) {
    super(props);

    const { asset } = props;

    this.state = {
      loading: false,
      error: null,

      showImport: false,

      fileParsed: false,
      fileUploaded: false,
      fileUploading: false,
      fetchingBankData: false,
      selectedExcelProp: "",
      parsed: {},
      period: new Date().getFullYear(),

      //transaction state
      transactionSaving: false,
      transactionSavingErr: "",

      parseType: asset.management_platform || "",
      respParseType: "",

      // manualTransactions: pastTransactions,
      selectedAccount: ""
    };
  }

  formikResetForm;

  getTransactionTemplate = (year, assetType, noOfUnits) => {
    let txnDate = new Date(year, 0, 1);

    let units = noOfUnits || 1;

    if (assetType === "note" || assetType === "syndication") {
      return [
        {
          date: txnDate,
          category: "INTEREST",
          amount: "",
          payee: "Borrower",
          monthly: true,
          description: "Interest Payment"
        }
      ];
    } else {
      let data = [
        {
          date: txnDate,
          category: "real_estate_taxes",
          amount: "",
          payee: "County",
          monthly: false,
          description: "Taxes for " + year
        },
        {
          date: txnDate,
          category: "insurance",
          amount: "",
          payee: "Insurance Company",
          monthly: false,
          description: "Insurance for " + year
        }
      ];

      for (let i = 0; i < units; i++) {
        data.push({
          date: txnDate,
          category: "rent",
          description: units === 1 ? "Rental Income" : "Unit " + (i + 1),
          amount: "",
          payee: "Tenant",
          monthly: true
        });
      }

      return data;
    }
  };

  addTransaction = values => {
    let transactions = values.transactions;

    if (transactions.length > 0) {
      const mappedTran = transactions.map(t => {
        let frequency = t.frequency;
        // If we have a null value for the frequency in case of
        // manual import, we can default it to monthly
        if (this.state.parseType === "manual")
          frequency = t.monthly ? "monthly" : "yearly";

        return {
          category: t.category,
          amount: unmaskCurrency(t.amount),
          payee: t.payee,
          frequency: frequency,
          date: t.date,
          type: t.type,
          name: t.description
        };
      });
      this.setState({ transactionSaving: true, error: null }, () => {
        this.props.saveTransactions({
          slug: this.props.assetSlug,
          transactions: mappedTran,
          pm_import: this.state.parseType !== "manual"
        });
      });
    }
  };

  fetchBankTransactions = () => {
    this.setState({ error: null, fetchingBankData: true }, () => {
      this.props.getTransactionsFromBank({
        slug: this.props.assetSlug,
        account_id: this.state.selectedAccount
      });
    });
  };

  handleFileSelect = files => {
    // clear previously loaded data
    this.setState(
      {
        error: null,
        file: files,
        selectedExcelProp: "",
        parsed: {},
        period: new Date().getFullYear(),
        fetched: false,
        fileParsed: true
      },
      () => {
        this.props.propertyParser({
          file: this.state.file,
          parseType: this.state.parseType
        });
      }
    );
  };

  handleFileError = e => {
    this.setState({ error: e });
  };

  handleReset = ({ resetForm }) => {
    this.setState({ showImport: false, parseType: "" });
  };

  handleOptionChange = ({ target }) => {
    this.setState({
      parseType: target.value,
      selectedExcelProp: "",
      selectedAccount: "",
      parsed: {},
      fetched: false
    });
  };

  downloadSampleXLSX = parserType => {
    if (parserType === "bettercapital")
      this.props.downloadSampleCSV({ slug: this.props.assetSlug });
  };

  handleExcelPropertyChange = ({ target }) => {
    this.setState({
      selectedExcelProp: target.value,
      errors: { ...this.state.errors, excel: "" }
    });
  };

  toggleImportModal = () => {
    this.setState(prevState => ({
      showImport: !prevState.showImport,
      fileUploaded: false,
      parseType: this.props.asset.management_platform || "",
      selectedExcelProp: "",
      selectedAccount: "",
      fileParsed: false
    }));
  };

  handleBankAccountChange = ({ target }) => {
    this.setState({
      selectedAccount: target.value
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.fileParsed) {
      return {
        fileUploading: true,
        fileParsed: false
      };
    }

    if (prevState.fileUploading) {
      if (nextProps.propParser.propertyParseSuccess === true) {
        return {
          error: null,
          parsed: nextProps.propParser.parsed,
          fileUploading: false,
          fetched: true,
          // respParseType: nextProps.propParser.parseType,
          selectedExcelProp: Object.keys(nextProps.propParser.parsed)[0]
        };
      } else if (nextProps.propParser.propertyParseSuccess === false) {
        return {
          error: nextProps.propParser.error,
          fileUploading: false
          // respParseType: ""
        };
      }
    }

    if (prevState.transactionSaving) {
      const {
        transactions: { transactions }
      } = nextProps;
      if (!isFetching(transactions) && transactions.type === "SAVE") {
        if (isSuccess(transactions)) {
          return {
            parseType: "",
            parsed: {},
            transactionSaving: false,
            fileUploaded: true
          };
        } else {
          return {
            transactionSavingErr: transactions.error,
            transactionSaving: false,
            fileUploaded: true
          };
        }
      }
    }

    if (prevState.fetchingBankData) {
      const { transactions } = nextProps;
      if (transactions.isGetTransactionsFromBankSuccess === true) {
        return {
          parseType: "bank",
          parsed: transactions.bankTransactions,
          fetchingBankData: false
        };
      } else if (transactions.isGetTransactionsFromBankSuccess === false) {
        return {
          error: transactions.error,
          fetchingBankData: false,
          parsed: []
        };
      }
    }
    return null;
  }

  togglePastTransaction = () => {
    this.setState(prevState => ({
      pastTransaction: !prevState.pastTransaction
    }));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevState.fileUploaded && this.state.fileUploaded) {
      this.toggleImportModal();
    }
  };

  getImportOptions = () => {
    const {
      asset: { assetType, management_platform }
    } = this.props;
    let options;
    options = [
      <option key="bettercapital" value="bettercapital">
        BetterCapital (Excel)
      </option>,
      <option key="manual" value="manual">
        Manual
      </option>
    ];

    if (assetType === "rental") {
      switch (management_platform) {
        case "airbnb":
          options.unshift(
            <option key="airbnb" value="airbnb">
              AirBnb (CSV)
            </option>
          );
          break;
        case "cozy":
          options.unshift(
            <option key="cozy" value="cozy">
              Cozy.co (CSV)
            </option>
          );
          break;
        case "rentmanager":
          options.unshift(
            <option key="rentmanager" value="rentmanager">
              Rent Manager (Excel)
            </option>
          );
          break;
        case "hemlane":
          options.unshift(
            <option key="hemlane" value="hemlane">
              Hemlane (CSV)
            </option>
          );
          break;
        case "propertyware":
          options.unshift(
            <option key="propertyware" value="propertyware">
              Propertyware (PDF)
            </option>
          );
          break;
        case "appfolio":
          options.unshift(
            <option key="appfolio" value="appfolio">
              Appfolio (PDF)
            </option>
          );
          break;
        case "buildium":
          options.unshift(
            <option key="buildium" value="buildium">
              Buildium (Excel)
            </option>
          );
          break;
        case "homeroom":
          options.unshift(
            <option key="homeroom" value="homeroom">
              HomeRoom (Excel)
            </option>
          );
          break;
        case "quickbook":
          options.unshift(
            <option key="quickbook" value="quickbook">
              QuickBooks (Excel)
            </option>
          );
          break;
        case "yardi":
          options.unshift(
            <option key="yardi" value="yardi">
              Yardi (Excel)
            </option>
          );
          break;
        case "tenantcloud":
          options.unshift(
            <option key="tenantcloud" value="tenantcloud">
              TenantCloud (Excel)
            </option>
          );
          break;
        default:
          break;
      }
    }
    options.unshift(
      <option key="none" value="">
        Select import type
      </option>
    );
    return options;
  };
  renderImportOption = (arrayHelpers, disabled) => {
    if (!this.state.parseType || this.state.parseType === "none")
      return <div />;

    switch (this.state.parseType) {
      case "manual":
        return (
          <div className="col-12 col-sm-6">
            <Button
              color="primary"
              className="btn view-reminder-btn"
              type="button"
              onClick={() =>
                arrayHelpers.push({
                  date: new Date(this.state.period, 0, 1),
                  amount: "",
                  category: "",
                  payee: "",
                  description: ""
                })
              }
              disabled={disabled}
            >
              {"+ Add Transaction"}
            </Button>
          </div>
        );

      case "bettercapital":
        return (
          <div className="col-sm-6">
            <FileInput
              onSuccess={files => {
                this.handleFileSelect(files);
              }}
              onError={this.handleFileError}
              extensions={["xlsx", "xls", "pdf", "csv"]}
              multiple={false}
              disabled={disabled}
              id={"file"}
              className={"hide"}
            />
            <Button
              color="first"
              className="my-2"
              onClick={() => this.downloadSampleXLSX(this.state.parseType)}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={faDownload} />
              </span>
              <span className="btn-wrapper--label">Excel Template</span>
            </Button>
          </div>
        );
      default:
        return (
          <div className="col-sm-6">
            <FileInput
              onSuccess={files => {
                this.handleFileSelect(files);
              }}
              onError={this.handleFileError}
              extensions={["xlsx", "xls", "pdf", "csv"]}
              multiple={false}
              disabled={disabled}
              id={"file"}
              className={"hide"}
            />
          </div>
        );
    }
  };

  periodChange = value => {
    this.setState({ period: value });
  };

  render() {
    const validationSchema = Yup.object().shape({
      parseType: Yup.string().required("Parse Type is required"),
      transactions: Yup.array().of(
        Yup.object({
          category: Yup.string().required("Category is required"),
          amount: Yup.string()
            .required("Amount is required")
            .max(16, "Amount is too large")
        })
      )
    });

    const {
      fileUploading,
      error,
      parsed,
      selectedExcelProp,
      parseType,
      /*assetLoading,
      myProperties,
      errors */
      transactionSaving,
      fetched,
      period
    } = this.state;

    const { asset } = this.props;

    const parsedOK = !fileUploading && !error;
    const showTable = parsedOK && fetched;
    const properties = Object.keys(parsed || {});
    let toShowProperty = [];

    if (parseType === "manual") {
      toShowProperty = this.getTransactionTemplate(
        period,
        asset.assetType,
        asset.units
      );
    } else if (
      parseType === "bettercapital" ||
      parseType === "cozy" ||
      parseType === "airbnb" ||
      parseType === "hemlane"
    ) {
      toShowProperty =
        parsed && parsed[selectedExcelProp]
          ? parsed[selectedExcelProp].data
          : [];
    }
    // else if (parseType === "bank") toShowProperty = parsed;
    else toShowProperty = (parsed && parsed[selectedExcelProp]) || [];

    return (
      <Fragment>
        <Button color="first" className="mx-2" onClick={this.toggleImportModal}>
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faUpload} />
          </span>
          <span className="btn-wrapper--label">Import</span>
        </Button>
        <Formik
          enableReinitialize
          initialValues={{
            period: period,
            parseType: parseType,
            transactions: toShowProperty
          }}
          validationSchema={validationSchema}
          onSubmit={this.addTransaction}
        >
          {props => {
            const { handleSubmit, values } = props;
            const { loading } = this.state;
            return (
              <Modal
                zIndex={2000}
                centered
                size="xl"
                isOpen={this.state.showImport && !this.state.fileUploaded}
                toggle={() => this.handleReset(props)}
              >
                <ModalHeader toggle={this.toggleImportModal}>
                  Import Transactions
                </ModalHeader>
                <form onSubmit={handleSubmit}>
                  <ModalBody>
                    <FieldArray
                      name="transactions"
                      render={arrayHelpers => (
                        <div>
                          <div className="form-group row">
                            <div className="col-12 col-sm-6 pb-3 inputblock">
                              <select
                                className="form-control"
                                name={"parseType"}
                                disabled={fileUploading}
                                value={this.state.parseType}
                                onChange={this.handleOptionChange}
                              >
                                {this.getImportOptions(asset.assetType)}
                              </select>
                            </div>

                            {this.renderImportOption(
                              arrayHelpers,
                              !parseType || fileUploading || transactionSaving
                            )}
                          </div>

                          {showTable && (
                            <div className="form-group row">
                              {/* <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Import From:</label> */}
                              <div className="col-sm-12 inputblock">
                                <select
                                  className="form-control"
                                  value={this.state.selectedExcelProp}
                                  onChange={this.handleExcelPropertyChange}
                                >
                                  <option value="">Select Property</option>
                                  {properties.map((k, i) => (
                                    <option value={k} key={i}>
                                      {k}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}
                          {parseType === "manual" && (
                            <div className="form-group row">
                              <label
                                htmlFor="staticEmail"
                                className="col-2 col-form-label"
                              >
                                Transaction Year:
                              </label>
                              <div className="col-4">
                                <Field
                                  name="period"
                                  component={SelectField}
                                  onChange={this.periodChange}
                                  options={_.times(3, function(i) {
                                    let year = new Date().getFullYear() - i;
                                    return { value: year, label: year };
                                  })}
                                  placeholder="Select Year"
                                />
                              </div>
                            </div>
                          )}
                          {values.transactions &&
                            values.transactions.length > 0 && (
                              <PerfectScrollbar className={"scroll-area-lg"}>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        style={{ borderBottom: "none" }}
                                      >
                                        Category
                                      </th>
                                      <th
                                        scope="col"
                                        style={{ borderBottom: "none" }}
                                      >
                                        Description
                                      </th>
                                      <th
                                        scope="col"
                                        style={{ borderBottom: "none" }}
                                      >
                                        Date
                                      </th>
                                      <th
                                        scope="col"
                                        style={{ borderBottom: "none" }}
                                      >
                                        Amount
                                      </th>

                                      {parseType === "manual" && (
                                        <th
                                          scope="col"
                                          style={{ borderBottom: "none" }}
                                        >
                                          Monthly
                                        </th>
                                      )}
                                      {parseType === "manual" && (
                                        <th
                                          scope="col"
                                          style={{ borderBottom: "none" }}
                                        />
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {values.transactions.map((t, i) => (
                                      <tr key={i}>
                                        <td className="w-30">
                                          <CategorySelect
                                            name={`transactions.${i}.category`}
                                            value={
                                              props.getFieldMeta(
                                                `transactions.${i}.category`
                                              ).value
                                            }
                                            onUpdate={value => {
                                              props.setFieldValue(
                                                `transactions.${i}.category`,
                                                value
                                              );
                                            }}
                                          />
                                        </td>
                                        <td className="w-30">
                                          <Field
                                            component={FormField}
                                            name={`transactions.${i}.description`}
                                            type="text"
                                            placeholder="Description"
                                          />
                                        </td>
                                        <td className="w-20">
                                          <Field
                                            name={`transactions.${i}.date`}
                                            component={renderDatePickerField}
                                            placeholder={"Pick date"}
                                            mode={"DAY"}
                                          />
                                        </td>
                                        <td className="w-20">
                                          <Field
                                            component={InlineCurrencyField}
                                            name={`transactions.${i}.amount`}
                                            type="text"
                                            placeholder="Amount"
                                          />
                                        </td>

                                        {parseType === "manual" && (
                                          <td className="w-10 pl-3">
                                            <Field
                                              name={`transactions.${i}.monthly`}
                                              component={ToggleSwitch}
                                              type={"number"}
                                            />
                                          </td>
                                        )}
                                        {parseType === "manual" && (
                                          <td className="w-10 pl-3">
                                            <Button
                                              color="neutral-danger"
                                              className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                              onClick={() =>
                                                arrayHelpers.remove(i)
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={faTimes}
                                                className="font-size-sm"
                                              />
                                            </Button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </PerfectScrollbar>
                            )}
                        </div>
                      )}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="reset"
                      className="btn mb-3 btn-secondary px-5 d-inline-block mx-2"
                      onClick={this.toggleImportModal}
                      disabled={fileUploading || transactionSaving}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className="btn mb-3 savebtns px-5  d-inline-block mx-2"
                      type="submit"
                      disabled={fileUploading || transactionSaving}
                    >
                      {loading ? "Importing" : "Import"}
                    </Button>
                  </ModalFooter>
                </form>
              </Modal>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  propParser: state.propParser,
  transactions: state.transactions
});

const mapDispatchToProps = dispatch => {
  return {
    saveTransactions: data => saveTransactions(data, dispatch),
    propertyParser: file => propParser(file, dispatch),
    hideMissingImport: () => hideMissingImport(dispatch),
    downloadPastSampleXLSX: data => downloadPastSampleXLSX(data, dispatch),
    downloadSampleCSV: data => downloadSampleCSV(data, dispatch),
    getTransactionsFromBank: data => getTransactionsFromBank(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PastTransactionImport);
