import React from "react";
import { connect } from "react-redux";
import ChatCard from "./components/ChatCard";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import SectionHeader from "../../shared/components/SectionHeader";

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      isInnerSidebarOpen: false
    };
  }

  toggleInnerSidebar = () => {
    this.setState(prevState => ({
      isInnerSidebarOpen: !prevState.isInnerSidebarOpen
    }));
  };

  componentWillMount() {
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated) this.setState({ loggedIn: true });
    else this.setState({ loggedIn: false });
  }

  componentWillReceiveProps(nextProps) {
    const { isAuthenticated } = nextProps.auth;
    if (isAuthenticated) this.setState({ loggedIn: true });
    else this.setState({ loggedIn: false });
  }

  render() {
    return (
      <div className="p-5">
        <SectionHeader titleHeading="Chats">
          {/* <button
                  onClick={() => this.toggleModal(null)}
                  className="btn btn-primary px-4"
                >
                  <i className="fas fa-comments" /> Add Contact
                </button> */}

          <div className="btn-md-pane d-lg-none px-4 order-0">
            <Button
              onClick={this.toggleInnerSidebar}
              size="sm"
              color="primary"
              className="p-0 btn-icon d-40"
            >
              <FontAwesomeIcon icon={faBars} />
            </Button>
          </div>
        </SectionHeader>
        {this.state.loggedIn && (
          <ChatCard
            {...this.props}
            isInnerSidebarOpen={this.state.isInnerSidebarOpen}
          />
        )}

        <div
          onClick={this.toggleInnerSidebar}
          className={clsx("sidebar-inner-layout-overlay", {
            active: this.state.isInnerSidebarOpen
          })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.login
});
export default connect(
  mapStateToProps,
  null
)(Chat);
