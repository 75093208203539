import React, { PureComponent } from "react";
import { Card, CardHeader, CardBody, Button, Badge } from "reactstrap";
import _ from "lodash";
import { formatCurrency } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFunnelDollar } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ImportConfirmModal from "./ImportConfirmModal";

class BankTransactions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,

      fetchingBankData: false,
      transactions: [],
      selectedRows: [],
      //transaction state
      transactionSaving: false,

      selectedAccount: "",
      showSaveModal: false,
      selectedAsset: "",
      createFilter: false,
      showImport: false
    };
  }

  columnProps = [
    { dataField: "txnId", hidden: true },
    {
      dataField: "category",
      text: "Category",
      sort: true
    },
    {
      dataField: "name",
      text: "Description",
      sort: true
    },
    {
      dataField: "date",
      text: "Date",
      sort: true
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: (cell, row) => {
        if (row.amount) {
          return formatCurrency.format(row.amount);
        }
      }
    },
    {
      dataField: "",
      text: "Action",
      align: "center",
      headerStyle: { width: "25em" },
      formatter: (cell, row) => {
        if (row.imported)
          return (
            <Badge color="success" className="h-auto  py-2 px-4 font-size-md">
              <FontAwesomeIcon icon={faCheckSquare} className="mr-1" />
              <span> Imported</span>
            </Badge>
          );
        if (row.hasFilter)
          return (
            <Badge color="dark" className="h-auto py-2 px-4 font-size-md">
              <FontAwesomeIcon icon={faFunnelDollar} className="mr-1" />
              <span>Has Filter</span>
            </Badge>
          );
        return (
          <div className="d-flex align-items-center">
            <Button
              className={"btn-neutral-second mr-2"}
              onClick={() => this.toggleAddFilterModal(row)}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={faRedo} />
              </span>
              <span className="btn-wrapper--label">Reccuring</span>
            </Button>
            <Button
              className={"btn-neutral-first mr-2"}
              onClick={() => this.toggleImportModal(row)}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={faDownload} />
              </span>
              <span className="btn-wrapper--label">One-Time</span>
            </Button>
          </div>
        );
      }
    }
  ];

  toggleImportModal = row => {
    this.setState(prevState => ({
      showImport: !prevState.showImport,
      selectedRows: [row]
    }));
  };

  toggleAddFilterModal = row => {
    this.setState(prevState => ({
      createFilter: !prevState.createFilter,
      selectedRows: [row]
    }));
  };

  handleOnSelect = (row, isSelect) => {
    const { selectedRows } = this.state;
    let rows = selectedRows || [];
    if (isSelect) {
      rows.push(row);
    } else {
      rows = rows.filter(r => r.txnId !== row.txnId);
    }
    this.setState({ selectedRows: rows });
    return true;
  };

  handleOnSelectAll = (isSelect, rows) => {
    this.setState({ selectedRows: isSelect ? rows : [] });
    return true;
  };

  handleAssetChange = ({ target }) => {
    this.setState({
      selectedAsset: target.value
    });
  };

  updateSavedTransactions = () => {
    const { selectedRows } = this.state;
    this.setState({
      transactions: _.difference(this.state.transactions, selectedRows),
      error: null,
      createFilter: false
    });
  };

  cancelImport = () => {
    this.setState({
      selectedRows: [],
      createFilter: false,
      showImport: false
    });
  };

  render() {
    const { parsedTransactions } = this.props;
    if (parsedTransactions.length <= 0) return <div />;
    return (
      <Card className="card-box mb-5">
        <CardHeader>
          <div className="card-header--title">
            <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
              Bank Transactions
            </h4>
            <p className="text-black-50 mb-0">
              Select relevant transactions to import
            </p>
          </div>
          <div className="card-header--actions">
            <ImportConfirmModal
              assets={this.props.assets}
              onImport={this.updateSavedTransactions}
              onCancel={this.cancelImport}
              selectedAsset={this.state.selectedAsset}
              selectedTrans={this.state.selectedRows}
              accountId={this.props.accountId}
              handleAssetChange={this.handleAssetChange}
              bankImport={true}
              createFilter={this.state.createFilter}
              showImport={this.state.showImport}
            />
          </div>
        </CardHeader>
        <CardBody>
          <BootstrapTable
            keyField="txnId"
            columns={this.columnProps}
            data={parsedTransactions}
            bootstrap4
            headerClasses="table-bordered"
            pagination={paginationFactory()}
          />
        </CardBody>
      </Card>
    );
  }
}

export default BankTransactions;
