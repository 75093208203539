import {
  SAVE_ATTACHMENTS,
  GET_ATTACHMENTS,
  GET_ATTACHMENT_URL,
  DELETE_ATTACHMENT,
  ADD_ATTACHMENT_TAGS,
  REQUEST_DOCUMENT
} from "../actionTypes";

export const saveAttachments = (files, dispatch) => {
  dispatch({
    type: SAVE_ATTACHMENTS,
    payload: files
  });
};

export const getAttachments = (slug, dispatch) => {
  dispatch({
    type: GET_ATTACHMENTS,
    payload: slug
  });
};

export const getAttachmentUrl = (data, dispatch) => {
  dispatch({
    type: GET_ATTACHMENT_URL,
    payload: data
  });
};

export const deleteAttachment = (data, dispatch) => {
  dispatch({
    type: DELETE_ATTACHMENT,
    payload: data
  });
};

export const addAttachmentTags = (data, dispatch) => {
  dispatch({
    type: ADD_ATTACHMENT_TAGS,
    payload: data
  });
};

export const requestDocument = (data, dispatch) => {
  dispatch({
    type: REQUEST_DOCUMENT,
    payload: data
  });
};
