import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";

import SelectField from "../../shared/components/form/SelectField";
import FormField from "../../shared/components/form/FormField";
import { addContact } from "../../actions/contacts";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import { formatTitle } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SlidingPane from "react-sliding-pane";

const AddContactModal = ({
  modalOpen,
  handleSubmit,
  handleReset,
  loading,
  error
}) => (
  <SlidingPane
    from="right"
    width="350px"
    hideHeader={true}
    className="d-flex justify-content-center modal-content"
    isOpen={modalOpen}
    onRequestClose={handleReset}
  >
    <ModalHeader toggle={handleReset}>Add Contact</ModalHeader>

    <form onSubmit={handleSubmit}>
      <ModalBody>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Email Address:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="email"
              component={FormField}
              type="text"
              placeholder="Email address"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            First Name:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="first_name"
              component={FormField}
              type="text"
              placeholder="First name"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Last Name:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="last_name"
              component={FormField}
              type="text"
              placeholder="Last name"
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Category:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="category"
              component={SelectField}
              options={[
                "tenant",
                "investor",
                "realtor",
                "contractor",
                "lender",
                "property_manager",
                "other"
              ].map(key => {
                return { value: key, label: formatTitle(key) };
              })}
              placeholder="Select Category"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="reset"
          className="btn btn-secondary px-5 d-inline-block mx-2"
          onClick={handleReset}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="btn savebtns btn-primary px-5  d-inline-block mx-2"
          type="submit"
          disabled={loading}
        >
          {loading ? "Saving" : "Save"}
        </Button>
      </ModalFooter>
    </form>
  </SlidingPane>
);

const validationSchema = Yup.object({
  first_name: Yup.string("").required("First name is required"),
  last_name: Yup.string("").required("Last name is required"),
  email: Yup.string("")
    .email("Enter valid email")
    .required("Email is required"),
  category: Yup.string("").required("Category is required")
});

const AddContact = props => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => setShowModal(prevState => !prevState);
  const [request, setRequest] = useState({
    loading: false,
    error: null
  });

  const addContact = (values, { resetForm }) => {
    setRequest({ loading: true, error: null });
    props.addContact({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      category: values.category
    });
  };

  const closeModal = () => {
    setRequest({ loading: false, error: null });
    setShowModal(false);
  };

  useEffect(() => {
    const {
      contacts: { contact }
    } = props;

    if (request.loading && !isFetching(contact) && contact.type === "ADD") {
      if (isSuccess(contact)) {
        setRequest({ loading: false, error: null });
        setShowModal(false);
        props.refreshContacts();
      } else {
        setRequest({ loading: false, error: contact.data.error });
        setShowModal(false);
      }
    }
  });

  const initialValues = {
    email: "",
    category: "",
    first_name: "",
    last_name: ""
  };

  return (
    <>
      <Button color="primary" className="mx-2" onClick={toggle}>
        <span className="btn-wrapper--icon">
          <FontAwesomeIcon icon={faPlus} />
        </span>
        <span className="btn-wrapper--label">Add Contact</span>
      </Button>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={addContact}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {props => {
          return (
            <AddContactModal
              handleSubmit={props.handleSubmit}
              handleReset={closeModal}
              modalOpen={showModal}
              loading={request.loading}
              error={request.error}
            />
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = state => ({
  contacts: state.contacts
});

const mapDispatchToProps = dispatch => {
  return {
    addContact: data => addContact(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddContact);
