import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Field /* Form */ } from "formik";
import * as Yup from "yup";
import { unmaskCurrency } from "../../../helpers/";
import {
  getInvestmentInfo,
  updateInvestmentInfo
} from "../../../actions/asset/assetAction";
import { getAssetFinancials } from "../../../actions/financial";

import MaskedField from "../../../shared/components/form/MaskedField";
import EntitySelect from "../../../shared/components/form/EntitySelect";
import Textarea from "../../../shared/components/form/Textarea";
import { Row, Col, Card } from "reactstrap";

import FormField from "../../../shared/components/form/FormField";
// import customSelect from '../../../shared/components/form/customSelect';
import TextWithFrequency from "../FinancialWorksheet/TextWithFrequency";

const validationSchema = Yup.object({
  investmentStartDate: Yup.string().required(
    "Investment Start date is required"
  ),
  investmentEndDate: Yup.string().required("Investment End date is required"),

  /*   purchaseValue: Yup.string().max(16, 'Purchase value too large'),
  marketValue: Yup.string().max(16, 'Market value too large'),
  marketRent: Yup.string().max(16, 'Market rent too large'),
  downPayment: Yup.string().max(16, 'Down payment too large'),

  askingPrice: Yup.string().max(16, 'Asking Price too large'),
  afterRepairValue: Yup.string().max(16, 'After Repair value too large'),
  holdingCosts: Yup.string().max(16, 'Holding Costs too large'),
  rehabEstimate: Yup.string().max(16, 'Rehab Estimate too large'), */

  llcName: Yup.string().max(100, "LLC Name too long"),
  amountInvested: Yup.string().max(16, "Amount Invested too large"),
  amountEarnedEachMonth: Yup.string().max(
    16,
    "Amount Earned each month too large"
  )

  /*   balance:Yup.string().max(16, 'Balance too large'),
  yield:Yup.string().max(16, 'Yield too large'),
  investmentToBalance: Yup.string().max(16, 'Investment to balance too large'),
  investmentToValue: Yup.string().max(16, 'Investment to value too large'),
  loanToValue: Yup.string().max(16, 'Loan to value too large'),
  lienPosition: Yup.number().typeError("Enter valid Lien position").integer("Enter valid Lien position").min(1, 'Min 1 unit is required').required('Enter Lien position'),
  unpaidBalance: Yup.string().max(16, 'Unpaid Balance too large'),
  isPerforming: Yup.string().max(1, 'Performing too large'), */
});

class SyndicateInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      investmentInfo: {
        llcName: "",
        amountInvested: "",
        amountEarnedEachMonth: "",
        entity: "",
        investmentStartDate: "",
        investmentEndDate: ""
      }
    };
  }

  updateInvestmentInfo = values => {
    values.llcName = unmaskCurrency(values.llcName);
    values.amountInvested = unmaskCurrency(values.amountInvested);
    values.amountEarnedEachMonth = unmaskCurrency(values.amountEarnedEachMonth);

    this.setState({ loading: true, error: null }, () => {
      values.slug = this.props.assetSlug;
      this.props.updateInvestmentInfo(values);
    });
  };

  componentWillMount() {
    const { assetSlug } = this.props;
    this.props.getInvestmentInfo(assetSlug);
  }

  componentWillReceiveProps(nextProps) {
    const { loading } = this.state;
    const { assetInvestmentInfo } = nextProps;
    if (loading && this.props !== nextProps) {
      if (assetInvestmentInfo.loadingInvestmentInfoSuccess === true) {
        const investmentInfo = assetInvestmentInfo.investmentInfo;
        this.setState({ investmentInfo, loading: false });
      } else if (assetInvestmentInfo.loadingInvestmentInfoSuccess === false) {
        this.setState({ error: assetInvestmentInfo.error, loading: false });
      }

      if (assetInvestmentInfo.updateInvestmentInfoSuccess === true) {
        const investmentInfo = assetInvestmentInfo.investmentInfo;

        this.setState({ investmentInfo, loading: false });
      } else if (assetInvestmentInfo.updateInvestmentInfoSuccess === false) {
        this.setState({ error: assetInvestmentInfo.error, loading: false });
      }
    }
  }
  render() {
    const initialValues = this.state.investmentInfo;
    const { loading } = this.state;
    const {
      isShare /* assetBasicInfo */,
      assetSlug,
      financial: { financials }
    } = this.props;
    // const basicInfo = assetBasicInfo.basicInfo;
    let incomeData;
    if (
      financials &&
      financials.findIndex(f => f.category === "DISTRIBUTION") >= 0
    ) {
      const index = financials.findIndex(f => f.category === "DISTRIBUTION");
      incomeData = financials[index];
    } else {
      const currentDate = new Date();
      const defaultDueDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        5
      );
      incomeData = {
        type: "INCOME",
        category: "DISTRIBUTION",
        frequency: "Quarterly",
        amount: "",
        dueDates: [defaultDueDate],
        notify: true,
        placeholder: true
      };
    }

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.updateInvestmentInfo}
      >
        {props => {
          let values = props.values;
          return (
            <div className="px-3 py-4">
              <form onSubmit={props.handleSubmit}>
                {/* syndication */}
                <Row>
                  <Col xl="6">
                    <Card className="p-3 mb-5">
                      <div className="bg-composed-wrapper bg-deep-blue rounded-sm">
                        <div className="rounded-sm bg-composed-wrapper--image opacity-4" />
                        <div className="bg-composed-wrapper--bg rounded-sm bg-serious-blue opacity-7" />
                        <div className="bg-composed-wrapper--content text-center text-white p-3">
                          <h1 className="font-size-xxl font-weight-bold pt-2 pb-1 mb-0">
                            Syndication Info
                          </h1>
                        </div>
                      </div>
                      <div className="card-body ">
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">LLC Name:</label>
                          <Field
                            name="llcName"
                            component={FormField}
                            size="lg"
                          />
                          {/* <span className="usd_icon">$</span> */}
                        </div>

                        <div className="form-group  inputblock">
                          <label htmlFor="exampleInputEmail1">
                            Entity Name:
                          </label>
                          <Field name="entity" component={EntitySelect} />
                          {/* <span className="usd_icon">$</span> */}
                        </div>
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">Notes:</label>
                          <Field name="notes" component={Textarea} size="lg" />
                          {/* <span className="usd_icon">$</span> */}
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col xl="6">
                    <Card className="p-3 mb-5">
                      <div className="bg-composed-wrapper bg-warm-flame rounded-sm">
                        <div className="rounded-sm bg-composed-wrapper--image opacity-4" />
                        <div className="bg-composed-wrapper--bg rounded-sm bg-sunrise-purple opacity-7" />
                        <div className="bg-composed-wrapper--content text-center text-white p-3">
                          <h1 className="font-size-xxl font-weight-bold pt-2 pb-1 mb-0">
                            Payment Info
                          </h1>
                        </div>
                      </div>
                      <div className="card-body ">
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">
                            Investment Start:
                          </label>
                          <Field
                            name="investmentStartDate"
                            component={MaskedField}
                            disabled={isShare}
                            type="text"
                            addonSize={"lg"}
                            addonType={"prepend"}
                            addonText={"$"}
                            maskType="date"
                          />
                        </div>
                        <div className="form-group inputblock">
                          <label htmlFor="exampleInputEmail1">
                            Investment End:
                          </label>
                          <Field
                            name="investmentEndDate"
                            component={MaskedField}
                            minDate={values.investmentStartDate}
                            disabled={isShare}
                            type="text"
                            addonSize={"lg"}
                            addonType={"prepend"}
                            addonText={"$"}
                            maskType="date"
                          />
                        </div>
                        <TextWithFrequency
                          data={incomeData}
                          assetSlug={assetSlug}
                          info={{}}
                          isShare={false}
                          fullWidth={true}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
                <div className="row" />

                {!isShare && (
                  <div className="py-5 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary text-uppercase px-5"
                      disabled={loading}
                    >
                      Update
                    </button>
                  </div>
                )}
              </form>
            </div>
          );
        }}
      </Formik>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getInvestmentInfo: slug => {
    getInvestmentInfo(slug, dispatch);
  },
  updateInvestmentInfo: data => {
    updateInvestmentInfo(data, dispatch);
  },
  getAssetFinancials: slug => getAssetFinancials(slug, dispatch)
});
const mapStateToProps = state => ({
  assetInvestmentInfo: state.assetInvestmentInfo,
  assetBasicInfo: state.assetBasicInfo,
  financial: state.financial
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyndicateInfo);
