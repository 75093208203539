import capAllWords from "./capAllWords";
import unmaskCurrency from "./unmaskCurrency";
import saveFile from "./fileSaver";
import bgSelector from "./bgSelector";
import bytesToSize from "./bytesToSize";
import numberFormatter from "./numberFormatter";
import _ from "lodash";
import { categoryOptions } from "./constants";

const formatCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0
});

const formatPercent = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const formatRelativeTime = new Intl.RelativeTimeFormat("en-US", {
  style: "narrow"
});

const getAssetStyleClass = function(assetType) {
  switch (assetType) {
    case "primary":
      return "primary";
    case "rental":
      return "success";
    case "note":
      return "info";
    case "syndication":
      return "danger";
    case "flip":
      return "dark";
    case "for_sale":
    default:
      return "first";
  }
};

const getAssetCashflowStyle = function(asset) {
  return assetProfited(asset) ? "success" : "danger";
};

const assetProfited = function(asset) {
  switch (asset.assetType) {
    case "note":
      return (
        asset.stats.cashflow.prevMonth > 0 &&
        asset.stats.cashflow.currMonth >= asset.stats.cashflow.prevMonth
      );
    case "syndication":
      return asset.stats.cummCashflow > 0; // TODO: this needs more rigorous logic
    case "primary":
      return true;
    default:
      return (
        asset.stats.cashflow.currMonth > 0 &&
        asset.stats.cashflow.currMonth >= asset.stats.cashflow.prevMonth
      );
  }
};

const getUserRole = role => {
  switch (role) {
    case "O":
      return "Owner";
    case "I":
      return "Investor";
    case "IA":
      return "Insurance Agent";
    case "R":
      return "Real Estate Agent";
    case "T":
      return "Tenant";
    case "C":
      return "Contractor";
    case "PM":
      return "Property Manager";
    default:
      return "Other";
  }
};

const getOptionsForAssetType = function(assetType) {
  switch (assetType) {
    case "note":
      return [
        {
          label: "Interest",
          value: "INTEREST"
        },
        { label: "Other", value: "OTHER" }
      ];

    case "syndication":
      return [
        {
          label: "Distribution",
          value: "DISTRIBUTION"
        },
        { label: "Other", value: "OTHER" }
      ];
    case "rental":
    case "flip":
    default:
      return [
        { label: "Real Estate Taxes", value: "REAL_ESTATE_TAXES" },
        {
          label: "Interest",
          value: "INTEREST"
        },
        {
          label: "Interest Payout",
          value: "INTEREST PAYOUT"
        },
        { label: "Insurance", value: "INSURANCE" },
        { label: "Rent", value: "RENT" },
        { label: "Mortgage", value: "MORTGAGE" },
        { label: "Other", value: "OTHER" }
      ];
  }
};

const getVideoUrlByAssetType = function(assetType) {
  switch (assetType) {
    case "note":
      return "https://www.youtube.com/watch?v=zUwFWCHH0-k";
    case "syndication":
      return "https://www.youtube.com/watch?v=i5VSIsnP9gI";
    case "fund":
      return "https://www.youtube.com/watch?v=i5VSIsnP9gI";
    default:
      return "https://www.youtube.com/watch?v=F51MNiKyiCI";
  }
};

const getAssetTypeLabel = function(assetType) {
  switch (assetType) {
    case "note":
      return "Private Lending";
    case "for_sale":
      return "For Sale";
    default:
      return _.capitalize(assetType);
  }
};

const encode = function(unencoded) {
  return new Buffer(unencoded || "").toString("base64");
};

const decode = function(encoded) {
  return new Buffer(encoded || "", "base64").toString("utf8");
};

const urlEncode = function(unencoded) {
  var encoded = encode(unencoded);
  return encoded
    .replace("+", "-")
    .replace("/", "_")
    .replace(/=+$/, "");
};

const urlDecode = function(encoded) {
  encoded = encoded.replace("-", "+").replace("_", "/");
  while (encoded.length % 4) encoded += "=";
  return decode(encoded);
};

const updateColumnMapping = function(data, newMapping) {
  let b = {};
  _.each(data, function(value, key) {
    key = newMapping[key] || key;
    b[key] = value;
  });
  return b;
};

const getTxnFilterPattern = function(txn) {
  // We are going to return a wildcard string to search for,
  // description and the amount
  const { name, amount } = txn;
  // get all the words from the name
  // then split it when we hit a alpha numeric string
  let nameChunks = name.split(" ");
  let patternStrings = [];
  for (var i = 0; i < nameChunks.length; i++) {
    if (!/^[a-z]+$/i.test(nameChunks[i])) {
      break;
    }
    patternStrings.push(nameChunks[i]);
  }
  return {
    pattern: patternStrings.join(" "),
    amount: amount
  };
};

const formatTitle = function(str) {
  return _.startCase(_.toLower(str.replace("_", " ")));
};

const getLocalDate = function(dateStr) {
  const dateTimeObj = new Date(dateStr);
  const timeObjWithOffset =
    dateTimeObj.getTime() + dateTimeObj.getTimezoneOffset() * 60000;
  return new Date(timeObjWithOffset).toLocaleDateString("en-US");
};

const getDateComponent = function(dateStr) {
  let dateObj = new Date(getLocalDate(dateStr));
  return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
};

const compareValues = function(from, to, isDateField) {
  if (isDateField) {
    return _.isEqual(getDateComponent(from), getDateComponent(to));
  }
  if (typeof from === "number" || typeof to === "number")
    return _.isEqual(Number(from), Number(to));
  return _.isEqual(String(from), String(to));
};

const getTypeForCategory = function(category) {
  let matchedOption = categoryOptions
    .flatMap(a => a.options)
    .find(a => a.value === category);

  return matchedOption ? matchedOption.type : "income";
};

const getLabelForCategory = function(category) {
  let matchedCategory = categoryOptions
    .flatMap(a => a.options)
    .find(a => a.value === category.toLowerCase());
  return matchedCategory ? matchedCategory.label : _.capitalize(category);
};

const getTransactionDescription = function(category, date) {
  let description = getLabelForCategory(category) + " payment";
  if (date) {
    let monthStr = new Date(date).toLocaleString("default", { month: "short" });
    description += " for " + monthStr;
  }
  return description;
};

const openSocialLink = function(socialType) {
  switch (socialType) {
    case "twitter":
      window.open("https://twitter.com/BetterCapitalUS", "_blank");
      break;
    case "instagram":
      window.open("https://www.instagram.com/bettercapital.us/", "_blank");
      break;
    case "youtube":
      window.open(
        "https://www.youtube.com/channel/UCFXz9rxX0fGze-Ub0Qda_oA",
        "_blank"
      );
      break;
    case "facebook":
    default:
      window.open("https://fb.com/bettercapital.us", "_blank");
      break;
  }
};

var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;

const sortAlphaNumeric = function(a, b) {
  if (!a || !b) return 0;
  var aA = a.replace(reA, "");
  var bA = b.replace(reA, "");
  if (aA === bA) {
    var aN = parseInt(a.replace(reN, ""), 10);
    var bN = parseInt(b.replace(reN, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
};

export {
  formatCurrency,
  formatPercent,
  formatRelativeTime,
  capAllWords,
  unmaskCurrency,
  saveFile,
  bgSelector,
  bytesToSize,
  numberFormatter,
  getAssetStyleClass,
  getAssetCashflowStyle,
  assetProfited,
  getOptionsForAssetType,
  getVideoUrlByAssetType,
  getAssetTypeLabel,
  urlEncode,
  urlDecode,
  updateColumnMapping,
  getTxnFilterPattern,
  getDateComponent,
  getLocalDate,
  compareValues,
  getTypeForCategory,
  getTransactionDescription,
  getLabelForCategory,
  getUserRole,
  openSocialLink,
  sortAlphaNumeric,
  formatTitle
};
