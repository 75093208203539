import React, { PureComponent, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import DatePickerField from "../../../shared/components/form/DatePicker";
import CurrencyField from "../../../shared/components/form/AddonCurrencyField";
import FormField from "../../../shared/components/form/FormField";
import LocationSearch from "../../../shared/components/form/LocationSearch";

import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import renderInputGroupBtnDropdown from "../../../shared/components/form/InputGroupBtnDropdown";
// import Alert from '../../../shared/components/Alert';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";
import { saveTransactions } from "../../../actions/transaction";

const initialValues = {
  category: "EQUITY",
  amount: "",
  name: "",
  location: "",
  start_date: "",
  end_date: ""
};

class AddFund extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
      latLng: {},
      showModal: props.modalOpen,
      loading: false,
      error: null,
      saved: false
    };
  }

  handleChange = location => {
    this.setState({ location });
    geocodeByAddress(location)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ latLng }))
      .catch(error => this.setState({ latLng: {} }));
  };

  handleSelect = location => {
    this.setState({ location });
    geocodeByAddress(location)
      .then(results => this.setState({ result: results[0] }))
      .catch(error => console.error("Error", error));
  };

  formikResetForm;

  addFund = (values, { resetForm }) => {
    // if (values.category !== "OTHER") {
    //   values.type = incomeCategories.includes(values.category)
    //     ? "INCOME"
    //     : "EXPENSE";
    // }
    // values.category =
    //   values.category === "OTHER" ? values.categoryName : values.category;
    // values.amount = unmaskCurrency(values.amount);
    // this.formikResetForm = resetForm;
    // values.isPending = false;
    // this.setState({ loading: true, error: null }, () => {
    //   this.props.saveTransactions({
    //     slug: this.props.asset.slug,
    //     transactions: new Array(values)
    //   });
    // });
  };

  closeModal = () => {
    this.setState({ showModal: false, loading: false, error: null });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      transactions: { transactions }
    } = nextProps;

    if (
      prevState.loading &&
      !isFetching(transactions) &&
      transactions.type === "SAVE"
    ) {
      if (isSuccess(transactions)) {
        return { loading: false, showModal: false };
      } else {
        return { loading: false, error: transactions.error };
      }
    }
    return null;
  }

  toggleAddTransaction = () => {
    this.setState(prevState => ({
      showModal: !prevState.showAddTransaction
    }));
  };

  render() {
    const validationSchema = Yup.object({
      amount: Yup.string().max(16, "Invalid Amount"),
      category: Yup.string().required("Category is required"),
      investment_name: Yup.string().max(100, "Investment name too long"),
      target_return: Yup.number()
        .moreThan(0, "Invalid return percentage")
        .lessThan(100, "Invalid return percentage"),
      start_date: Yup.date().required("Start Date is required"),
      end_date: Yup.date().required("End Date is required"),
      location: Yup.string().required("Location is required")
    });

    const categories = [
      {
        label: "Commerical Debt",
        value: "COMMERICAL_DEBT"
      },
      {
        label: "Residential Debt",
        value: "RESIDENTIAL_DEBT"
      },
      {
        label: "Equity",
        value: "EQUITY"
      }
    ];
    return (
      <Fragment>
        <Button
          color="primary"
          className="mx-2"
          onClick={this.toggleAddTransaction}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faPlus} />
          </span>
          <span className="btn-wrapper--label">Add Fund</span>
        </Button>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.addFund}
        >
          {props => {
            const { handleSubmit } = props;
            const { loading } = this.state;
            return (
              <Modal
                isOpen={this.state.showModal}
                className={"modal-dialog modal-dialog-centered"}
                contentClassName={"newexpensemodal"}
              >
                <ModalHeader toggle={this.closeModal}>{"Add Fund"}</ModalHeader>
                <form onSubmit={handleSubmit}>
                  <ModalBody>
                    <div className="form-group row">
                      <label
                        htmlFor="inputPassword"
                        className="col-sm-2 col-form-label"
                      >
                        Capital Allocated:
                      </label>
                      <div className="col-sm-10">
                        <div className="">
                          <div className="input-group">
                            <Field
                              name="amount"
                              component={CurrencyField}
                              type="text"
                              placeholder="Amount"
                            />

                            <Field
                              name="category"
                              component={renderInputGroupBtnDropdown}
                              options={categories}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Name:
                      </label>
                      <div className="col-sm-10 inputblock">
                        <Field
                          name="investment_name"
                          component={FormField}
                          placeholder="Investment Name"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Target Return:
                      </label>
                      <div className="col-sm-10 inputblock">
                        <Field
                          name="target_return"
                          component={FormField}
                          placeholder="Target Return"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Start Date:
                      </label>
                      <div className="col-sm-10 inputblock">
                        <Field
                          name="start_date"
                          component={DatePickerField}
                          placeholder="Date"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        End Date:
                      </label>
                      <div className="col-sm-10 inputblock">
                        <Field
                          name="end_date"
                          component={DatePickerField}
                          placeholder="Date"
                        />
                      </div>
                    </div>
                    <Field
                      name="location"
                      component={LocationSearch}
                      placeholder="Enter Location"
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="reset"
                      className="btn btn-secondary px-5 d-inline-block mx-2"
                      onClick={this.closeModal}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className="btn savebtns btn-primary px-5  d-inline-block mx-2"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving" : "Save"}
                    </Button>
                  </ModalFooter>
                </form>
              </Modal>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  transactions: state.transactions
});

const mapDispatchToProps = dispatch => {
  return {
    saveTransactions: data => saveTransactions(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFund);
