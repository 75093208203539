import React, { Component } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";

import SelectField from "../../shared/components/form/SelectField";
import FormField from "../../shared/components/form/FormField";
import Alert from "../../shared/components/Alert";

import { updateContact } from "../../actions/contacts";
import { formatTitle } from "../../helpers";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";

const UpdateContactModal = ({
  modalOpen,
  handleSubmit,
  closeModal,
  loading,
  error
}) => (
  <Modal
    isOpen={modalOpen}
    className={"modal-dialog modal-dialog-centered"}
    contentClassName={"newexpensemodal"}
    toggle={closeModal}
  >
    <ModalHeader toggle={closeModal}>{"Update Contact"}</ModalHeader>
    <form onSubmit={handleSubmit}>
      <ModalBody>
        {error && (
          <Alert color="danger" className="alert--bordered" icon>
            <p>Error! {error}</p>
          </Alert>
        )}
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Email Address:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="email"
              component={FormField}
              type="text"
              placeholder="Email address"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            First Name:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="first_name"
              component={FormField}
              type="text"
              placeholder="First name"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Last Name:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="last_name"
              component={FormField}
              type="text"
              placeholder="Last name"
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Category:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="category"
              component={SelectField}
              options={[
                "tenant",
                "investor",
                "realtor",
                "contractor",
                "lender",
                "property_manager",
                "other"
              ].map(key => {
                return { value: key, label: formatTitle(key) };
              })}
              placeholder="Select Category"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="reset"
          className="btn btn-secondary px-5 d-inline-block mx-2"
          onClick={closeModal}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="btn savebtns btn-primary px-5  d-inline-block mx-2"
          type="submit"
          disabled={loading}
        >
          {loading ? "Updating" : "Update"}
        </Button>
      </ModalFooter>
    </form>
  </Modal>
);

const validationSchema = Yup.object({
  first_name: Yup.string("").required("First name is required"),
  last_name: Yup.string("").required("Last name is required"),
  email: Yup.string("")
    .email("Enter valid email")
    .required("Email is required"),
  category: Yup.string("").required("Category is required")
});

class UpdateContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      closeModal: false
    };
  }

  fomrikResetForm;

  closeModal = () => {
    const { toggleModal } = this.props;
    this.setState({ loading: false, error: null }, () => {
      toggleModal({});
    });
  };

  updateContact = (values, { resetForm }) => {
    this.setState({ loading: true, error: null }, () => {
      this.fomrikResetForm = resetForm;
      this.props.updateContact({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        category: values.category,
        slug: this.props.contact.slug
      });
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      contacts: { contact }
    } = nextProps;

    if (
      prevState.loading &&
      !isFetching(contact) &&
      contact.type === "UPDATE"
    ) {
      if (isSuccess(contact)) {
        return {
          loading: false,
          closeModal: true
        };
      } else {
        return {
          loading: false,
          error: contact.data.error,
          closeModal: true
        };
      }
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.closeModal && !prevState.closeModal) {
      this.props.refreshContacts();
    }
  };

  render() {
    const { contact } = this.props;
    if (!contact) return <div />;

    const initialValues = {
      email: contact.email,
      first_name: contact.first_name,
      last_name: contact.last_name,
      category: contact.category
    };
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.updateContact}
      >
        {props => (
          <UpdateContactModal
            handleSubmit={props.handleSubmit}
            {...this.props}
            loading={this.state.loading}
            error={this.state.error}
            closeModal={this.closeModal}
          />
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  contacts: state.contacts
});

const mapDispatchToProps = dispatch => ({
  updateContact: data => updateContact(data, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateContact);
