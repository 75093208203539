// import PropTypes from 'prop-types';
import React, { PureComponent } from "react";
import CurrencyText from "./CurrencyText";
import { getIn } from "formik";

class CurrencyField extends PureComponent {
  render() {
    const {
      field,
      form: { touched, errors }
    } = this.props;
    const touch = getIn(touched, field.name);
    const error = getIn(errors, field.name);

    return <CurrencyText touched={touch} error={error} {...this.props} />;
  }
}
export default CurrencyField;
