import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import FormField from "../../../shared/components/form/FormField";
import Textarea from "../../../shared/components/form/Textarea";
import _ from "lodash";

import customSelect from "../../../shared/components/form/customSelect";
import ToggleSwitch from "../../../shared/components/form/ToggleSwitch";
import { Row, Col, Card } from "reactstrap";
import MaskedField from "../../../shared/components/form/MaskedField";
import EntitySelect from "../../../shared/components/form/EntitySelect";

import { unmaskCurrency } from "../../../helpers/";

import {
  getBasicInfo,
  updateBasicInfo
} from "../../../actions/asset/assetAction";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

const validationSchema = Yup.object({
  beds: Yup.number()
    .typeError("Enter valid beds")
    .integer("Enter valid beds")
    .min(0, "Enter valid beds")
    .max(1000, "Bed size should be less than 1000"),
  baths: Yup.number()
    .typeError("Enter valid baths")
    .min(0, "Enter valid baths")
    .max(1000, "Baths size should be less than 1000"),
  yearBuilt: Yup.number()
    .typeError("Enter valid year built")
    .integer("Enter valid year built")
    .min(0, "Enter valid year built")
    .max(3000, "Enter valid year built"),
  areaSquareFeet: Yup.number()
    .typeError("Enter valid Area Sq.ft")
    .min(0, "Enter valid Area Sq.ft")
    .max(9999999999, "Area Sq.ft is very large"),
  lotSizeSquareFeet: Yup.number()
    .typeError("Enter valid Lot size")
    .min(0, "Enter valid Lot size")
    .max(9999999999, "Lot size Sq.ft is very large"),
  stories: Yup.number()
    .typeError("Enter valid stories")
    .integer("Enter valid stories")
    .min(0, "Enter valid stories")
    .max(1000, "Too many stories"),
  units: Yup.number()
    .typeError("Enter valid units")
    .integer("Enter valid units")
    .min(1, "Min 1 unit is required")
    .max(200, "Max 10 units can be added")
    .required("Enter units between 1-10"),
  trust: Yup.bool(true),
  land: Yup.number()
    .typeError("Enter valid land percentage")
    .integer("Enter valid land percentage")
    .min(0, "Enter valid land percentage")
    .max(100, "Enter valid land percentage"),
  purchaseDate: Yup.string().when("assetType", {
    is: type => type === "note" || type === "syndication",
    then: Yup.string().nullable(),
    otherwise: Yup.string().required("Purchase Date is Required")
  })
});

class Investment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updating: false,
      error: null,
      basicInfo: {
        country: "",
        beds: "",
        baths: "",
        yearBuilt: "",
        areaSquareFeet: "",
        lotSizeSquareFeet: "",
        stories: "",
        units: "",
        trust: "",
        land: 0,
        purchaseDate: "",
        asset_name: "",
        self_managed: true,
        management_platform: "",

        purchaseValue: "",
        marketValue: "",
        afterRepairValue: "",
        rehabEstimate: "",
        marketRent: "",
        downPayment: "",
        landValue: 0,
        entity: ""
      }
    };
  }

  updateBasicInfo = values => {
    this.props.changesInDataSaved && this.props.changesInDataSaved();
    const {
      basicInfo: { slug }
    } = this.state;

    this.setState({ updating: true, error: null }, () => {
      values.slug = slug;
      // TODO: need to figure this out.
      // values.regenerateSlug =
      //   this.state.basicInfo.asset_name !== values.asset_name;
      values.purchaseValue = unmaskCurrency(values.purchaseValue);
      values.marketRent = unmaskCurrency(values.marketRent);
      values.marketValue = unmaskCurrency(values.marketValue);
      values.downPayment = unmaskCurrency(values.downPayment);
      values.landValue = unmaskCurrency(values.landValue);
      values.afterRepairValue = unmaskCurrency(values.afterRepairValue);
      values.rehabEstimate = unmaskCurrency(values.rehabEstimate);
      this.props.updateBasicInfo(values);
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.loading && !this.state.loading) {
      this.props.refreshData();
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading, updating } = prevState;
    const { assetBasicInfo } = nextProps;

    if (updating && !loading) {
      return { loading: true, updating: false };
    }

    if (loading && !_.isEmpty(assetBasicInfo)) {
      if (!isFetching(assetBasicInfo)) {
        if (isSuccess(assetBasicInfo)) {
          return {
            basicInfo: assetBasicInfo.data.basicInfo,
            loading: false
          };
        } else {
          return {
            basicInfo: assetBasicInfo.error,
            loading: false
          };
        }
      }
    }
    return null;
  }

  render() {
    const initialValues = this.state.basicInfo;
    const { loading } = this.state;
    const { isShare, changesInData } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.updateBasicInfo}
      >
        {props => (
          <div className="px-4">
            <form onChange={changesInData} onSubmit={props.handleSubmit}>
              <Row>
                <Col lg="6" xl="4">
                  <Card className="p-3 mb-5">
                    <div className="bg-composed-wrapper bg-happy-fisher rounded-sm">
                      <div className="rounded-sm bg-composed-wrapper--image opacity-4" />
                      <div className="bg-composed-wrapper--bg rounded-sm bg-sunrise-purple opacity-7" />
                      <div className="bg-composed-wrapper--content text-center text-white p-3">
                        <h1 className="font-size-xxl font-weight-bold pt-2 pb-1 mb-0">
                          Ownership Info
                        </h1>
                      </div>
                    </div>
                    <div className="card-body ">
                      <div className="form-group inputblock">
                        <label>Purchase date</label>
                        <Field
                          name="purchaseDate"
                          component={MaskedField}
                          disabled={isShare}
                          type="text"
                          addonSize={"lg"}
                          addonType={"prepend"}
                          addonText={"$"}
                          maskType="date"
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>Purchase Price</label>
                        <Field
                          name="purchaseValue"
                          component={MaskedField}
                          disabled={isShare}
                          type="text"
                          addonSize={"lg"}
                          addonType={"prepend"}
                          addonText={"$"}
                          maskType="currency"
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>Down Payment</label>
                        <Field
                          name="downPayment"
                          component={MaskedField}
                          disabled={isShare}
                          type="text"
                          addonSize={"lg"}
                          addonType={"prepend"}
                          addonText={"$"}
                          maskType="currency"
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>Entity Name</label>
                        <Field name="entity" component={EntitySelect} />
                      </div>
                      <div className="form-group inputblock">
                        <label>Self Managed</label>
                        <Field
                          name="self_managed"
                          component={ToggleSwitch}
                          disabled={isShare}
                          type={"number"}
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>Management Platform</label>
                        <Field
                          name="management_platform"
                          component={customSelect}
                          disabled={isShare}
                          placeholder="Select Platform"
                          value={props.values.management_platform}
                          options={[
                            { value: "airbnb", label: "AirBnB" },
                            { value: "appfolio", label: "AppFolio" },
                            { value: "buildium", label: "Buildium" },
                            { value: "hemlane", label: "Hemlane" },
                            {
                              value: "propertyware",
                              label: "PropertyWare"
                            },
                            { value: "homeroom", label: "HomeRoom" },
                            { value: "rentmanager", label: "RentManager" },
                            { value: "quickbook", label: "QuickBooks" },
                            { value: "yardi", label: "Yardi" },
                            { value: "tenantcloud", label: "TenantCloud" },
                            { value: "cozy", label: "Cozy.co" },
                            { value: "other", label: "Other" },
                            { value: "none", label: "None" }
                          ]}
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>Notes</label>
                        <Field name="notes" component={Textarea} />
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="p-3 mb-5">
                    <div className="bg-composed-wrapper bg-ripe-malin rounded-sm">
                      <div className="rounded-sm bg-composed-wrapper--image opacity-4" />
                      <div className="bg-composed-wrapper--bg rounded-sm bg-love-kiss opacity-7" />
                      <div className="bg-composed-wrapper--content text-center text-white p-3">
                        <h1 className="font-size-xxl font-weight-bold pt-2 pb-1 mb-0">
                          Property Info
                        </h1>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="form-group inputblock">
                        <label>Beds</label>
                        <Field
                          name="beds"
                          component={FormField}
                          disabled={isShare}
                          type={"number"}
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>Baths</label>
                        <Field
                          name="baths"
                          component={FormField}
                          disabled={isShare}
                          type={"number"}
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>Stories</label>
                        <Field
                          name="stories"
                          component={FormField}
                          disabled={isShare}
                          type={"number"}
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>Year Built</label>
                        <Field
                          name="yearBuilt"
                          component={FormField}
                          disabled={isShare}
                          type={"number"}
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>Floor Size</label>
                        <Field
                          name="areaSquareFeet"
                          component={FormField}
                          disabled={isShare}
                          type={"number"}
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>Lot Size</label>
                        <Field
                          name="lotSizeSquareFeet"
                          component={FormField}
                          disabled={isShare}
                          type={"number"}
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>No of Units</label>
                        <Field
                          name="units"
                          component={FormField}
                          disabled={isShare}
                          type={"number"}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="p-3 mb-5">
                    <div className="bg-composed-wrapper bg-warm-flame rounded-sm">
                      <div className="rounded-sm bg-composed-wrapper--image opacity-4" />
                      <div className="bg-composed-wrapper--bg rounded-sm bg-sunrise-purple opacity-7" />
                      <div className="bg-composed-wrapper--content text-center text-white p-3">
                        <h1 className="font-size-xxl font-weight-bold pt-2 pb-1 mb-0">
                          Valuation Info
                        </h1>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="form-group inputblock">
                        <label>Market Value</label>
                        <Field
                          name="marketValue"
                          component={MaskedField}
                          disabled={isShare}
                          type="text"
                          addonSize={"lg"}
                          addonType={"prepend"}
                          addonText={"$"}
                          maskType="currency"
                        />
                      </div>

                      <div className="form-group inputblock">
                        <label>Land Value</label>
                        <Field
                          name="landValue"
                          disabled={isShare}
                          component={MaskedField}
                          addonSize={"lg"}
                          addonType={"prepend"}
                          addonText={"$"}
                          maskType="currency"
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>Market Rent</label>
                        <Field
                          name="marketRent"
                          component={MaskedField}
                          disabled={isShare}
                          type="text"
                          addonSize={"lg"}
                          addonType={"prepend"}
                          addonText={"$"}
                          maskType="currency"
                        />
                      </div>

                      <div className="form-group inputblock">
                        <label>Rehab Estimate</label>
                        <Field
                          name="rehabEstimate"
                          disabled={isShare}
                          component={MaskedField}
                          addonSize={"lg"}
                          addonType={"prepend"}
                          addonText={"$"}
                          maskType="currency"
                        />
                      </div>
                      <div className="form-group inputblock">
                        <label>After Repair Value</label>
                        <Field
                          name="afterRepairValue"
                          disabled={isShare}
                          component={MaskedField}
                          addonSize={"lg"}
                          addonType={"prepend"}
                          addonText={"$"}
                          maskType="currency"
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              {!isShare && (
                <div className="py-3 text-center">
                  <button
                    type="submit"
                    className="btn btn-primary text-uppercase px-5"
                    disabled={loading}
                  >
                    Update
                  </button>
                </div>
              )}
            </form>
          </div>
        )}
      </Formik>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  getBasicInfo: slug => getBasicInfo(slug, dispatch),
  updateBasicInfo: data => updateBasicInfo(data, dispatch)
});
const mapStateToProps = state => ({
  assetBasicInfo: state.assetBasicInfo
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Investment);
