import React, { Component } from "react";
import {
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormGroup
} from "reactstrap";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import renderDatePickerField from "../../../shared/components/form/DatePicker";

import CurrencyField from "../../../shared/components/form/AddonCurrencyField";
import Alert from "../../../shared/components/Alert";
import moment from "moment";

import { addFinancialToAsset } from "../../../actions/financial";
import { unmaskCurrency } from "../../../helpers";
import renderInputGroupBtnDropdown from "../../../shared/components/form/InputGroupBtnDropdown";
import CategorySelect from "../../../shared/components/form/CategorySelect";
import SlidingPane from "react-sliding-pane";

const initialValues = {
  category: "",
  frequency: "Monthly",
  amount: "",
  dueDate: moment(5, "DD").toDate(),
  dueDate2: ""
};

class AddFinancial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      saving: false,
      values: null
    };
  }

  checkAndSubmit = values => {
    values.amount = unmaskCurrency(values.amount);
    values.dueDates = [values.dueDate];
    if (values.frequency === "Half-Yearly")
      values.dueDates.push(values.dueDate2);
    delete values.dueDate;
    delete values.dueDate2;
    // save the financial directly
    this.setState({ saving: true, error: null, values }, () => {
      this.saveFinancial();
    });
  };

  saveFinancial = () => {
    const { assetSlug, addFinancialToAsset, type } = this.props;
    const { values } = this.state;
    values.slug = assetSlug;
    values.document = [];
    values.type = type;

    addFinancialToAsset(values);
  };

  closeModal = () => {
    const { toggleModal } = this.props;
    if (this.fomrikResetForm) this.fomrikResetForm(initialValues);
    this.setState({ saving: false, error: null }, () => {
      toggleModal({});
    });
  };

  handleReset = () => {
    this.closeModal();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if ((!this.state.saving, prevState.saving)) {
      this.closeModal();
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      financial: { updateFinancialSuccess, addingFinancialSuccess, error }
    } = nextProps;
    if (prevState.saving) {
      //  for checking saving actions
      if (updateFinancialSuccess === true || addingFinancialSuccess === true) {
        return { error: null, saving: false };
        // this.closeModal();
      } else if (
        updateFinancialSuccess === false ||
        addingFinancialSuccess === false
      ) {
        return { error, saving: false };
      }
    }
    return null;
  }

  render() {
    const validationSchema = Yup.object({
      category: Yup.string()
        .max(50, "Enter valid category")
        .required("Category is required"),
      frequency: Yup.string("").required("Frequency is required"),
      amount: Yup.string()
        .max(16, "Enter valid amount")
        .required("Amount is required"),
      dueDate: Yup.date().required("Due Date is required"),
      dueDate2: Yup.date()
        .typeError("Enter due date")
        .when("frequency", {
          is: fre => fre !== "Half-Yearly",
          then: Yup.date(),
          otherwise: Yup.date().required("Due Date is required")
        })
    });

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.checkAndSubmit}
      >
        {props => {
          const { handleSubmit, values, resetForm, setFieldValue } = props;
          const { modalOpen, type } = this.props;
          const { error, uploading, saving } = this.state;
          const loading = uploading || saving;
          this.fomrikResetForm = resetForm;
          return (
            <SlidingPane
              from="right"
              width="350px"
              isOpen={modalOpen}
              hideHeader={true}
              className="d-flex justify-content-center modal-content"
              toggle={this.closeModal}
            >
              <ModalHeader toggle={this.closeModal}>Add {type}</ModalHeader>
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  {error && (
                    <Alert color="danger" className="alert--bordered" icon>
                      <p>
                        <span className="bold-text">Error! </span>
                        {error}
                      </p>
                    </Alert>
                  )}

                  <FormGroup>
                    <label>Category:</label>
                    <CategorySelect
                      name="category"
                      value={values.category}
                      onUpdate={value => {
                        setFieldValue("category", value);
                      }}
                      categoryType={type}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Amount:</label>
                    <div className=" inputfilds-block">
                      <div className="input-group">
                        <Field
                          name="amount"
                          component={CurrencyField}
                          type="text"
                          placeholder="Amount"
                        />

                        <Field
                          name="frequency"
                          component={renderInputGroupBtnDropdown}
                          options={[
                            { value: "Yearly", label: "Yearly" },
                            { value: "Monthly", label: "Monthly" },
                            { value: "Half-Yearly", label: "Half-Yearly" }
                          ]}
                        />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label>Due Date:</label>
                    <Field
                      name="dueDate"
                      component={renderDatePickerField}
                      placeholder="Select Date"
                    />
                  </FormGroup>

                  {values.frequency === "Half-Yearly" && (
                    <FormGroup>
                      <label>Due Date:</label>
                      <Field
                        name="dueDate2"
                        component={renderDatePickerField}
                        placeholder="Select Date"
                      />
                    </FormGroup>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    className="btn btn-secondary px-5 d-inline-block mx-2"
                    onClick={this.closeModal}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    className="btn savebtns btn-primary px-5  d-inline-block mx-2"
                    disabled={loading}
                  >
                    Save{" "}
                  </Button>
                </ModalFooter>
              </form>
            </SlidingPane>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  financial: state.financial
});

const mapDispatchToProps = dispatch => ({
  addFinancialToAsset: data => addFinancialToAsset(data, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFinancial);
