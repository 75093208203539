/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Nav, NavItem, Button } from "reactstrap";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <Row>
        <Col sm="12" md={3}>
          <Nav className="justify-content-center justify-content-xl-start">
            <NavItem>
              <Button
                tag="a"
                color="facebook"
                className="px-3 mr-3"
                href="https://fb.com/bettercapital.us"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} className="font-size-lg" />
              </Button>
            </NavItem>
            <NavItem>
              <Button
                tag="a"
                color="twitter"
                className="px-3 mr-3"
                href="https://twitter.com/BetterCapitalUS"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} className="font-size-lg" />
              </Button>
            </NavItem>
          </Nav>
        </Col>
        <Col sm="12" md={6}>
          <div className="footernav text-center">
            <a
              href="https://bettercapital.us/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            <span> I</span>
            <a
              href="https://bettercapital.us/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Terms{" "}
            </a>{" "}
            <span> I</span>
            <a
              href="https://forms.bettercapital.us/feedback"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Feedback
            </a>
          </div>
        </Col>
        <Col sm="12" md={3}>
          <a
            title="Realtime application protection"
            href="https://www.sqreen.com/?utm_source=badge"
          >
            <img
              style={{ width: "109px", height: "36px" }}
              src="https://s3-eu-west-1.amazonaws.com/sqreen-assets/badges/20171107/sqreen-light-badge.svg"
              alt="Sqreen | Runtime Application Protection"
            />
          </a>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm="12" md={{ size: 6, offset: 5 }}>
          Copyright © 2020. BetterCapital.US
        </Col>
      </Row>
    </div>
  </footer>
);
export default Footer;
