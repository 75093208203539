import React, { Component } from "react";
import { Badge } from "reactstrap";
import { setContactToAdd } from "../../actions/auth/authAction";
import { getAllContacts } from "../../actions/contacts";
import { getUserRole } from "../../helpers/constants";
import { connect } from "react-redux";
import AddContact from "./AddContact";
import UpdateContact from "./UpdateContact";
import InviteContact from "./InviteContact";
import DeleteContact from "./DeleteContact";
import ContactAction from "./ContactAction";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import SectionHeader from "../../shared/components/SectionHeader";
import TableWithSearch from "../../shared/components/TableWithSearch";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      refresh: false,
      showAddContactModal: false,
      actionType: null,
      selectedContact: null,
      contacts: [],
      searchText: null,
      page: 1,
      sizePerPage: 10
    };
  }

  columnProps = [
    {
      dataField: "email",
      text: "Email",
      sort: true
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: true
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      formatter: (cell, row) => {
        return <Badge color={"success"}>{getUserRole(row.role)}</Badge>;
      }
    },
    {
      text: "Actions",
      dataField: "",
      editable: false,
      formatter: (cell, row) => {
        return (
          <ContactAction
            isUser={row.userId}
            onSelect={type => this.handleAction(type, row)}
          />
        );
      }
    }
  ];

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.props.getAllContacts();
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { allContacts } = nextProps.contactsProp;
    if (prevState.refresh) {
      return {
        loading: true,
        refresh: false
      };
    }
    if (prevState.loading && !isFetching(allContacts)) {
      if (isSuccess(allContacts)) {
        return {
          loading: false,
          contacts: allContacts.data.contacts
        };
      } else if (allContacts) {
        return {
          loading: false,
          error: allContacts.data && allContacts.data.error
        };
      }
    }
  }

  handleAction = (type, contact) => {
    this.setState({ actionType: type, selectedContact: contact }, () => {
      if (type === "message") {
        this.props.history.push(`/chats/${contact.user._id}`);
      }
    });
  };

  resetAction = () => {
    this.setState({ actionType: null, selectedContact: null });
  };

  refreshContacts = () => {
    this.setState(
      prevState => ({
        showAddContactModal: false,
        actionType: null,
        selectedContact: null,
        refresh: true
      }),
      () => {
        this.props.getAllContacts();
      }
    );
  };

  handleResetQueryParams = () => {};

  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText, data, cellEdit }
  ) => {
    this.setState({ page, sizePerPage });
  };

  render() {
    const { loading, contacts, page, sizePerPage } = this.state;

    return (
      <div className="p-5">
        <SectionHeader titleHeading="Contacts">
          <AddContact
            refreshContacts={this.refreshContacts}
            currentContacts={contacts}
          />
        </SectionHeader>
        <div className="d-inline-block float-right mb-4" />
        <InviteContact
          contact={this.state.selectedContact}
          modalOpen={this.state.actionType === "invite"}
          toggleModal={this.resetAction}
        />
        <UpdateContact
          contact={this.state.selectedContact}
          modalOpen={this.state.actionType === "edit"}
          toggleModal={this.resetAction}
          refreshContacts={this.refreshContacts}
        />
        <DeleteContact
          modalOpen={this.state.actionType === "delete"}
          toggleModal={this.resetAction}
          contact={this.state.selectedContact}
          refreshContacts={this.refreshContacts}
        />
        {loading ? (
          <LoadingIndicator />
        ) : (
          <TableWithSearch
            keyField="slug"
            data={contacts}
            totalSize={contacts.length}
            page={page}
            sizePerPage={sizePerPage}
            columns={this.columnProps}
            onTableChange={this.handleTableChange}
            resetQueryParams={this.handleResetQueryParams}
            enableCellEdit={false}
            remoteData={false}
            searchPlaceholder={"Search Contacts..."}
            noDataIndicator={"No contacts found!"}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  contactsProp: state.contacts
});

const mapDispatchToProps = dispatch => ({
  getAllContacts: () => getAllContacts(dispatch),
  setContactToAdd: contact => setContactToAdd(contact, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);
