import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { asset_list, resetAssetList } from "../../actions/asset/assetAction";
import TransactionsSidebar from "./TransactionsSidebar";
import TransactionListing from "./TransactionListing";

// import FileImport from "./FileImport";

const TransactionRoutes = () => (
  <Switch>
    <Route exact path="/transactions" component={TransactionListing} />
    <Route exact path="/transactions/pending" component={TransactionListing} />
    <Route
      exact
      path="/transactions/duplicate"
      component={TransactionListing}
    />
  </Switch>
);

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false
    };
  }

  componentDidMount = () => {
    this.props.asset_list();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { asset } = nextProps;

    if (prevState.loading) {
      if (asset.getAssetListSuccess === true) {
        return {
          assets: asset.data,
          loading: false
        };
      } else if (asset.getAssetListSuccess === false) {
        return {
          error: asset.error,
          loading: false
        };
      }
    }
  }

  render() {
    return (
      <main className="container-fluid p-5">
        <div className="row">
          <TransactionsSidebar {...this.props} />
          <div className="col-sm-12 col-md-8 col-lg-8 col-xl-10">
            <TransactionRoutes {...this.props} />
          </div>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    asset: state.assetList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    asset_list: data => asset_list(data, dispatch),
    resetAssetList: () => resetAssetList(dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transactions);
