import React, { Component, Fragment } from "react";
import { Card, CardBody, Button, Modal } from "reactstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import holderImage from "../../../img/property_placeholder.jpg";
import ScaledImage from "../../../shared/components/ScaledImage";
import { connect } from "react-redux";
import Slider from "react-slick";

import { getAssetMedias } from "../../../actions/asset/assetAction";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";

import AddMedia from "./AddMedia";
import DeleteMedia from "./DeleteMedia";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function SliderArrowNext(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
}

function SliderArrowPrev(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
}

class AssetMedias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddMediaModal: false,
      loadingMedia: false,
      showAsModal: false,
      showDeleteModal: false,
      fileIdToDelete: null,
      files: {}
    };
  }

  componentWillMount() {
    const { getAssetMedias, slug } = this.props;

    this.setState({ loadingMedia: true, error: null }, () => {
      getAssetMedias(slug);
    });
  }

  componentWillReceiveProps = nextProps => {
    const { assetMedia } = nextProps;
    if (assetMedia) {
      if (assetMedia.isAddMediasSuccess) {
        this.setState({
          loadingMedia: false,
          showAddMediaModal: false,
          files: assetMedia.files
        });
      } else if (assetMedia.isDeleteMediaSuccess) {
        this.setState({
          loadingMedia: false,
          showDeleteModal: false,
          files: assetMedia.files
        });
      } else if (assetMedia.isGetMediasSuccess) {
        this.setState({
          loadingMedia: false,
          files: assetMedia.files
        });
      }
    }
  };

  componentWillUnmount() {
    const { files } = this.state;
    // Make sure to revoke the data uris to avoid memory leaks
    Object.keys(files).map((fileId, idx) => {
      return URL.revokeObjectURL(files[fileId]);
    });
  }

  toggleShowAsModal = () => {
    this.setState(prevState => ({
      showAsModal: !prevState.showAsModal
    }));
  };

  toggleAddMediaModal = () => {
    this.setState(prevState => ({
      showAddMediaModal: !prevState.showAddMediaModal
    }));
  };

  hideShowModalAndShowAddModal = () => {
    this.setState({
      showAddMediaModal: true,
      showAsModal: false
    });
  };

  toggleDeleteModal = fileId => {
    let fileIdToDelete = fileId;
    if (this.state.showAddMediaModal) {
      fileIdToDelete = null;
    }
    this.setState(prevState => ({
      showDeleteModal: !prevState.showDeleteModal,
      fileIdToDelete: fileIdToDelete
    }));
  };

  renderImageSliderItem = (file, fileId, insideModal) => {
    const { readOnly } = this.props;

    if (insideModal) {
      return (
        <CardBody style={{ minHeight: "400px" }}>
          <div className="slider-item">
            {!readOnly && (
              <Button
                tag="a"
                href="#/"
                onClick={e => this.toggleDeleteModal(fileId)}
                color="danger"
                className="ribbon-vertical ribbon-vertical--danger ribbon-vertical--right"
              >
                <span>
                  <FontAwesomeIcon icon={faTrash} className="font-size-lg" />
                </span>
              </Button>
            )}
            <img src={file} alt="" width={"760px"} height={"400px"} />
          </div>
        </CardBody>
      );
    } else {
      return (
        <CardBody>
          <div className="slider-item">
            <a href="#/" onClick={this.toggleShowAsModal}>
              <ScaledImage
                alt=""
                scaled={true}
                width={550}
                height={300}
                src={file}
              />
            </a>
          </div>
        </CardBody>
      );
    }
  };

  renderImageSlider = (files, insideModal) => {
    const { readOnly } = this.props;
    return (
      <Slider
        className="rounded-lg overflow-hidden mb-5 mb-lg-0 slider-dots-light"
        slidesToShow={1}
        slidesToScroll={1}
        nextArrow={<SliderArrowNext />}
        prevArrow={<SliderArrowPrev />}
      >
        {_.isEmpty(files) && readOnly ? (
          <div className="mb-2">
            <img src={holderImage} alt="" />
          </div>
        ) : (
          Object.keys(files).map((fileId, idx) => {
            return (
              <div key={`asset-media-${idx}`} className="mb-2">
                {this.renderImageSliderItem(files[fileId], fileId, insideModal)}
              </div>
            );
          })
        )}
        {!insideModal && !readOnly && (
          <div>
            <CardBody>
              <a
                href="#/"
                onClick={this.toggleAddMediaModal}
                className="card btn rounded text-left p-5"
              >
                <div className="d-flex align-items-center">
                  <div className="d-70 rounded-circle bg-success text-white mr-3 text-center">
                    <FontAwesomeIcon icon={faPlus} className="font-size-lg" />
                  </div>
                  <div className="flex-grow-1">
                    <div className="text-success pb-1">Add Media</div>
                    <div className="text-black-50 ">Photo/Video</div>
                  </div>
                </div>
              </a>
              <AddMedia
                toggleModal={this.toggleAddMediaModal}
                modalOpen={this.state.showAddMediaModal}
                assetSlug={this.props.slug}
              />
            </CardBody>
          </div>
        )}
      </Slider>
    );
  };

  render() {
    const { loadingMedia, files } = this.state;
    const { readOnly } = this.props;
    if (loadingMedia) {
      return <LoadingIndicator loading={loadingMedia} />;
    }

    if (this.state.showAsModal) {
      return (
        <Fragment>
          {this.renderImageSlider(files, false)}
          <Modal
            zIndex={2000}
            centered
            size="lg"
            isOpen={this.state.showAsModal}
            toggle={this.toggleShowAsModal}
          >
            <Card className="card-box d-block">
              <div className="card-header bg-light">
                <div className="card-header--title">
                  <h4>Photos</h4>
                </div>
                {!readOnly && (
                  <div className="card-header--actions">
                    <Button
                      color="primary"
                      onClick={this.hideShowModalAndShowAddModal}
                    >
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={faPlus} />
                      </span>
                      <span className="btn-wrapper--label">Add Photo</span>
                    </Button>
                  </div>
                )}
              </div>
              {this.renderImageSlider(files, true)}
            </Card>
            {!readOnly && (
              <DeleteMedia
                toggleModal={this.toggleDeleteModal}
                modalOpen={this.state.showDeleteModal}
                assetSlug={this.props.slug}
                fileId={this.state.fileIdToDelete}
              />
            )}
          </Modal>
        </Fragment>
      );
    }
    return this.renderImageSlider(files, false);
  }
}

const mapStateToProps = state => ({
  assetMedia: state.assetMedia
});

const mapDispatchToProps = dispatch => {
  return {
    getAssetMedias: slug => getAssetMedias(slug, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetMedias);
