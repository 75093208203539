/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import AssetSummary from "./AssetSummary";
import AssetFilter from "./AssetFilter";
import SectionHeader from "../../shared/components/SectionHeader";

class AssetListingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: props.assets,
      showAssetLookup: false,
      currentFilter: "active"
    };
  }

  handleClick = a => {
    this.props.history.push(`/assets/${a.slug}`);
  };

  handleSearch = ({ target }) => {
    this.props.searchAsset(target.value);
  };

  changeFilter = filter => {
    this.setState({ currentFilter: filter }, () => {});
  };
  render() {
    let assets = [];
    const { currentType } = this.props;
    if (currentType) {
      assets = this.props.assets.filter(a => a.assetType === currentType);
    } else {
      assets = this.props.assets;
    }
    return (
      <>
        <SectionHeader titleHeading="Assets" innerSection={true}>
          <div className="d-flex flex-row justify-content-end dash-filter">
            <AssetFilter
              handleSearch={this.handleSearch}
              changeFilter={this.changeFilter}
              currentFilter={this.state.currentFilter}
            />
          </div>
        </SectionHeader>
        <AssetSummary
          assets={assets}
          toggleAssetLookup={this.props.toggleAssetLookup}
          viewAsset={this.handleClick}
          currentAssetType={currentType}
        />{" "}
      </>
    );
  }
}

export default AssetListingTable;
