import React from "react";
import { connect } from "react-redux";

import FullCalender from "../../shared/components/FullCalender";
import AddReminder from "./AddReminder";
import ViewReminder from "./ViewReminder";
import SectionHeader from "../../shared/components/SectionHeader";

import { getRemindersList } from "../../actions/reminder";
class Reminders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReminderModal: false,
      viewReminderModal: false,

      selectedData: null,

      toViewEvent: null
    };
  }

  componentDidMount() {
    this.props.getReminders();
  }

  toggleModal = data => {
    this.setState(prevState => ({
      showReminderModal: !prevState.showReminderModal,
      selectedData: data
    }));
  };

  toggleViewReminderModal = event => {
    this.setState(prevState => ({
      viewReminderModal: !prevState.viewReminderModal,
      toViewEvent: event
    }));
  };

  render() {
    const { reminders } = this.props.reminders;
    return (
      <div className="p-5">
        <SectionHeader titleHeading="Reminders">
          <button
            onClick={() => this.toggleModal(null)}
            className="btn btn-primary px-4"
          >
            <i className="fas fa-bell" /> Create Reminder
          </button>
        </SectionHeader>
        <div className="mb-4">
          <FullCalender
            events={reminders}
            handleDateClick={this.toggleModal}
            handleEventClick={this.toggleViewReminderModal}
          />
        </div>
        <AddReminder
          modalOpen={this.state.showReminderModal}
          toggleModal={this.toggleModal}
          selectedData={this.state.selectedData}
        />
        <ViewReminder
          modalOpen={this.state.viewReminderModal}
          toggleModal={this.toggleViewReminderModal}
          selectedData={this.state.toViewEvent}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  reminders: state.reminders
});
const mapDispatchToProps = dispatch => ({
  getReminders: () => getRemindersList(dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reminders);
