import { 
    GET_POLICY_CONTENT,
    SAVE_FEEDBACK,

    // GET_RECOMMENDATIONS,
    // GET_EVENTS,
    // GET_VIDEOS,
 } from "../actionTypes";

export const getCMSContent = dispatch => {
    dispatch({
        type: GET_POLICY_CONTENT
    })
}
export const saveFeedback = ( feedback, dispatch ) => {
    dispatch({
        type: SAVE_FEEDBACK,
        payload: feedback
    })
}

/* export const getRecommendations = ( query, dispatch ) => {
    dispatch({
        type: GET_RECOMMENDATIONS,
        payload: query,
    })
}

export const getEvents = ( query, dispatch ) => {
    dispatch({
        type: GET_EVENTS,
        payload: query
    })
}

export const getVideos = ( query, dispatch ) => {
    dispatch({
        type: GET_VIDEOS,
        payload: query,
    })
} */


