import React, { Component } from "react";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import { getIn } from "formik";

class FilterSelect extends Component {
  onChange = option => {
    const {
      field,
      form: { setFieldValue }
    } = this.props;
    setFieldValue(field.name, option && option.value);
    if (this.props.onChange) this.props.onChange(option.value);
  };

  render() {
    const {
      field,
      form: { /*touched, errors, setFieldValue,*/ values }
    } = this.props;
    let { options } = this.props;

    // const touch = getIn(touched, field.name);
    // const error = getIn(errors, field.name);
    const value = getIn(values, field.name);
    let defaultOption;
    if (options && options.length > 0) {
      defaultOption = options.find(option => {
        return option.value === value;
      });
    }

    return (
      <CreatableSelect
        isSearchable
        name="filter"
        placeholder="Select Filter"
        options={options}
        value={defaultOption}
        theme={theme => ({
          ...theme,
          borderRadius: "0.29rem",
          borderWidth: 1,
          colors: {
            ...theme.colors,
            primary25: "rgba(60,68,177,0.15)",
            primary50: "rgba(60,68,177,0.15)",
            primary: "#3c44b1"
          }
        })}
        onChange={this.onChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  userDetail: state.userDetail
});

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterSelect);
