import React, { Component } from "react";
import { Badge } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  markAllPaid,
  removeAllDuplicates,
  getAllTransactions,
  updateTransaction
} from "../../actions/transaction/";

import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import TableWithSearch from "../../shared/components/TableWithSearch";
import { getLocalDate, formatCurrency } from "../../helpers";
import TransactionAction from "../AssetDetails/Transaction/TransactionAction";
import { categoryOptions } from "../../helpers/constants";
import { Type } from "react-bootstrap-table2-editor";
import queryString from "query-string";
import { getCategoryValueFromLabel } from "../../helpers/constants";
import _ from "lodash";

import UpdateTransaction from "../../components/AssetDetails/Transaction/UpdateTransaction";
import DeleteTransaction from "../../components/AssetDetails/Transaction/DeleteTransaction";
import DuplicateTransaction from "../../components/AssetDetails/Transaction/DuplicateTransaction";
import CategorySelect from "../../shared/components/form/CategorySelect";
import CustomFilter from "../../shared/components/CustomFilter";

class TransactionListing extends Component {
  columnProps = [
    {
      dataField: "category",
      text: "Category",
      sort: true,
      editable: true,
      formatter: (cell, row) => {
        let matchedOption = categoryOptions
          .flatMap(cat => cat.options)
          .find(option => option.value === cell.toLowerCase());
        return matchedOption ? matchedOption.label : cell;
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => <CategorySelect {...editorProps} value={value} />
    },
    {
      dataField: "payee",
      text: "Payee",
      sort: true,
      editable: true
    },
    {
      dataField: "name",
      text: "Description",
      sort: true,
      editable: true
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: (cell, row) => {
        if (row.amount) {
          return formatCurrency.format(row.amount);
        }
      }
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      editor: {
        type: Type.DATE
      },
      formatter: (cell, row) => {
        if (row.date) {
          return getLocalDate(row.date);
        }
      }
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      editable: true,
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: "INCOME",
            label: "INCOME"
          },
          {
            value: "EXPENSE",
            label: "EXPENSE"
          }
        ]
      },
      formatter: (cell, row) => {
        return (
          <Badge color={row.type === "INCOME" ? "success" : "danger"}>
            {row.type}
          </Badge>
        );
      }
    },
    {
      text: "Asset",
      dataField: "asset",
      editable: false,

      formatter: (cell, row) => {
        return (
          <Link to={`/assets/${row.asset.slug}/ledger`}>
            {row.asset.asset_name}
          </Link>
        );
      }
    },
    {
      text: "Actions",
      dataField: "",
      editable: false,

      formatter: (cell, row) => {
        return (
          <TransactionAction
            onSelect={type => this.handleAction(type, row)}
            isReview={row.isPending || row.duplicate === "T"}
          />
        );
      }
    }
  ];

  handleAction = (type, row) => {
    this.setState({ actionType: type, selectedRow: row }, () => {});
  };

  closeModal = () => {
    this.setState({ selectedRow: null, actionType: null });
  };

  constructor(props) {
    super(props);
    const urlQueryParams = queryString.parse(this.props.location.search);
    let searchText;
    let typeFilter = "current";

    let queryParams = new URLSearchParams(
      "offset=0&limit=10&sortBy=date&orderBy=desc"
    );
    if (!_.isEmpty(urlQueryParams)) {
      if (urlQueryParams.search) {
        searchText = getCategoryValueFromLabel(urlQueryParams.search);
        queryParams.set("search", searchText);
      }
      if (urlQueryParams.type) {
        typeFilter = urlQueryParams.type;
        queryParams.set("type", typeFilter);
      }
    }

    if (props.currentFilter) {
      queryParams.set(
        "from",
        props.currentFilter.fromDate.format("MM-DD-YYYY")
      );
      queryParams.set("to", props.currentFilter.toDate.format("MM-DD-YYYY"));
    }
    this.state = {
      loading: true,
      error: null,
      transactions: [],
      page: 1,
      sizePerPage: 10,
      searchText: searchText,
      currentFilter: props.currentFilter,
      typeFilter: typeFilter,
      filterChanged: false,
      selectedRow: null,
      actionType: null,
      queryParams: queryParams.toString()
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true }, () => {
      this.queryTransactions();
    });
  };

  dateFilterChange = filter => {
    let queryParams = new URLSearchParams(this.state.queryParams);
    if (filter) {
      queryParams.set("from", filter.fromDate.format("MM-DD-YYYY"));
      queryParams.set("to", filter.toDate.format("MM-DD-YYYY"));
    }
    this.setState(
      {
        currentFilter: filter,
        filterChanged: true,
        queryParams: queryParams.toString()
      },
      () => {
        this.queryTransactions();
      }
    );
  };

  typeFilterChange = typeFilter => {
    let queryParams = new URLSearchParams(this.state.queryParams);
    if (typeFilter) {
      queryParams.set("type", typeFilter);
    }
    this.setState(
      {
        filterChanged: true,
        typeFilter,
        queryParams: queryParams.toString()
      },
      () => {
        this.queryTransactions();
      }
    );
  };

  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText, data, cellEdit }
  ) => {
    if (cellEdit) {
      const { rowId, dataField, newValue } = cellEdit;

      if (rowId && newValue) {
        // if there was a edit operation, we need to call update transactions
        let rowData = data.find(row => row._id === rowId);
        rowData[dataField] = newValue;
        this.setState(
          {
            updating: true
          },
          () => {
            if (rowData.asset)
              this.props.updateTransaction({
                slug: rowData.asset.slug,
                transaction: rowData,
                query: this.state.queryParams
              });
          }
        );
      }
    } else {
      let queryParams = new URLSearchParams(this.state.queryParams);
      let currentSearchText = this.state.searchText;
      if (searchText) currentSearchText = searchText;
      queryParams.set("offset", page - 1);
      if (sortField) queryParams.set("sortBy", sortField);
      if (sortOrder) queryParams.set("orderBy", sortOrder);
      if (currentSearchText) queryParams.set("search", currentSearchText);
      this.setState(
        {
          loading: true,
          page: page,
          sizePerPage: sizePerPage,
          queryParams: queryParams.toString()
        },
        () => {
          this.queryTransactions();
        }
      );
    }
  };

  handleResetQueryParams = () => {
    let queryParams = new URLSearchParams(this.state.queryParams);
    queryParams.delete("search");
    this.setState(
      { searchText: null, queryParams: queryParams.toString() },
      () => {
        this.queryTransactions();
      }
    );
  };

  queryTransactions = () => {
    this.props.getAllTransactions({ query: this.state.queryParams.toString() });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      transactions: { transactions }
    } = nextProps;

    if (prevState.filterChanged) {
      return {
        loading: true,
        filterChanged: false
      };
    }

    if (!prevState.loading && isFetching(transactions)) {
      return {
        loading: true
      };
    }
    if (prevState.loading && !isFetching(transactions)) {
      if (isSuccess(transactions) && transactions.type === "GET_ALL") {
        return {
          loading: false,
          transactions: transactions.data.transactions,
          totalCount: transactions.data.totalCount
        };
      }
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const newTypeFilter =
      queryString.parse(this.props.location.search).type || "current";
    const { typeFilter } = this.state;
    if (typeFilter !== newTypeFilter) {
      this.typeFilterChange(newTypeFilter);
    }
  };

  render() {
    const { transactions, totalCount, currentFilter } = this.state;
    if (!transactions) return <div />;
    const { actionType, selectedRow, queryParams } = this.state;
    return (
      <div>
        <div className="inner_pagetitle mb-4">
          <h4>
            <span>Ledger</span>
          </h4>
          <div className={"d-flex"}>
            <CustomFilter
              onChange={this.dateFilterChange}
              currentFilter={currentFilter}
            />
          </div>
        </div>

        {selectedRow && (
          <DuplicateTransaction
            transaction={selectedRow}
            assetSlug={selectedRow.asset.slug}
            asset={selectedRow.asset}
            showModal={actionType === "duplicate"}
            closeModal={this.closeModal}
            queryParams={queryParams}
          />
        )}
        {selectedRow && (
          <DeleteTransaction
            transaction={selectedRow}
            assetSlug={selectedRow.asset.slug}
            showModal={actionType === "delete"}
            closeModal={this.closeModal}
            queryParams={queryParams}
          />
        )}
        {selectedRow && (
          <UpdateTransaction
            transaction={selectedRow}
            assetSlug={selectedRow.asset.slug}
            asset={selectedRow.asset}
            showModal={actionType === "edit"}
            closeModal={this.closeModal}
            queryParams={queryParams}
            {...this.props}
          />
        )}
        <TableWithSearch
          keyField="_id"
          data={transactions}
          totalSize={totalCount}
          page={this.state.page}
          sizePerPage={this.state.sizePerPage}
          columns={this.columnProps}
          onTableChange={this.handleTableChange}
          resetQueryParams={this.handleResetQueryParams}
          enableCellEdit={true}
          location={this.props.location}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  transactions: state.transactions,
  currentFilter: state.filter.currentFilter
});

const mapDispatchToProps = dispatch => ({
  markAllPaid: data => markAllPaid(data, dispatch),
  removeAllDuplicates: data => removeAllDuplicates(data, dispatch),
  getAllTransactions: data => getAllTransactions(data, dispatch),
  updateTransaction: data => updateTransaction(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionListing);
