/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAssetFinancials } from "../../../actions/financial";
import { Table, Progress } from "reactstrap";

import AddFund from "../Fund/AddFund";

class FundListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: "",
      showAddFinancial: false
    };
  }

  componentWillMount() {
    const { getAssetFinancials, assetSlug } = this.props;

    getAssetFinancials(assetSlug);
  }

  getOtherIncomeFinancials = () => {
    const {
      financial: { financials }
    } = this.props;
    return financials.filter(
      f => f.type === "INCOME" && !["DEPOSIT", "RENT"].includes(f.category)
    );
  };

  toggleAddFinancial = () => {
    this.setState(nextState => ({
      showAddFinancial: !nextState.showAddFinancial
    }));
  };

  render() {
    return (
      <div className="px-3 py-4">
        <div className="border-0 text-right">
          <AddFund />
        </div>

        <Table hover borderless className="text-nowrap mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th className="text-left">Type</th>
              <th className="text-left">Name</th>
              <th className="text-left">Target Return</th>
              <th className="text-left">Capital Allocated</th>
              <th className="text-left">Progress</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>12</td>
              <td>Commerical Debt</td>
              <td>
                <div className="d-flex align-items-center">
                  <div>
                    <a
                      href="#/"
                      onClick={e => e.preventDefault()}
                      className="font-weight-bold text-black"
                      title="..."
                    >
                      Upper West Side Multi-Family Apartment
                    </a>
                    <span className="text-black-50 d-block">
                      Manhattan, New York
                    </span>
                  </div>
                </div>
              </td>
              <td>8%</td>
              <td>$1,000</td>

              {/*
              <td className="text-center">
                <Badge color="neutral-warning" className="text-warning px-4">
                  Pending
                </Badge>
              </td>*/}
              <td>
                <Progress
                  value="55"
                  className="progress-bar-rounded"
                  color="info"
                >
                  55%
                </Progress>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  financial: state.financial,
  assetBasicInfo: state.assetBasicInfo
});

const mapDispatchToProps = dispatch => {
  return {
    getAssetFinancials: slug => getAssetFinancials(slug, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundListing);
