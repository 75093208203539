import React, { PureComponent } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { Link } from "react-router-dom";
import GoogleLogin from "react-google-login";
import FormField from "../../shared/components/form/FormField";
import logo from "../../assets/images/logo.png";

class LogInForm extends PureComponent {
  handleSubmit = (values, { props = this.props, setSubmitting }) => {
    if (this.state.forgotPassword) {
      this.props.onResetPassword(values);
    } else {
      this.props.onSubmit(values);
    }
    setSubmitting(false);
  };

  googleResponse = response => {
    this.props.onSocialLogin(response);
  };

  onFailure = error => {};

  constructor() {
    super();
    this.state = {
      showPassword: false,
      forgotPassword: false
    };
  }

  showPassword = e => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  toggleForgetPassword = e => {
    e.preventDefault();
    this.props.onReset();
    this.setState({
      forgotPassword: !this.state.forgotPassword
    });
  };

  render() {
    const initialValues = { email: "", password: "" };
    let validationSchema;
    if (this.state.forgotPassword) {
      validationSchema = Yup.object({
        email: Yup.string()
          .email("Enter a valid email")
          .required("Enter your email")
      });
    } else {
      validationSchema = Yup.object({
        email: Yup.string()
          .email("Enter a valid email")
          .required("Enter your email"),
        password: Yup.string()
          .max(20, "Max length should be less than 20")
          .required("Enter your password")
      });
    }
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {props => {
          const { handleSubmit } = props;

          return (
            <div className="bg-white rounded my-4">
              <div className="text-center my-4">
                <img src={logo} width="250px" alt="BetterCapital.US" />
                <p className="font-size-lg text-center my-3 text-black-50">
                  Built for Landlords, by landlords.
                </p>
              </div>
              <h1 className="display-4 text-center mb-3 font-weight-bold">
                {this.state.forgotPassword ? "Forgot Password" : "Sign in"}
              </h1>

              <form className="form" onSubmit={handleSubmit}>
                <div className="form-group col-12 inputblock">
                  <Field
                    name="email"
                    component={FormField}
                    type="email"
                    placeholder="Email"
                  />
                </div>
                {!this.state.forgotPassword && (
                  <div className="form-group col-12 inputblock">
                    <Field
                      name="password"
                      component={FormField}
                      type={this.state.showPassword ? "text" : "password"}
                      placeholder="Password"
                    />
                    <div className="forgot-password">
                      <a href="/" onClick={e => this.toggleForgetPassword(e)}>
                        Forgot a password?
                      </a>
                    </div>
                  </div>
                )}
                <div className="col-12 text-center my-4">
                  <button
                    className="col-10 btn btn-primary px-5"
                    disabled={this.props.loading}
                    type="submit"
                  >
                    {this.props.loading
                      ? "Please wait..."
                      : this.state.forgotPassword
                      ? "Reset Password"
                      : "Sign in"}
                  </button>

                  {this.state.forgotPassword && (
                    <button
                      className="col-10 btn btns_remove px-5"
                      onClick={e => this.toggleForgetPassword(e)}
                      type="cancel"
                      disabled={this.props.loading}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </form>
              <div className="text-center my-3">- or -</div>

              <div className="account-or text-center">
                <div className="my-3 text-center">
                  <GoogleLogin
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Sign in with Google"
                    theme="dark"
                    onSuccess={this.googleResponse}
                    onFailure={this.onFailure}
                  />
                </div>
              </div>
              <div className="account-signup text-lg-center font-size-lg ">
                Don't have an account? <Link to="/signup">Sign up</Link>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default LogInForm;
