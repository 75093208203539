import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getUserDetail,
  updateUser,
  updatePassword,
  updateInvestorProfile
} from "../../actions/auth/authAction";
import { uploadFile } from "../../actions/resource";

import clsx from "clsx";
import AccountInfo from "./AccountInfo";
import InvestorInfoSetting from "./InvestorInfo";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import { Card, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

class Settings extends Component {
  state = {
    activeTab: "1",
    loading: true,
    userInfo: {}
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  componentDidMount() {
    const { getUserDetail } = this.props;
    getUserDetail();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.loading) {
      const {
        userDetail: { userInfo, getUserDetailSuccess }
      } = nextProps;

      if (getUserDetailSuccess && getUserDetailSuccess === true) {
        return {
          loading: false,
          userInfo: userInfo
        };
      } else if (getUserDetailSuccess && getUserDetailSuccess === false) {
        return {
          loading: false,
          userInfo: {}
        };
      }
    }
    return null;
  }

  handleSubmit = newData => {
    const { updateUser } = this.props;
    updateUser(newData);
  };
  updateInvestorProfile = newData => {
    const { updateInvestorProfile } = this.props;
    updateInvestorProfile(newData);
  };

  render() {
    const { activeTab, loading } = this.state;

    if (loading) {
      return <LoadingIndicator />;
    }

    const { userDetail } = this.props;
    let investorProfileInfo = userDetail.userInfo.investmentProfileInfo;

    return (
      <div className=" p-5">
        <div className="inner_pagetitle mx-2 mb-4">
          <h4 className="d-inline-block">Profile</h4>
        </div>
        <Card className="card-box-alt border-first">
          <div className="nav-tabs-first tabs-animated tabs-bordered tabs-animated-shadow">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={clsx({ active: activeTab === "1" })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  <span>Settings</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={clsx({ active: activeTab === "2" })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  <span>Preferences</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={activeTab} className="p-2">
            <TabPane tabId="1">
              <AccountInfo submit={this.handleSubmit} {...this.props} />
            </TabPane>
            <TabPane tabId="2">
              <InvestorInfoSetting
                investorProfileInfo={investorProfileInfo}
                userDetail={userDetail}
                updateInvestorProfile={this.updateInvestorProfile}
              />
            </TabPane>
          </TabContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userDetail: state.userDetail,
  uploadProp: state.upload
});

const mapDispatchToProps = dispatch => ({
  getUserDetail: () => {
    getUserDetail(dispatch);
  },
  updateUser: userData => {
    updateUser(userData, dispatch);
  },
  updatePassword: userData => {
    updatePassword(userData, dispatch);
  },
  updateInvestorProfile: userData => {
    updateInvestorProfile(userData, dispatch);
  },
  uploadFile: data => uploadFile(data, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
