// import isEmpty from '../validation/is-empty';
import {
} from '../actions/auth/authActionTypes';
import {
    GET_POLICY_CONTENT_SUCCESS,
    GET_POLICY_CONTENT_FAILURE,

    SAVE_FEEDBACK_SUCCESS,
    SAVE_FEEDBACK__FAILURE,

    // GET_RECOMMENDATIONS_SUCCESS,
    // GET_RECOMMENDATIONS_FAILURE,

    // GET_EVENTS_SUCCESS,
    // GET_EVENTS_FAILURE,

    // GET_VIDEOS_SUCCESS,
    // GET_VIDEOS_FAILURE,
} from '../actions/actionTypes';
const initialState = {
    // recommendations:[],
    // events:[],
    // videos:[],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_POLICY_CONTENT_SUCCESS:
            return {
                loading: false,
                cmsContentSuccess: true,
                cmsContent: action.payload.data
            }
        case GET_POLICY_CONTENT_FAILURE:
            return {
                loading: false,
                cmsContentSuccess: false,
                errorMessage: action.payload.message
            }
        case SAVE_FEEDBACK_SUCCESS:
            return {
                saveFeedbackSuccess: true,
                message: action.payload.message
            }
        case SAVE_FEEDBACK__FAILURE:
            return {
                saveFeedbackSuccess: false,
                errorMessage: action.payload.message
            }


        /* case GET_RECOMMENDATIONS_SUCCESS:
            return {
                getRecommendationsSuccess: true,
                recommendations: action.payload.resources,
            }
        case GET_RECOMMENDATIONS_FAILURE:
            return {
                getRecommendationsSuccess: false,
                recommendations: state.recommendations,
            }
        case GET_EVENTS_SUCCESS:
            return {
                getEventsSuccess: true,
                events: action.payload.resources,
            }
        case GET_EVENTS_FAILURE:
            return {
                getEventsSuccess: false,
                events: state.events,
            }
        case GET_VIDEOS_SUCCESS:
            return {
                getVideosSuccess: true,
                events: action.payload.resources,
            }
        case GET_VIDEOS_FAILURE:
            return {
                getVideosSuccess: false,
                events: state.videos,
            } */
        default:
            return state;
    }
}