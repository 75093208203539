/* eslint-disable no-param-reassign */
import React, { PureComponent } from "react";
import { dateFilters } from "../../../helpers/constants";
import DatePicker from "../form/DatePicker";
import EntitySelect from "../form/EntitySelect";
import FilterSelect from "../form/FilterSelect";

import {
  saveFilter,
  getCurrentFilter,
  setCurrentFilter
} from "../../../actions/auth/authAction";

import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

import _ from "lodash";

import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";

import { Filter, Save } from "react-feather";

class CustomFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addFilter: false,
      adding: false,
      newFilterName: "",
      filterOpen: false,
      showAddFilter: false,
      triggerOnChange: false,
      filters: [],
      fetching: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.props.getCurrentFilter();
    });
  }

  handleChangeFilter = values => {
    if (values.submitType === "apply") {
      this.setState({ selectedFilter: values, filterOpen: false }, () => {
        this.props.setCurrentFilter(values);
        this.props.onChange(values);
      });
    } else {
      // We need to save that new filter and then call onChange...
      this.setState(
        {
          addFilter: true,
          newFilterName: values.filter
        },
        () => {
          this.props.setCurrentFilter(values);
          // this.props.onChange(values);
          this.props.saveFilter(values);
        }
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { triggerOnChange, selectedFilter } = this.state;
    if (triggerOnChange && !prevState.triggerOnChange) {
      // incase of newly added filters we need to call the onChange
      // after save.
      this.setState({ triggerOnChange: false, filterOpen: false }, () => {
        this.props.onChange(selectedFilter);
      });
    }
  };

  toggleFilter = () => {
    this.setState({ filterOpen: !this.state.filterOpen, showAddFilter: false });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading, adding, addFilter } = prevState;
    const { userDetail, dashboardFilter } = nextProps;

    if (addFilter) {
      return {
        adding: true,
        addFilter: false
      };
    }
    if (loading && !_.isEmpty(userDetail)) {
      if (userDetail.getUserDetailSuccess) {
        let userFilters = userDetail.userInfo.filters;
        let filters = dateFilters.concat(userFilters);
        return {
          loading: false,
          filters: filters
        };
      }
    } else if (adding && !isFetching(dashboardFilter)) {
      if (isSuccess(dashboardFilter)) {
        let userFilters = dashboardFilter.data.filters;
        let filters = dateFilters.concat(userFilters);
        return {
          adding: false,
          newFilterName: "",
          filters: filters,
          triggerOnChange: true
        };
      }
    }
    return null;
  }

  getFilter = filter => {
    return this.state.filters.find(f => f._id === filter);
  };

  render() {
    const { loading, filters } = this.state;
    const { currentFilter } = this.props;
    if (loading) return <div />;

    let filterOptions = filters.map(filter => {
      return {
        label: filter.name,
        value: filter._id
      };
    });

    let initialValues = {
      filter: currentFilter._id || currentFilter.filter,
      name: currentFilter.name,
      fromDate: currentFilter.fromDate,
      toDate: currentFilter.toDate,
      entity: currentFilter.entity || "All",
      status: currentFilter.status || "active"
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.handleChangeFilter}
      >
        {props => {
          return (
            <form onSubmit={props.handleSubmit}>
              <Dropdown
                isOpen={this.state.filterOpen}
                toggle={this.toggleFilter}
              >
                <DropdownToggle color="outline-primary" caret>
                  <span className="btn-wrapper--icon">
                    <Filter />
                  </span>
                  <span className="btn-wrapper--label">
                    {currentFilter && currentFilter.name}
                  </span>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-xl p-2">
                  <div className="cell p-3">
                    <small className="font-weight-bold pb-2 text-uppercase text-primary">
                      Filter
                    </small>
                    <Field
                      name="filter"
                      component={FilterSelect}
                      options={filterOptions}
                      onChange={value => {
                        let filter = this.getFilter(value);
                        if (filter) {
                          props.setFieldValue("fromDate", filter.fromDate);
                          props.setFieldValue("toDate", filter.toDate);
                          props.setFieldValue("name", filter.name);
                        }
                      }}
                    />
                  </div>
                  <div className="cell p-3">
                    <small className="font-weight-bold pb-2 text-uppercase text-primary">
                      Entity
                    </small>
                    <Field
                      name="entity"
                      component={EntitySelect}
                      readOnly={true}
                      allOption={true}
                    />
                  </div>
                  <div className="row p-3">
                    <div className="cell px-3">
                      <small className="font-weight-bold pb-2 text-uppercase text-primary">
                        From Date
                      </small>
                      <Field
                        name="fromDate"
                        component={DatePicker}
                        onChange={value => {
                          props.setFieldValue("filter", "custom");
                          props.setFieldValue("name", "Custom");
                        }}
                      />
                    </div>
                    <div className="cell px-3">
                      <small className="font-weight-bold pb-2 text-uppercase text-primary">
                        To Date
                      </small>
                      <Field
                        name="toDate"
                        component={DatePicker}
                        onChange={value => {
                          props.setFieldValue("filter", "custom");
                          props.setFieldValue("name", "Custom");
                        }}
                      />
                    </div>
                  </div>
                  <div className="divider" />
                  <div className="p-3">
                    <Row>
                      <Col md="6">
                        <Nav className="nav-neutral-second flex-column p-0">
                          <NavItem className="mx-auto">
                            <NavLink
                              className="d-flex rounded-sm justify-content-center"
                              tag="button"
                              type="submit"
                              onClick={() => {
                                props.setFieldValue("submitType", "save");
                              }}
                            >
                              <div className="nav-link-icon">
                                <Save />
                              </div>
                              <span>Save</span>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                      <Col md="6">
                        <Nav className="nav-neutral-primary flex-column p-0">
                          <NavItem className="mx-auto">
                            <NavLink
                              className="d-flex rounded-sm justify-content-center"
                              tag="button"
                              onClick={() => {
                                props.setFieldValue("submitType", "apply");
                              }}
                              type="submit"
                            >
                              <div className="nav-link-icon">
                                <Filter />
                              </div>
                              <span>Apply</span>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  userDetail: state.userDetail,
  dashboardFilter: state.dashboardFilter
});

const mapDispatchToProps = dispatch => {
  return {
    saveFilter: data => saveFilter(data, dispatch),
    getCurrentFilter: data => getCurrentFilter(data, dispatch),
    setCurrentFilter: data => setCurrentFilter(data, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFilter);
