import React, { Component } from "react";
import { Route, Switch, Link } from "react-router-dom";
import { connect } from "react-redux";

import AssetOverview from "./Overview/";
import Documents from "./Documents";
import Transaction from "./Transaction";
import Contacts from "./Contacts";
import Financials from "./Financials";
import Reports from "./Reports";
import Investment from "./Investment";

import DeleteAsset from "./Financials/DeleteAsset";
import CloseAsset from "./CloseAsset";

import _ from "lodash";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import SectionHeader from "../../shared/components/SectionHeader";

import { Formik, Field } from "formik";
import DateRangeFilter from "../../shared/components/DateRangeFilter";
import AssetKPIs from "./Overview/AssetKPIs";

import {
  Card,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import { NavLink as NavLinkStrap } from "reactstrap";

import { Settings, Trash, Check } from "react-feather";

// import Notification from './Notification';
import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import { capAllWords, getAssetTypeLabel } from "../../helpers";
import { getKPIsFromActuals } from "../../helpers/assetCalculator";

import {
  getBasicInfo,
  getCashFlowGraph,
  cleanup
} from "../../actions/asset/assetAction";
import { getAssetFinancials } from "../../actions/financial";
import { setCurrentFilter } from "../../actions/auth/authAction";

const getTabname = path => {
  switch (path) {
    case "/documents":
    case "/documents/":
      return "DOCUMENTS";
    case "/ledger":
    case "/ledger/":
      return "LEDGER";
    case "/financials":
    case "/financials/":
      return "FINANCIALS";
    case "/contacts":
    case "/contacts/":
      return "CONTACTS";
    case "/investment":
    case "/investment/":
      return "INVESTMENT";
    // case "/map":
    // case "/map/":
    //   return "MAP";
    // case "/notifications/":
    // case "/notifications":
    //   return "NOTIFICATION";
    case "/reports":
    case "/reports/":
      return "REPORTS";
    default:
      return "OVERVIEW";
  }
};

class AssetDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilter: props.currentFilter,
      loading: true,
      error: false,
      basicInfo: null,
      loadingGraph: false,
      filterChange: true,
      cashFlowActual: [],
      fetchCashflow: false,
      loadingAssetInfo: false,
      loadingUserInfo: false,
      userInfo: null,
      showDeleteModal: false,
      showUpdateStateModal: false
    };
  }
  componentDidMount() {
    // before showing anything let's check whether the the asset actually exists
    const {
      getBasicInfo,

      match: {
        params: { asset }
      }
    } = this.props;
    this.setState(
      {
        loading: true,
        loadingAssetInfo: true,
        filterChange: false,
        loadingGraph: true,
        loadingUserInfo: true
      },
      () => {
        getBasicInfo(asset);
        this.props.getAssetFinancials(asset);
        this.props.getCashFlowGraph({
          slug: asset,
          fromDate: this.state.currentFilter.fromDate,
          toDate: this.state.currentFilter.toDate
        });
      }
    );
  }

  componentWillUnmount() {
    this.props.cleanup();
  }

  refreshData = () => {
    const {
      getBasicInfo,
      match: {
        params: { asset }
      }
    } = this.props;
    this.setState(
      {
        loading: true
      },
      () => {
        getBasicInfo(asset);
      }
    );
  };

  handleDateChange = filter => {
    let { currentFilter } = this.state;
    currentFilter.fromDate = filter.fromDate;
    currentFilter.toDate = filter.toDate;
    currentFilter.name = filter.type;
    this.handleChangeFilter(currentFilter);
  };

  handleChangeFilter = values => {
    const {
      match: {
        params: { asset }
      }
    } = this.props;
    this.setState(
      {
        currentFilter: values,
        filterChange: false,
        loadingGraph: true
      },
      () => {
        this.props.setCurrentFilter(values);
        this.props.getCashFlowGraph({
          slug: asset,
          fromDate: values.fromDate,
          toDate: values.toDate
        });
      }
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { cashflow, assetBasicInfo, userDetail } = nextProps;
    const { loading, loadingGraph, loadingUserInfo } = prevState;

    if (loading && !_.isEmpty(assetBasicInfo)) {
      if (!isFetching(assetBasicInfo)) {
        if (isSuccess(assetBasicInfo)) {
          return {
            loading: false,
            basicInfo: assetBasicInfo.data.basicInfo
          };
        } else {
          return {
            loading: false,
            error: assetBasicInfo.data
          };
        }
      }
    } else if (
      !_.isEmpty(assetBasicInfo) &&
      !isFetching(assetBasicInfo) &&
      assetBasicInfo.type === "CLOSE"
    ) {
      if (isSuccess(assetBasicInfo)) {
        return {
          loading: false,
          basicInfo: assetBasicInfo.data.basicInfo
        };
      } else {
        return {
          loading: false,
          error: assetBasicInfo.data
        };
      }
    }

    if (loadingGraph) {
      if (!isFetching(cashflow)) {
        if (isSuccess(cashflow)) {
          return {
            loadingGraph: false,
            cashFlowActual: cashflow.data.cashFlowActual,
            cashFlowProjected: cashflow.data.cashFlowProjected
          };
        }
      }
    } else if (!loadingGraph && isFetching(cashflow)) {
      return { loadingGraph: true };
    }

    if (loadingUserInfo && !_.isEmpty(userDetail)) {
      const { getUserDetailSuccess, userInfo } = userDetail;

      if (getUserDetailSuccess) {
        return {
          userInfo: userInfo,
          loadingUserInfo: false
        };
      }
    }

    if (prevState.loadingAssetInfo && !_.isEmpty(assetBasicInfo)) {
      if (!isFetching(assetBasicInfo)) {
        if (isSuccess(assetBasicInfo)) {
          return {
            basicInfo: assetBasicInfo.data.basicInfo,
            loadingAssetInfo: false,
            fetchCashflow: true
          };
        } else {
          return {
            basicInfo: assetBasicInfo.error,
            loadingAssetInfo: false
          };
        }
      }
    }
    return null;
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      showDeleteModal: !prevState.showDeleteModal
    }));
  };

  toggleUpdateStateModal = () => {
    this.setState(prevState => ({
      showUpdateStateModal: !prevState.showUpdateStateModal
    }));
  };

  render() {
    const { financial } = this.props;
    const {
      loading,
      error,
      basicInfo,
      cashFlowActual,
      cashFlowProjected,
      showProjection,
      currentFilter,
      userInfo
    } = this.state;

    if (loading) return <LoadingIndicator />;
    else if (error)
      return (
        <main className="container-fluid p-5">
          <div className="row">
            <div className="col-md-12">
              <p style={{ textAlign: "center" }}>
                Page not found{" "}
                <Link to="/dashboard"> Go back to dashboard</Link>
              </p>
            </div>
          </div>
        </main>
      );
    else {
      const { asset_name, assetType } = basicInfo;
      const { location } = this.props;
      const locationName = location.pathname;
      const tabname = getTabname(
        locationName.slice(locationName.lastIndexOf("/"))
      );

      let kpis = {};
      let cashflow;
      if (basicInfo && (cashFlowActual || cashFlowProjected)) {
        const { financials } = financial;
        cashflow = showProjection ? cashFlowProjected : cashFlowActual;

        kpis = getKPIsFromActuals(
          basicInfo,
          cashflow,
          financials,
          0,
          currentFilter
        );
      }

      let initialValues = {
        filter: currentFilter
      };

      return (
        <div className="p-5">
          <SectionHeader
            asset={basicInfo}
            pathItems={[
              { label: "Dashboard", path: "/dashboard", active: false },
              {
                label: getAssetTypeLabel(assetType),
                path: `/dashboard?type=${assetType}`,
                active: false
              },
              {
                label: capAllWords(asset_name),
                path: location.pathname,
                active: true
              }
            ]}
          >
            <UncontrolledDropdown>
              <DropdownToggle
                outline
                color="primary"
                className="d-flex align-items-center justify-content-center d-40 mr-1 p-0 rounded-pill"
              >
                <Settings className="w-50" />
              </DropdownToggle>
              <DropdownMenu right className="dropdown-menu-md p-0">
                <Nav className="nav-pills flex-column">
                  <NavItem>
                    <NavLinkStrap
                      tag="a"
                      className="d-flex rounded-sm "
                      href="#/"
                      onClick={this.toggleUpdateStateModal}
                    >
                      <div className="nav-link-icon">
                        <Check />
                      </div>
                      <span>Mark Closed</span>
                    </NavLinkStrap>
                  </NavItem>
                  <NavItem>
                    <NavLinkStrap
                      tag="a"
                      href="#"
                      className="d-flex rounded-sm "
                      onClick={this.toggleDeleteModal}
                    >
                      <div className="nav-link-icon">
                        <Trash />
                      </div>
                      <span>Delete</span>
                    </NavLinkStrap>
                  </NavItem>
                </Nav>
              </DropdownMenu>
            </UncontrolledDropdown>
            <div className="btn-group btn-group-sm" role="group">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={this.handleChangeFilter}
              >
                {props => {
                  return (
                    <Field
                      name="filter"
                      component={DateRangeFilter}
                      onChange={this.handleDateChange}
                    />
                  );
                }}
              </Formik>
            </div>
          </SectionHeader>

          <div>
            <Card className="shadow-xxl m-4">
              <div>
                <div className="my-2">
                  <AssetKPIs
                    data={kpis}
                    slug={this.props.match.params.asset}
                    cashflow={cashflow}
                    isProjection={this.state.showProjection}
                  />
                </div>
                <div className="nav-tabs-first tabs-animated tabs-animated-shadow">
                  <Nav tabs className="justify-content-center">
                    <NavItem>
                      <NavLinkStrap
                        active={tabname === "OVERVIEW"}
                        tag={Link}
                        to={`${this.props.match.url}`}
                      >
                        <span className="font-weight-bold font-size-sm text-uppercase">
                          Overview
                        </span>
                        <div className="divider" />
                      </NavLinkStrap>
                    </NavItem>
                    <NavItem>
                      <NavLinkStrap
                        active={tabname === "INVESTMENT"}
                        tag={Link}
                        to={`${this.props.match.url}/investment`}
                      >
                        <span className="font-weight-bold font-size-sm text-uppercase">
                          Investment
                        </span>
                        <div className="divider" />
                      </NavLinkStrap>
                    </NavItem>
                    <NavItem>
                      <NavLinkStrap
                        active={tabname === "FINANCIALS"}
                        tag={Link}
                        to={`${this.props.match.url}/financials`}
                      >
                        <span className="font-weight-bold font-size-sm text-uppercase">
                          Financials
                        </span>
                        <div className="divider" />
                      </NavLinkStrap>
                    </NavItem>
                    <NavItem>
                      <NavLinkStrap
                        active={tabname === "LEDGER"}
                        tag={Link}
                        to={`${this.props.match.url}/ledger`}
                      >
                        <span className="font-weight-bold font-size-sm text-uppercase">
                          Ledger
                        </span>
                        <div className="divider" />
                      </NavLinkStrap>
                    </NavItem>
                    <NavItem>
                      <NavLinkStrap
                        active={tabname === "REPORTS"}
                        tag={Link}
                        to={`${this.props.match.url}/reports`}
                      >
                        <span className="font-weight-bold font-size-sm text-uppercase">
                          Reports
                        </span>
                        <div className="divider" />
                      </NavLinkStrap>
                    </NavItem>
                    <NavItem>
                      <NavLinkStrap
                        active={tabname === "DOCUMENTS"}
                        tag={Link}
                        to={`${this.props.match.url}/documents`}
                      >
                        <span className="font-weight-bold font-size-sm text-uppercase">
                          Documents
                        </span>
                        <div className="divider" />
                      </NavLinkStrap>
                    </NavItem>
                    <NavItem>
                      <NavLinkStrap
                        active={tabname === "CONTACTS"}
                        tag={Link}
                        to={`${this.props.match.url}/contacts`}
                      >
                        <span className="font-weight-bold font-size-sm text-uppercase">
                          Contacts
                        </span>
                        <div className="divider" />
                      </NavLinkStrap>
                    </NavItem>
                  </Nav>
                </div>
              </div>
            </Card>
          </div>

          <div className="nav-tabs-first tabs-animated tabs-animated-shadow" />
          <Switch>
            <Route
              exact
              path="/assets/:asset/"
              render={props => <AssetOverview {...props} />}
            />
            <Route
              exact
              path="/assets/:asset/financials"
              render={props => (
                <Financials
                  {...props}
                  refreshData={this.refreshData}
                  asset={basicInfo}
                />
              )}
            />
            <Route
              exact
              path="/assets/:asset/documents"
              render={props => (
                <Documents {...props} asset={basicInfo} userInfo={userInfo} />
              )}
            />
            <Route
              exact
              path="/assets/:asset/ledger"
              render={props => <Transaction {...props} asset={basicInfo} />}
            />
            <Route exact path="/assets/:asset/contacts" component={Contacts} />
            <Route
              exact
              path="/assets/:asset/reports"
              render={props => <Reports {...props} asset={basicInfo} />}
            />

            <Route
              exact
              path="/assets/:asset/investment"
              render={props => (
                <Investment
                  {...props}
                  asset={basicInfo}
                  refreshData={this.refreshData}
                />
              )}
            />
            {/*
                <Route
                  exact
                  path="/dashboard/assets/:asset/map"
                  component={Map}
                />*/}
            {/* <Route exact path="/dashboard/assets/:asset/notifications" component={Notification} /> */}
          </Switch>

          <DeleteAsset
            modalOpen={this.state.showDeleteModal}
            toggleModal={this.toggleDeleteModal}
            assetSlug={this.props.match.params.asset}
          />
          <CloseAsset
            modalOpen={this.state.showUpdateStateModal}
            toggleModal={this.toggleUpdateStateModal}
            assetSlug={this.props.match.params.asset}
            assetType={assetType}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  cashflow: state.assetExpenses.cashflow,
  userDetail: state.userDetail,
  financial: state.financial,
  transactions: state.transactions,
  currentFilter: state.filter.currentFilter,
  assetBasicInfo: state.assetBasicInfo
});

const mapDispatchToProps = dispatch => ({
  getBasicInfo: slug => getBasicInfo(slug, dispatch),
  getCashFlowGraph: data => getCashFlowGraph(data, dispatch),
  getAssetFinancials: slug => getAssetFinancials(slug, dispatch),
  setCurrentFilter: data => setCurrentFilter(data, dispatch),
  cleanup: () => cleanup(dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetDetails);
