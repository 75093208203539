import React from "react";
import { connect } from "react-redux";
import { logout } from "../../../../actions/auth/authAction";
import DefaultUser from "../../../../img/default_user.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";

import { NavLink } from "react-router-dom";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

const SidebarUser = props => {
  return (
    <>
      <div className="app-sidebar--userbox">
        <UncontrolledDropdown className="card-tr-actions">
          <DropdownToggle
            color="link"
            className="p-0 border-0 d-30 text-white-50"
          >
            <FontAwesomeIcon icon={faEllipsisH} className="font-size-lg" />
          </DropdownToggle>
          <DropdownMenu
            right
            className="text-nowrap dropdown-menu-lg overflow-hidden p-0"
          >
            <div role="menuitem">
              <a className="dropdown-item" href="#/" onClick={props.logout}>
                Logout
              </a>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
        <div className="avatar-icon-wrapper avatar-icon-md">
          <div className="avatar-icon rounded-circle">
            <img alt="..." src={props.auth.user.picture || DefaultUser} />
          </div>
        </div>
        <div className="my-3 userbox-details">
          {props.auth.user.fullName}
          <small className="d-block text-white-50">
            {props.auth.user.email}
          </small>
        </div>
        <Button size="sm" tag={NavLink} to="/settings" color="userbox">
          View profile
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.login
});

const mapDispatchToProps = dispatch => ({
  logout: () => {
    logout(dispatch);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarUser);
