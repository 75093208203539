/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import DocumentListing from "./DocumentListing";
import AddAttachment from "./AddAttachment";
import DocumentRequest from "./DocumentRequest";
import { Button } from "reactstrap";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

const Document = props => {
  const [showModal, setShowModal] = useState(null);

  const toggleModal = type => {
    setShowModal(type);
  };

  const copyEmail = () => {
    toast.info("Email copied to clipboard", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
    toggleModal();
  };

  return (
    <div className="px-4">
      <DocumentListing
        assetSlug={props.match.params.asset}
        asset={props.asset}
        addModalOpen={!!showModal}
        toggleAddModal={toggleModal}
      />
      <AddAttachment
        toggleModal={toggleModal}
        modalOpen={showModal === "upload"}
        assetSlug={props.match.params.asset}
      />
      <DocumentRequest
        toggleModal={toggleModal}
        modalOpen={showModal === "request"}
        asset={props.asset}
        userInfo={props.userInfo}
      />
      <ConfirmModal
        type="info"
        modalOpen={showModal === "email"}
        onSubmit={toggleModal}
        btnText={"Ok"}
        hideCancel={true}
      >
        <h4 className="font-weight-bold mt-4">
          Just email your documents to:
          <CopyToClipboard
            text={`${props.match.params.asset}@upload.bettercapital.us`}
            onCopy={copyEmail}
          >
            <Button>{`${
              props.match.params.asset
            }@upload.bettercapital.us`}</Button>
          </CopyToClipboard>
        </h4>
        <p className="mb-0 font-size-lg">
          {"* This email is only linked to your asset."}
        </p>
      </ConfirmModal>
    </div>
  );
};

export default Document;
