import React, { PureComponent, Fragment } from "react";
import classNames from "classnames";
import { getIn } from "formik";
import PropTypes from "prop-types";

import PlacesAutocomplete from "react-places-autocomplete";

class LocationSearch extends PureComponent {
  handleChange({ name, form, search }) {
    form.setFieldValue(name, search);
  }
  render() {
    const {
      field,
      form,
      form: { touched, errors, setFieldTouched },
      label,
      placeholder,
      searchOptions
    } = this.props;

    const touch = getIn(touched, field.name);
    const errorMessage = getIn(errors, field.name);
    return (
      <div className="form-group row">
        {label && (
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            {label}
          </label>
        )}
        <div className="col-sm-10 inputblock">
          <PlacesAutocomplete
            value={field.value}
            onChange={search => {
              this.handleChange({ name: field.name, form, search });
            }}
            onSelect={search => {
              this.handleChange({ name: field.name, form, search });
            }}
            searchOptions={searchOptions}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <Fragment>
                <div className="form-group">
                  <input
                    type="text"
                    {...getInputProps({
                      placeholder: placeholder,
                      className: "form-control"
                    })}
                    onBlur={e => setFieldTouched(field.name, e)}
                    className={classNames("form-control", {
                      "is-invalid": touch && errorMessage
                    })}
                  />
                  <div className="invalid-feedback">{errorMessage}</div>
                </div>
                {suggestions && suggestions.length > 0 && (
                  <div className="autocomplete-suggestion-container ">
                    {suggestions.map((suggestion, idx) => {
                      const className = classNames(
                        "autocomplete__suggestion-item",
                        {
                          "autocomplete__suggestion-item--active":
                            suggestion.active
                        }
                      );

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className
                          })}
                          key={`location-suggestion-${idx}`}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{" "}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                    })}
                    <div className="autocomplete__dropdown-footer">
                      <div>
                        <img
                          src={require("../../../img/powered_by_google_default.png")}
                          className="autocomplete__dropdown-footer-image"
                          alt="powered by Google"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </PlacesAutocomplete>
        </div>
      </div>
    );
  }
}

LocationSearch.propTypes = {
  label: PropTypes.string,
  searchOptions: PropTypes.object,
  placeholder: PropTypes.string
};

LocationSearch.defaultProps = {
  searchOptions: {
    types: ["(cities)"],
    componentRestrictions: { country: "us" }
  },
  label: "Location:",
  placeholder: "Enter location"
};

export default LocationSearch;
